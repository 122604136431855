import styles from './styles.css';
import * as Icon from 'react-feather';

const IndicatorCard = ({id, name, description, categories, selected, setSelectedIndicatorId}) => {

    const getStyleTitle = () => (
        selected ? 
        {
            color: "var(--main-color)",
            fontWeight: '700'
        }
        : {
            fontWeight: '400'
        }
    )

     const getStyleDetails = () => (
        selected ? 
        {
            display: "block"
        }
        : {
            display: "none"
        }
    )

    return <div className={styles.indicatorCard}>
        <div className={styles.titleContainer} onClick={() => setSelectedIndicatorId(selected ? null : id)}>
            {selected ? <Icon.Minus className="icon" style={{color: "var(--main-color)"}} /> : <Icon.Plus className="icon" />}
            <h2 style={getStyleTitle()}>{name}</h2>
        </div>
        <div className={styles.details} style={getStyleDetails()}>
            <p className={styles.description}>{description}</p>
            <div className={styles.categoriesContainer}>
                {
                    categories && categories.map((category, i) => (
                        <div key={i}>
                            <h3>{category.name}</h3>
                            <p>{category.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
}

export default IndicatorCard;