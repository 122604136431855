import PartnerCard from '../../components/Cards/PartnerCard/PartnerCard';
import TemplateScreen from '../../components/Template/TemplateScreen'; 
import { Partners } from '../../assets/img'
import './styles.css';
import { MarkupText, Text } from 'preact-i18n';

const Acknowledgements = ({definitions, mainTabs, bottomTabs, languages, language, setLanguage}) => {

    const partners = [
        {
            picture: Partners.tgeu,
            description: <p><MarkupText id="acknowledgements.TGEU logo" /></p> // https://tgeu.org/
        },
        {
            picture: Partners.ilga,
            description: <p><MarkupText id="acknowledgements.ILGA-Europe logo" /></p> // https://ilga-europe.org/
        },
        {
            picture: Partners.ue_vertical,
            description: <p><MarkupText id="acknowledgements.EU logo" /></p> // https://europa.eu/
        },
        {
            picture: Partners.wolfox,
            description: <p><MarkupText id="acknowledgements.Wolfox logo" /></p> // https://wolfox.studio/
        }
    ]

    return <TemplateScreen definitions={definitions} mainTabs={mainTabs} bottomTabs={bottomTabs} title={<Text id="acknowledgements.Main title (H3)" />} language={language} setLanguage={setLanguage} languages={languages}>
        <p><Text id="acknowledgements.Text" /></p>
        <div className="partnersContainer">
            {
                partners && partners.map((partner, i) => (
                    <PartnerCard key={i} picture={partner.picture} description={partner.description} />
                ))
            }
        </div>
    </TemplateScreen>
}

export default Acknowledgements;