import { IntroPictures, Partners } from '../../assets/img'
import './styles.css';
import * as Icon from 'react-feather';
import { Link } from 'preact-router';
import MapSVG from '../../components/Map/MapSVG';
import { MarkupText, Text } from 'preact-i18n';

const Intro = ({languages, language, setLanguage}) => {

    return (
        <div className="introPage">
            <div className="leftContainer">
                <div className="topContainer">
                    <div className="titleContainer">
                        <h1><Text id="landing_page.Main title" /></h1>
                        <h2><Text id="landing_page.Subtitle" /></h2>
                    </div>
                    <div className="dropdown introDropdown">
                        <div className="dropdown-main">
                            <p>{language}</p>
                            <Icon.ChevronDown className="arrowIcon" />
                        </div>
                        <div className={"dropdown-content dropdownLanguagesContent"}>
                            {languages && languages.filter(l => l!== language).map((lang, i) =>(
                                <button key={i} onClick={() => setLanguage(lang)} >{lang}</button>
                            ))}
                        </div>
                    </div>
                </div>
                <p><Text id="landing_page.Introduction" /></p>
                <p><MarkupText id="landing_page.Introduction Link" /></p>
                <div className={"flex items-center gap-8"} style={{fontSize:'1.2rem', flexWrap:'wrap'}}>
                    <Link href="/home/" className="primaryButton">
                        <Icon.Map />
                        <Text id="landing_page.Map button" />
                    </Link>
                    <Link href="/index" className="primaryButton">
                        <Icon.List />
                        <Text id="landing_page.Index button" />
                    </Link>
                    <Link href="/fast-facts" className="primaryButton">
                        <Icon.FastForward />
                        <Text id="landing_page.Fast facts" />
                    </Link>
                </div>
                <p className="smallText"><MarkupText id="landing_page.Cooperation" /></p>
                <div className="footer">
                    <img className="flag" src={IntroPictures.ue_flag} alt="ue_flag" />
                    <p className="verySmallText"><Text id="landing_page.EU" /></p>
                </div>
            </div>
            <div className="rightContainer">
                <Link href="/home/"><div className="mapContainer"><MapSVG height={"100%"} viewBox="50 220 1500 250" mainColor="var(--main-color)" forceMainColor /></div></Link>
                <a href="https://tgeu.org/">
                    <div className="tgeuBanner">
                        <p>A project by</p>
                        <img src={Partners.tgeu} alt="tgeu_logo" />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Intro;