import styles from './styles.css';
import * as Icon from 'react-feather';
import {useState} from "preact/hooks";

const FFCard = ({id, name, description, categories, selected, setSelectedIndicatorId}) => {

    const [open, setOpen] = useState(false)

    const getStyleTitle = () => (
        open ?
        {
            color: "var(--main-color)",
            fontWeight: '700'
        }
        : {
            fontWeight: '400'
        }
    )

     const getStyleDetails = () => (
        open ?
        {
            display: "block"
        }
        : {
            display: "none"
        }
    )

    return <div className={styles.indicatorCard}>
        <div className={styles.titleContainer} onClick={() => setOpen(!open)}>
            {open ? <Icon.Minus className="icon" style={{color: "var(--main-color)"}} /> : <Icon.Plus className="icon" />}
            <h2 style={getStyleTitle()}>{name}</h2>
        </div>
        <div className={styles.details} style={getStyleDetails()}>
            {description && <p className={styles.description}>{description}</p>}
            <ul className={styles.categoriesContainer}>
                {
                    categories && categories.map((category, i) => (
                        <li key={i}>{category.description}</li>
                    ))
                }
            </ul>
        </div>
    </div>
}

export default FFCard;