import { useState } from "preact/hooks";
import Footer from "../Footer/Footer";
import SideBar from "../SideBar/SideBar"
import styles from "./styles.css"
import * as Icon from 'react-feather'

const TemplateScreen = ({definitions, mainTabs, bottomTabs, title, children, languages, language, setLanguage}) => {

    const [expandDropdownLanguage, setExpandDropDownLanguage] = useState(false);

    return (
        <div className={styles.templateScreen}>
            <div className={`dropdown ${styles.dropdownLanguage}`} onClick={() => setExpandDropDownLanguage(!expandDropdownLanguage)}>
                <div className="dropdown-main">
                    <p style={{opacity: expandDropdownLanguage ? 0.5 : 1}}>{language}</p>
                    {expandDropdownLanguage ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
                </div>
                <div className={`dropdown-content ${styles.dropdownLanguagesContent}`} style={{display: expandDropdownLanguage ? "inherit": "none"}}>
                    {languages && languages.filter(l => l!== language).map((lang, i) =>(
                        <button key={i} onClick={() => setLanguage(lang)} >{lang}</button>
                    ))}
                </div>
            </div>
            <div className={styles.mainContainer}>
                <SideBar definitions={definitions} mainTabs={mainTabs} bottomTabs={bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                <div className={styles.viewContainer}>
                    <h1>{title}</h1>
                    <div className={styles.childrenContainer}>
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TemplateScreen;