import { Text } from 'preact-i18n';
import TemplateScreen from '../../components/Template/TemplateScreen';
import './styles.css';

import data from "../../assets/data.csv";

import { useEffect, useState } from 'preact/hooks';
import { readString } from 'react-papaparse';
import { getPapaConfig } from '../../utils';
import CustomDataGrid from '../../components/Grid/CustomDataGrid';

const Index = ({definitions, mainTabs, bottomTabs, languages, language, setLanguage}) => {

    const [csvFile, setCsvFile] = useState({data: {}, errors: [], meta: {}});

    useEffect(() => {
        readString(data, getPapaConfig(setCsvFile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const countries = csvFile.data.countryData;
    const categories = csvFile.data.categoryList;

    return <TemplateScreen 
                definitions={definitions} 
                mainTabs={mainTabs} 
                bottomTabs={bottomTabs} 
                title={<Text id="index.Main title (H3)" />} 
                language={language} 
                setLanguage={setLanguage} 
                languages={languages} >
        <CustomDataGrid {...{definitions, language, countries, categories, mainTabs}} />
    </TemplateScreen>
}

export default Index;