
const Bar = ({position, percentage, width}) => {
  
    const height = 17;

    const [x, y] = position;

    const widthInnerBar = width * percentage/100;

    return (
        <g>
            <path d={`
                M ${x} ${y + height/2}
                C ${x} ${y + height/4} ${x + height/4} ${y} ${x+ height/2} ${y}
                H ${x + width - height/2}
                C ${x + width - height/4} ${y} ${x + width} ${y + height/4} ${x + width} ${y + height/2}
                V ${y + height/2}
                C ${x+width} ${y + height*3/4} ${x+width - height/4} ${y+height} ${x+width - height/2} ${y+height}
                H ${x + height/2}
                C ${x + height/4} ${y+height} ${x} ${y+height*3/4} ${x} ${y + height/2}
                V ${y + height/2}
                Z`} fill="#E5E7EA" />
            {
                widthInnerBar > 0 && <path d={`
                M ${x} ${y + height/2}
                C ${x} ${y + height/4} ${x + height/4} ${y} ${x+ height/2} ${y}
                H ${x + widthInnerBar - height/2}
                C ${x + widthInnerBar - height/4} ${y} ${x + widthInnerBar} ${y + height/4} ${x + widthInnerBar} ${y + height/2}
                V ${y + height/2}
                C ${x+widthInnerBar} ${y + height*3/4} ${x+widthInnerBar - height/4} ${y+height} ${x+widthInnerBar - height/2} ${y+height}
                H ${x + height/2}
                C ${x + height/4} ${y+height} ${x} ${y+height*3/4} ${x} ${y + height/2}
                V ${y + height/2}
                Z`} fill="var(--main-color)" />
            }
        </g>
    )
}

export default Bar;