import { MarkupText, Text } from 'preact-i18n';
import TemplateScreen from '../../components/Template/TemplateScreen';
import './styles.css';

const PrivacyPolicy = ({definitions, mainTabs, bottomTabs, languages, language, setLanguage}) => {


    return <TemplateScreen definitions={definitions} mainTabs={mainTabs} bottomTabs={bottomTabs} title={<Text id="privacy_policy.Main title (H3)" />} language={language} setLanguage={setLanguage} languages={languages} >
        <div className="privacyContainer">
            <h2><Text id="privacy_policy.Subtitle 1" /></h2>
            <p><Text id="privacy_policy.Text 1" /></p>
            <h2><Text id="privacy_policy.Subtitle 2" /></h2>
            <p><Text id="privacy_policy.Text 2" /></p>
            <h2><Text id="privacy_policy.Subtitle 3" /></h2>
            <p>
                <Text id="privacy_policy.Text 3-1" /><br />
                <br />
                <ul>
                    <li><Text id="privacy_policy.Item 1" /></li>
                    <li><Text id="privacy_policy.Item 2" /></li>
                    <li><Text id="privacy_policy.Item 3" /></li>
                    <li><Text id="privacy_policy.Item 4" /></li>
                    <li><Text id="privacy_policy.Item 5" /></li>
                    <li><Text id="privacy_policy.Item 6" /></li>
                </ul>
                <Text id="privacy_policy.Text 3-2" /><br />
                <br />
                <MarkupText id="privacy_policy.Text 3-3" /><br />
                <br />
                <Text id="privacy_policy.Text 3-4" />
            </p>
        </div>
    </TemplateScreen>
}

export default PrivacyPolicy;