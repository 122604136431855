import { MarkupText, Text } from 'preact-i18n';
import TemplateScreen from '../../components/Template/TemplateScreen';
import './styles.css';

const Disclaimer = ({definitions, mainTabs, bottomTabs, languages, language, setLanguage}) => {


    return <TemplateScreen definitions={definitions} mainTabs={mainTabs} bottomTabs={bottomTabs} title={<Text id="disclaimer.Main title (H3)" />} language={language} setLanguage={setLanguage} languages={languages} >
        <div className="disclaimerContainer">
            <h2><Text id="disclaimer.Subtitle 1" /></h2>
            <p>
                <Text id="disclaimer.Text 1-1" /><br />
                <br />
                <Text id="disclaimer.Text 1-2" />
            </p>
            <h2><Text id="disclaimer.Subtitle 2" /></h2>
            <p>
                <Text id="disclaimer.Text 2-1" /><br />
                <Text id="disclaimer.Text 2-2" />
            </p>
            <h2><Text id="disclaimer.Subtitle 3" /></h2>
            <p><Text id="disclaimer.Text 3" /></p>
            <h2><Text id="disclaimer.Subtitle 4" /></h2>
            <p>
                <Text id="disclaimer.Text 4-1" /><br />
                <br />
                <Text id="disclaimer.Text 4-2" /><br />
                <br />
                <MarkupText id="disclaimer.Text 4-3" />
            </p>
        </div>
    </TemplateScreen>
}

export default Disclaimer;