import JSZip from "jszip";
import jsPDF from "jspdf";
import Manrope_Bold from "./assets/fonts/Manrope/Manrope-bold"
import Manrope_Normal from "./assets/fonts/Manrope/Manrope-normal"
import Roboto_Normal from "./assets/fonts/Roboto/Roboto-normal"
import shortNames from "./assets/short-names.json";

export function debounce(fn, ms) {
    let timer
    // eslint-disable-next-line no-unused-vars
    return _ => {
        clearTimeout(timer)
        // eslint-disable-next-line no-unused-vars
        timer = setTimeout(_ => {
        timer = null
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, arguments)
        }, ms)
    };
}

export const getPointFromString = (str) => {
    return str !== "N.A." ? eval(str) : 0;
}

export const EUString = "EU countries";
export const CouncilOfEuropeString = "Council of Europe";
export const CentralAsiaString = "Central Asia";

export const inSelectedZone = (countries, selectedZone, country) => {
    if(!selectedZone) return true;
    switch (selectedZone.name) {
        case "Europe & Central Asia":
            return true;
        case "EU (European Union)":
            return countries.find(c => c.name === country.name).data[EUString].value !== "0";
        case "Council of Europe":
            return countries.find(c => c.name === country.name).data[CouncilOfEuropeString].value !== "0";
        case "Central Asia":
            return countries.find(c => c.name === country.name).data[CentralAsiaString].value !== "0";
        default: 
            return true;
    }
}

const formatData = (data) => {
    const categories = {}
    const lastColumnIndex = data[1].slice(1).findIndex(cell => cell.trim() === "");
    data[0].slice(1, lastColumnIndex + 1).filter(cell => cell.trim() !== "").forEach(cat => categories[cat] = []);
    data[1].slice(1, lastColumnIndex + 1).forEach((cell, i) => {
        if(cell.trim() !== ""){
            let j = i+1;
            while(data[0][j].trim() === ""){
                j--;
            }
            categories[data[0][j].trim()].push(cell.trim());
        }
    })

    let smallCategories = []
    for(let k of Object.keys(categories)){
        smallCategories = smallCategories.concat(categories[k])
    }

    const otherCategories = [CouncilOfEuropeString, EUString, CentralAsiaString];

    smallCategories = smallCategories.concat(otherCategories)
    const countries = data.slice(2, 56).map((countryRow) => {
        const country = {name: countryRow[0], data: {}}
        countryRow.slice(1, lastColumnIndex + 1).forEach((cell, i) => {
            const cellElements = cell.split('///').map(cell => cell.trim())
            const v = cellElements[0] === "§" ? "1" : cellElements[0] === "" ? "0" : cellElements[0];
            const comment = cellElements.length > 1 ? cellElements[1] : "";
            country["data"][smallCategories[i]] = {value: v, comment};
        })
        countryRow.slice(lastColumnIndex + 2, lastColumnIndex + 2 + otherCategories.length).forEach((cell, i) => {
            const cellElements = cell.split('///').map(cell => cell.trim())
            const v = cellElements[0].toLowerCase() === "x" ? "1" : cellElements[0] === "" ? "0" : cellElements[0];
            const comment = cellElements.length > 1 ? cellElements[1] : "";
            country["data"][smallCategories[lastColumnIndex + i]] = {value: v, comment};
        })
        return country
    })

    return {
        categoryList: categories,
        countryData: countries.sort((x, y) => x.name.localeCompare(y.name))
    };
}

export const formatDetails = (data) => {
    let details = {}
    const categories = data[1].splice(1)
    const countries = data.splice(2)
    countries.forEach(countryData => {
            const countryDetails = {}
            categories.forEach((category, i) => {
                countryDetails[category] = countryData[i+1]
            })
            details[countryData[0]] = countryDetails
    })
    return details
}

export const getPapaConfig = (setCsvFile) => ({
    complete: (results) => {
        console.log('Countries data : Parsing complete');
        setCsvFile({data: formatData(results.data), errors: results.errors, meta: results.meta});
    },
    download: true,
    error: (error, file) => {
        console.log('Error while parsing:', error, file);
    },
});

const passFilter = (value, filter) => {
        return !filter || filter.length === 0 || filter.includes(value)
    }

export const getFilteredCountries = (countries, smallCategories, filters) => {
    return countries.filter(country => {
        let passFilters = true;
        filters.forEach((filter, i) => {
            if(i === 0 ? !passFilter(country.name, filter) : i<=smallCategories.length && !passFilter(country.data[smallCategories[i-1]].value, filter)) passFilters = false
        })
        return passFilters;
    })
}

export const haveIndicator = (definitions, selectedIndicator, countryWithData) => {
    const category = definitions.EN.country_page[`Subcategory ${selectedIndicator.split('-')[2][selectedIndicator.split('-')[2].length - 1]}-${selectedIndicator.split('-')[3].trim()}`];

    if(category.trim().slice(0, 3).toLowerCase() === "no ") return countryWithData.data[category].value === "0"
    if(countryWithData.data[category].value === "N.A.") return null
    return countryWithData.data[category].value !== "0"
}

export const formatBinaryCell = (definitions, language, cell) => {
    return cell === "1" ? "X" : cell === "0" ? "" : cell === "N.A." ? definitions[language].maps["Non Applicable"] : `="${cell}"`
}

const getCellValueCSV = (definitions, language, countryData, subtype, lgrColumns) => {
    if(subtype.markers) {
        return lgrColumns[subtype.id].map(col => `${formatBinaryCell(definitions, language, countryData[definitions.EN.country_page[`Subcategory 1-${col}`]].value)}`)
    }
    return `"${definitions[language].maps[`Cluster ${countryData['Existence of legal measures'].value !== "1" && countryData['Existence of administrative measures'].value !== "1" ? 1
            : ((countryData['Existence of legal measures'].value === "1" || countryData['Existence of administrative measures'].value === "1") && (countryData['No compulsory medical intervention required'].value !== "1" || countryData['No compulsory surgical intervention required'].value !== "1" || countryData['No compulsory sterilisation required'].value !== "1")) ? 2
            : ((countryData['Existence of legal measures'].value === "1" || countryData['Existence of administrative measures'].value === "1") && countryData['No diagnosis or psychological opinion required'].value !== "1" && countryData['No compulsory medical intervention required'].value === "1" && countryData['No compulsory surgical intervention required'].value === "1" && countryData['No compulsory sterilisation required'].value === "1") ? 3
            : ((countryData['Existence of legal measures'].value === "1" || countryData['Existence of administrative measures'].value === "1") && countryData['No diagnosis or psychological opinion required'].value === "1" && countryData["Self-determination"].value !== "1") ? 4
            : countryData['Self-determination'].value === "1" ? 5 : 0}`]}"`;

}

const convertToCSV = (definitions, language, categories, mainTabs, lgrColumns, subtype, objArray, bigCategory) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
        let line = `${definitions[language].navigation[array[i].name]},`;
        if(bigCategory && bigCategory !== Object.keys(categories)[0]) categories[bigCategory].forEach(c => line += `${formatBinaryCell(definitions, language, array[i].data[c].value)},`)
        else if(bigCategory === Object.keys(categories)[0]) {
            line += `${getCellValueCSV(definitions, language, array[i].data, subtype, lgrColumns)},`
            if(subtype.id === 1) Object.keys(definitions[language]?.country_page).filter(k => k.includes(`Subcategory 1-`)).forEach(a => line += `${formatBinaryCell(definitions, language, array[i].data[definitions[language].country_page[a]].value)},`)
        }
        else {
            mainTabs[1].subtabs.forEach(s => line += `${getCellValueCSV(definitions, language, array[i].data, s, lgrColumns)},`)
            Object.values(categories).slice(1).forEach(a => a.forEach(c => line += `${formatBinaryCell(definitions, language, array[i].data[c].value)},`))
        }
        str += `${line}\r\n`;
    }
    return str;
}

export const downloadCsvFile = (definitions, language, mainTabs, lgrColumns, subtype, countries, categories, selectedCountry, selectedZone, bigCategory, callback) => { 

    let csvFileData = convertToCSV(definitions, language, categories, mainTabs, lgrColumns, subtype, countries.filter(c => selectedCountry ? c.name === selectedCountry.name : inSelectedZone(countries, selectedZone, c)), bigCategory);  

    //define the heading for each row of the data
    let csv = ","

    if(bigCategory) {
        const index = Object.keys(categories).indexOf(bigCategory)
        if(index === 0) {
            if(subtype.id === 1){
                csv += `${definitions[language].maps[`Subnav 1`]},`
                Object.keys(definitions[language]?.country_page).filter(k => k.includes(`Subcategory 1-`)).forEach(a => csv+= `${definitions[language]?.country_page[a]},`)
            }
            else lgrColumns[subtype.id].forEach(col => csv += `${definitions[language]?.country_page[`Subcategory 1-${col}`]},`)
        }
        else Object.keys(definitions[language]?.maps).filter(k => k.includes(`Marker ${index + 1}`) && !k.includes("short name")).forEach(a => csv+= `${definitions[language]?.maps[a]},`)
        csv += "\n"
    }
    else {
        csv += `${definitions[language].maps[`Subnav 1`]},`
        for(let i = 2; i<=Object.keys(lgrColumns).length; i++) {
           lgrColumns[i].forEach(col => csv += `${definitions[language]?.country_page[`Subcategory 1-${col}`]},`)
        }
        for (let i=2; i<= Object.keys(categories).length; i++){
            Object.keys(definitions[language]?.maps).filter(k => k.includes(`Marker ${i}`) && !k.includes("short name")).forEach(a => csv+= `${definitions[language]?.maps[a]},`)
        }
        csv+="\n"
    }

    csv += csvFileData;  

    csv += `\n\n,Legend\n${definitions[language].maps["Legend"].split(";").map(item => `,${item.trim()}`).join("\n")}`
    
    let hiddenElement = document.createElement('a');  
    hiddenElement.style.display = "none"
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    hiddenElement.download = `tgeu-trans-rights-map-${new Date().getFullYear()}${bigCategory ? `-${Object.keys(categories).indexOf(bigCategory) === 0 ? definitions.EN.maps[`Subnav ${subtype.id}`].toLowerCase().trim().replaceAll(" ", "-") : bigCategory.toLowerCase().trim().replaceAll(" ", "-")}`: ""}${selectedCountry ? `-${selectedCountry.name.toLowerCase().trim().replaceAll(" ", "-")}` : selectedZone ? `-${selectedZone.name.toLowerCase().trim().replaceAll(" ", "-")}`: ""}-${language.toLowerCase().trim().replaceAll(" ", "-")}.csv`;
    
    if (typeof hiddenElement.download != "undefined") {
        //browser has support - process the download
        document.body.appendChild(hiddenElement);
        hiddenElement.click();
        document.body.removeChild(hiddenElement);
    }

    if(callback) callback()

}

export const downloadCountries = (definitions, language, countries, selectedClusterIndex, callback) => { 

    let csv = "";

    countries.forEach(c => csv += `${definitions[language].navigation[c.name]}\n`)
    
    let hiddenElement = document.createElement('a');  
    hiddenElement.style.display = "none"
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`; 
    hiddenElement.download = `tgeu-trans-rights-map-${new Date().getFullYear()}-${definitions[language].maps[`Cluster ${selectedClusterIndex}`].toLowerCase().trim().replaceAll(" ", "-")}-${language.toLowerCase().trim().replaceAll(" ", "-")}.csv`;
    
    if (typeof hiddenElement.download != "undefined") {
        //browser has support - process the download
        document.body.appendChild(hiddenElement);
        hiddenElement.click();
        document.body.removeChild(hiddenElement);
    }
    if(callback) callback()
}

export const downloadImage = async (id, definitions, language, type, subtype, selectedCountry, callback) =>{ 

    const svgElement = document.getElementById(`svgExportFile${id}`)
    svgElement.style.setProperty('--main-color', definitions.colors['Main color']);

    let image = new Image();

    image.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = 1080;   
        canvas.height = 1080;
        let context = canvas.getContext('2d');
        // draw image in canvas starting left-0 , top - 0  
        context.drawImage(image, 0, 0, 1080, 1080);

        let png = canvas.toDataURL();

        let hiddenElement = document.createElement('a');  
        hiddenElement.style.opacity = "0"
        hiddenElement.href = png; 
        hiddenElement.download = `tgeu-trans-rights-map-${new Date().getFullYear()}${type && !selectedCountry ? `-${type.id === 1 ? definitions.EN.maps[`Subnav ${subtype.id}`].toLowerCase().trim().replaceAll(" ", "-") : definitions.EN.maps[`Map main title ${type.id}`].toLowerCase().trim().replaceAll(" ", "-")}`: ""}${selectedCountry ? `-${selectedCountry.name.toLowerCase().trim().replaceAll(" ", "-")}` : ""}-${language.toLowerCase().trim().replaceAll(" ", "-")}.png`;
        
        if (typeof hiddenElement.download != "undefined") {
            //browser has support - process the download
            document.body.appendChild(hiddenElement);
            hiddenElement.click();
            document.body.removeChild(hiddenElement);
        }

        if(callback) callback()
    };
    let svgAsXML = (new XMLSerializer).serializeToString( svgElement );
    image.src = `data:image/svg+xml,${encodeURIComponent( svgAsXML )}`
}

function getBase64String(dataURL) {
   let idx = dataURL.indexOf('base64,') + 'base64,'.length;
   return dataURL.substring(idx);
}

export const downloadZipImages = async (ids, definitions, language, type, subtype, selectedCountry, callback) => {
    const svgElements = ids.map(id => document.getElementById(`svgExportFile${id}`))
    let canvas = document.createElement('canvas');
    canvas.width = 1080;   
    canvas.height = 1080;
    canvas.style.setProperty('--main-color', definitions.colors['Main color']);
    canvas.style.setProperty('--secondary-color', definitions.colors['Secondary color']);
    let context = canvas.getContext('2d');

    let jsZip = new JSZip();

    let folder = jsZip.folder(`tgeu-trans-rights-map-${new Date().getFullYear()}${type && !selectedCountry ? `-${type.id === 1 ? definitions.EN.maps[`Subnav ${subtype.id}`].toLowerCase().trim().replaceAll(" ", "-") : definitions.EN.maps[`Map main title ${type.id}`].toLowerCase().trim().replaceAll(" ", "-")}`: ""}${selectedCountry ? `-${selectedCountry.name.toLowerCase().trim().replaceAll(" ", "-")}` : ""}-${language.toLowerCase().trim().replaceAll(" ", "-")}.zip`);

    let imagesRemaining = ids.length;

    svgElements.forEach((svgElement, i) => {
        svgElement.style.setProperty('--main-color', definitions.colors['Main color']);
        let image = new Image();

        image.onload = () => {
            context.drawImage(image, 0, 0, 1080, 1080);

            let baseString = getBase64String(canvas.toDataURL());
            folder.file(`tgeu-trans-rights-map-${new Date().getFullYear()}${type && !selectedCountry ? `-${type.id === 1 ? definitions.EN.maps[`Subnav ${subtype.id}`].toLowerCase().trim().replaceAll(" ", "-") : definitions.EN.maps[`Map main title ${type.id}`].toLowerCase().trim().replaceAll(" ", "-")}`: ""}${selectedCountry ? `-${selectedCountry.name.toLowerCase().trim().replaceAll(" ", "-")}` : ""}-${language.toLowerCase().trim().replaceAll(" ", "-")}-${i}.png`, baseString, {base64 : true});
        
            imagesRemaining --;

            if(imagesRemaining === 0) {
                jsZip.generateAsync({type:"blob"}).then((content) => {
                    content = URL.createObjectURL(content);
                    let name = `tgeu-trans-rights-map-${new Date().getFullYear()}${type && !selectedCountry ? `-${type.id === 1 ? definitions.EN.maps[`Subnav ${subtype.id}`].toLowerCase().trim().replaceAll(" ", "-") : definitions.EN.maps[`Map main title ${type.id}`].toLowerCase().trim().replaceAll(" ", "-")}`: ""}${selectedCountry ? `-${selectedCountry.name.toLowerCase().trim().replaceAll(" ", "-")}` : ""}-${language.toLowerCase().trim().replaceAll(" ", "-")}.zip`;
                    let hiddenElement = document.createElement('a');  
                    hiddenElement.style.opacity = "0"
                    hiddenElement.href = content; 
                    hiddenElement.download = name;  
                    
                    if (typeof hiddenElement.download != "undefined") {
                        //browser has support - process the download
                        document.body.appendChild(hiddenElement);
                        hiddenElement.click();
                        document.body.removeChild(hiddenElement);
                    }
                    
                    if(callback) callback()
                })
            }
        };
        let svgAsXML = (new XMLSerializer).serializeToString( svgElement );
        image.src = `data:image/svg+xml,${encodeURIComponent( svgAsXML )}`
    })
}

export const downloadPDF = async (ids, definitions, language, type, subtype, selectedCountry, callback) => {

    const doc = new jsPDF(ids.length > 1? "p" : "l", "pt", "a4")
    const pdfName = `tgeu-trans-rights-map-${new Date().getFullYear()}${type && !selectedCountry ? `-${type.id === 1 ? definitions.EN.maps[`Subnav ${subtype.id}`].toLowerCase().trim().replaceAll(" ", "-") : definitions.EN.maps[`Map main title ${type.id}`].toLowerCase().trim().replaceAll(" ", "-")}`: ""}${selectedCountry ? `-${selectedCountry.name.toLowerCase().trim().replaceAll(" ", "-")}` : ""}-${language.toLowerCase().trim().replaceAll(" ", "-")}.pdf`;

    const svgElements = ids.map(id => document.getElementById(`pdfExportFile${id}`))

    let imagesRemaining = ids.length;

    svgElements.forEach((svgElement) => {
        svgElement.style.setProperty('--main-color', definitions.colors['Main color']);
        let image = new Image();

        image.onload = () => {
            let canvas = document.createElement('canvas');
            const widthCanvas = image.width*5;
            const heightCanvas = image.height*5;
            canvas.width = widthCanvas;
            canvas.height = heightCanvas;
            let context = canvas.getContext('2d');
            context.drawImage(image, 0, 0, widthCanvas, heightCanvas);

            imagesRemaining --;

            doc.addImage(canvas, 'JPEG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight())

            if(imagesRemaining === 0) {
                doc.save(pdfName)
                if(callback) callback()
            } else {
                doc.addPage()
            }
        };
        let svgAsXML = (new XMLSerializer).serializeToString( svgElement );
        image.src = `data:image/svg+xml,${encodeURIComponent( svgAsXML )}`
    })
}

export const copyEmbedCode = () => {
    const url = window.location.href;
    const embedCode = `<iframe src="${url}" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>`;
    window.navigator.clipboard.writeText(embedCode).then(() => {
        alert("Embed code copied to clipboard");
    }).catch(() => {
        alert("Failed to copy embed code to clipboard");
    });
}

const arrayMatch = (a, b) => {
    return a.length === b.length && a.every((v, i) => v === b[i])
}

export const downloadIndexPDF = async (definitions,language, categories, smallCategories, countries, selectedZone, filters) => {
    let checkLogo = document.createElement('img')
    checkLogo.src = 'assets/img/check.png'

    const zones = [
        {
            name:"Council of Europe",
            countries: [
                "Iceland",
                "France",
                "Italy",
                "Spain",
                "Ireland",
                "United Kingdom",
                "Portugal",
                "Belgium",
                "Denmark",
                "Netherlands",
                "Switzerland",
                "Luxembourg",
                "Sweden",
                "Norway",
                "Finland",
                "Estonia","Latvia","Lithuania","Ukraine","Georgia","Azerbaijan","Armenia","Turkey","Greece","Bulgaria","North Macedonia",
                "Albania","Montenegro","Bosnia & Herzegovina","Serbia","Romania","Moldova","Poland","Slovakia","Hungary","Croatia","Slovenia","Austria","Czechia","Germany",
                "Monaco","San Marino","Andorra","Malta","Cyprus","Kosovo","Liechtenstein"
            ]
        },
        {
            name:"Central Asia",
            countries: ["Kazakhstan","Uzbekistan","Turkmenistan","Tajikistan","Kyrgyzstan"]
        },
        {
            name:"EU countries",
            countries: [
                "France",
                "Italy",
                "Spain",
                "Ireland",
                "Portugal",
                "Belgium",
                "Netherlands",
                "Luxembourg",
                "Sweden",
                "Finland",
                "Estonia","Latvia","Lithuania","Greece","Bulgaria",
                "Romania","Poland","Slovakia","Hungary","Croatia","Slovenia","Austria","Czechia","Germany",
                "Cyprus","Denmark","Malta"
            ]
        },
    ]

    // Adding title variables
    let selectedFilters = []
    filters.forEach((filter,i) => {
        if(filter.length) {
            if(i === 0) {
                if(selectedZone === "All regions") {
                    if(filter.length > 54)
                        selectedFilters.push(`countries-[${filter.join("/")}]`)
                }
                else {
                    const zone = zones.find(z => z.name === selectedZone)
                    if(arrayMatch(zone.countries, filter)) {
                        selectedFilters.push(zone.name.toLowerCase().replaceAll(" ", "-"))
                    }
                    else {
                        selectedFilters.push(`countries-[${filter.join("/")}]`)
                    }
                }
            }
            else {
                selectedFilters.push(
                    `${smallCategories[i - 1].toLowerCase().replaceAll(" ", "-")}-[${filter.join("/")}]`
                )
            }
        }
    })
    selectedFilters = selectedFilters.length ? `${selectedFilters.join("-")}-` : ''

    const countryWidth = 150
    const cellWidth = 42.5
    const cellHeight = 16;
    let maxWidth = 0;
    let xOffset = countryWidth;
    let yOffset = 0;

    // Sorting countries
    let Other = []
    let Asia = []

    countries.forEach(country => {
        if (zones[1].countries.includes(country.name)) {
            Asia.push({...country, short: shortNames[country.name]})
        } else {
            Other.push({...country, short: shortNames[country.name]})
        }
    })

    const doc = new jsPDF({
        orientation:'landscape',
        format:'a3',
        unit:"px",
        hotfixes:["px_scaling"],
    })
    doc.setProperties({
        title: `TGEU Index ${new Date().getFullYear()}`,
        subject: `TGEU Index ${new Date().getFullYear()}`,
        author: 'Transgender Europe',
        keywords: 'generated, javascript, web 2.0, ajax',
        creator: 'Transgender Europe'
    })
    const pdfName = `tgeu-trans-rights-index-${selectedFilters}${new Date().getFullYear()}.pdf`;
    //doc.addFileToVFS('Manrope-Bold.js', ManropeBold);

// Header
    doc.setFontSize(25)
    doc.setFont('Manrope', 'bold')
    doc.setTextColor(255, 136, 0);
    doc.text(150, 40, definitions[language].index["Main title (H3)"] || '');
    doc.setFont('Manrope', 'normal');
    doc.setFontSize(15)
    doc.setTextColor(0, 0, 0);
    //doc.text(500, 40, `Europe & Central Asia ${new Date().getFullYear()}`);
    doc.setFontSize(7)
    doc.addImage(checkLogo,'png', 1458 , 8, 8, 8)
    doc.text(1470, 14, definitions[language].index['Existing measures'] || '')
    doc.text(1460, 26, '/')
    doc.text(1470, 26, definitions[language].index['Regional application'] || '')
    doc.setFillColor(191,197,204)
    doc.rect(1457, 30, 8, 8, 'F')
    doc.text(1470, 38, definitions[language].index['EU member'] || '')
    doc.setFont('Manrope', 'bold')
    doc.setFontSize(9)


    var LogoImg = new Image()
    LogoImg = 'assets/img/tgeu_logo_export.png'
    doc.addImage(LogoImg, 'png', 20, 90, 110, 38)

    //Draw categories background
    Object.keys(categories).forEach((bigCategory, j) => {
        if (j % 2 === 0) {
            doc.setFillColor(56, 63, 69)
        } else {
            doc.setFillColor(127,135,144)
        }
        doc.rect(xOffset, yOffset + 50, categories[bigCategory].length * cellWidth, cellHeight * 2,'F')
        xOffset += categories[bigCategory].length * cellWidth
    })
    //Draw category titles
    xOffset = countryWidth
    doc.setFont('Manrope', 'normal')

    Object.keys(categories).forEach((bigCategory, j) => {
        // Big categories
        doc.setTextColor(255,255,255);
        doc.setFontSize(9)
        doc.text(definitions[language]['country_page'][`Category ${j+1}`], xOffset + categories[bigCategory].length * cellWidth / 2, yOffset + 60,{maxWidth:categories[bigCategory].length * cellWidth, align:'center', baseline:'top'})
        doc.setFillColor(255 ,255, 255)
        doc.line(xOffset,50,xOffset,cellHeight * 2 + 50,'F')
        //xOffset += categories[bigCategory].length * cellWidth
        yOffset += cellHeight * 2

        // Small categories
        doc.setTextColor(0,0,0);
        categories[bigCategory].forEach((category,i) => {
            doc.setFontSize(6)
            if (i%2 === 0) {
                doc.setFillColor(229, 231, 234)
            } else {
                doc.setFillColor(246, 247, 249)
            }
            doc.rect(xOffset + (cellWidth * i), yOffset + 50, cellWidth, cellHeight * 6 + 5,'F')
            doc.text(definitions[language]['country_page'][`Subcategory ${j+1}-${i+1}`], xOffset + (cellWidth * i) + (cellWidth / 3), yOffset + (cellHeight * 5) + 50,{align:'left', baseline:'top', angle:90, maxWidth:cellHeight * 5})
            //doc.text(category, xOffset + (cellWidth * i) + (cellWidth / 3), yOffset + (cellHeight * 5) + 50,{align:'left', baseline:'top', angle:90, maxWidth:cellHeight * 5})
            maxWidth += cellWidth
        })
        xOffset += categories[bigCategory].length * cellWidth
        yOffset -= cellHeight * 2
    })
    doc.setTextColor(0,0,0);
    yOffset += cellHeight * 9
    xOffset = countryWidth;

    //Draw countries
    let image = document.createElement('img')
    doc.setFontSize(9)
    doc.setFont('Roboto', 'normal');

    if (Other.length > 0) {
        Other.forEach((country , i) => {
            let name = country.name.toLowerCase().replaceAll(" ","%20")
            if(zones[2].countries.includes(country.name)) doc.setFillColor((i % 2 === 0 ? 191 : 227, i % 2 === 0 ? 197 : 229, i % 2 === 0 ? 204 : 233))
            else  doc.setFillColor(i % 2 === 0 ? 250 : 243, i % 2 === 0 ? 250 : 244, i % 2 === 0 ? 251 : 245)

            doc.rect(0,yOffset + (i * cellHeight) - 12 + 50,countryWidth + maxWidth + 200 ,cellHeight,'F')
            doc.text(definitions[language].navigation[country.name] || country.name, 123, yOffset + (i * cellHeight) + 50,{maxWidth:countryWidth, baseline:'center', align:'right'})
            smallCategories.forEach((category, j) => {

                if (j % 2 === 0) {
                    doc.saveGraphicsState();
                    doc.setFillColor(109, 110, 110)
                    doc.setGState(doc.GState({opacity: 0.2}));
                    doc.rect(150 + (j * (cellWidth)),yOffset + (i * cellHeight) - 12 + 50, cellWidth,cellHeight,'F')
                    doc.setGState(doc.GState({opacity: 0}));
                    doc.restoreGraphicsState();
                }

                if(country.data[category].value === '1') {
                    doc.addImage(checkLogo,'png', countryWidth + (cellWidth / 2) + (cellWidth * j) - 5,yOffset + (i * cellHeight) + (cellHeight * 3) - 7, 10, 10), {maxWidth:cellWidth, align:"center"} }
                else if(country.data[category].value.includes("N.A")) {
                    doc.setTextColor(168, 168, 168)
                    doc.text('N.A',countryWidth + (cellWidth / 2) + (cellWidth * j) ,yOffset + (i * cellHeight) + 50, {maxWidth:cellWidth, align:"center"})
                }
                else if((country.data[category].value !== '0')) {
                    doc.text(country.data[category].value,countryWidth + (cellWidth / 2) + (cellWidth * j) ,yOffset + (i * cellHeight) + 50, {maxWidth:cellWidth, align:"center"})
                }
                doc.setTextColor(0,0,0);

            })
            doc.text(country.short, 1535, yOffset + (i * cellHeight) + 50, {maxWidth:countryWidth, align:'left'})
            image.src = `assets/flags/${name}.png`
            doc.addImage(image,'png', 1515, 134.1 + (i * cellHeight) + 50, 15, 10)
            doc.addImage(image,'png', 130, 134.1 + (i * cellHeight) + 50, 15, 10)
        })
        yOffset += cellHeight * Other.length
    }

    if (Asia.length > 0) {
        doc.setFillColor(228, 236, 232)
        doc.rect(0,yOffset + 37,  countryWidth + maxWidth + 200, cellHeight,'F')
        doc.setFont('Manrope', 'bold')
        doc.text("CENTRAL ASIA", 130, yOffset + 48, {maxWidth:countryWidth, baseline:'center', align:'right'})
        doc.text("CENTRAL ASIA", 1450, yOffset + 48, {maxWidth:countryWidth,align:'left'})
        doc.setFont('Roboto', 'normal')
        yOffset += cellHeight
        Asia.forEach((country , i) => {
            let name = country.name.toLowerCase().replaceAll(" ","%20")
            doc.setFillColor(i % 2 === 0 ? 250 : 243, i % 2 === 0 ? 250 : 244, i % 2 === 0 ? 251 : 245)

            doc.rect(0,yOffset + (i * cellHeight) - 12 + 46,countryWidth + maxWidth + 200 ,cellHeight,'F')
            doc.text(definitions[language].navigation[country.name] || country.name, 123, yOffset + (i * cellHeight) + 46,{maxWidth:countryWidth, baseline:'center', align:'right'})
            smallCategories.forEach((category,j) => {

                if (j % 2 === 0) {
                    doc.saveGraphicsState();
                    doc.setFillColor(109, 110, 110)
                    doc.setGState(doc.GState({opacity: 0.2}));
                    doc.rect(150 + (j * (cellWidth)),yOffset + (i * cellHeight) - 16 + 50, cellWidth, cellHeight,'F')
                    doc.setGState(doc.GState({opacity: 0}));
                    doc.restoreGraphicsState();
                }

                if(country.data[category].value === '1') {
                    doc.addImage(checkLogo,'png', countryWidth + (cellWidth / 2) + (cellWidth * j) - 5,yOffset + (i * cellHeight) + 36, 10, 10), {maxWidth:cellWidth, align:"center"} }
                else if(country.data[category].value.includes("N.A")) {
                    doc.setTextColor(168, 168, 168)
                    doc.text('N.A',countryWidth + (cellWidth / 2) + (cellWidth * j) ,yOffset + (i * cellHeight) + 46, {maxWidth:cellWidth, align:"center"})
                }
                else if((country.data[category].value !== '0')) {
                    doc.text(country.data[category].value,countryWidth + (cellWidth / 2) + (cellWidth * j) ,yOffset + (i * cellHeight) + 46, {maxWidth:cellWidth, align:"center"})
                }
                doc.setTextColor(0,0,0);
            })
            doc.text(country.short, 1535, yOffset + (i * cellHeight) + 46, {maxWidth:countryWidth, align:'left'})
            image.src = `assets/flags/${name}.png`
            doc.addImage(image,'png', 1515, yOffset + (i * cellHeight) + 37, 15, 10)
            doc.addImage(image,'png', 130, yOffset + (i * cellHeight) + 37, 15, 10)
        })
    }

    xOffset = countryWidth
    smallCategories.forEach((category,i) => {
        doc.setFillColor(229, 231, 234)
        doc.line(xOffset + (cellWidth * i),cellHeight * 2 + 50, xOffset + (cellWidth * i),cellHeight * 8 + cellHeight * countries.length + 66,'F')

// Left Countries

    doc.setFillColor(229, 231, 234)
    doc.line(xOffset + (cellWidth * i) + cellWidth,cellHeight * 2 + 50, xOffset + (cellWidth * i) + cellWidth, cellHeight * 8 + cellHeight * countries.length + 66,'F')
    doc.setTextColor(0,0,0)
    })


// Footer

    doc.setFillColor(56, 63, 69)
    doc.rect(0 , 1060 , 2000, 200 ,'F')
    doc.setTextColor(255, 255, 255);
    image.src = 'assets/img/ue_white.png'
    doc.addImage(image,'png', 130, 1073, 190, 40, 10);

    doc.setFont('Roboto', 'normal')
    doc.text(555, 1097, 'TGEU Trans Rights Map Europe & Central Asia');
    doc.setFontSize(9)
    doc.text(803, 1097, 'is available under a Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International Licence.');
    doc.setTextColor(255, 136, 0);
    doc.textWithLink('transrightsmap.tgeu.org', 1358, 1097, {url:'transrightsmap.tgeu.org'});

    doc.save(pdfName)
    doc.output('dataurlnewwindow',{filename:pdfName});
}