import styles from './styles.css';

const PartnerCard = ({picture, description}) => {

    return <div className={styles.partnerCard}>
        <img src={picture} alt="logo" />
        <p>{description}</p>
    </div>
}

export default PartnerCard;