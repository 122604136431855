import mapData from '../../assets/map.json'
import { createRef } from 'preact'
import { forwardRef } from 'preact/compat';
import { useEffect, useImperativeHandle, useState } from 'preact/hooks'
import styles from './styles.css';
import * as Icon from 'react-feather';
import { Text } from 'preact-i18n';
import { debounce, downloadCountries, haveIndicator, inSelectedZone } from '../../utils';
import { Link } from 'preact-router';
import { Icons } from "../../assets/img";

const MapSVG = ({definitions, language, mainTabs, selectedCountry, selectedZone, handleSelectCountry, showIndicatorsInPanel, type, subtype, interactive, getPoints, selectedClusterIndex, maxPoints, countries, getColor, colors, mainColor, height, viewBox, getText, forceMainColor, selectedIndicatorCallback, resetCountry}) => {

    const tooltipRef = createRef()

    const [mapState, setMapState] = useState({
        heightMap: 0,
        zoom: 1,
        position: [0, 0],
        panningOrigin: null,
        pinchZoomOriginState: {position: null, distance: null},
        previouslyOpenedPanel: selectedCountry !== null,
        smallScreen: !(window.innerWidth > 800
            || document.documentElement.clientWidth > 800
            || document.body.clientWidth > 800),
        selectedIndicator: null,
        dragged:false,
    })
    const [selectCountryEnabled, setSelectCountryEnabled] = useState(true);

    const handleSelectIndicator = (key) => {
        setMapState({...mapState, selectedIndicator: key})
        if(selectedIndicatorCallback)
            selectedIndicatorCallback(key)
    }

    const baseHeightSVG = 500;
    const aspectRatio = 5;

    const {heightMap, zoom, position, panningOrigin, pinchZoomOriginState, previouslyOpenedPanel, smallScreen, selectedIndicator, dragged} = mapState

    const handleHover = (event, country) => {
        if(interactive && !event.touches) {
            const container = document.getElementById("svgContainer");
            tooltipRef.current.selectHoveredCountry(country);
            tooltipRef.current.selectToolTipPosition([
                event.offsetX === undefined ? event.layerX : event.offsetX,
                event.offsetY === undefined ? event.layerY : event.offsetY
            ]);
            tooltipRef.current.selectContainerSize([container.offsetWidth, container.offsetHeight]);
        }
    }

    const handleLeaveMap = () => {
        if(interactive)
            tooltipRef.current.selectHoveredCountry(null);
    }

    const getPosition = (el) => {
        let x = 0;
        let y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            x += el.offsetLeft - el.scrollLeft;
            y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: y, left: x };
    }

    const getEventPosition = (event, mouseBool, zoomBool) => {
        return mouseBool ? [event.clientX, event.clientY] : zoomBool ? [(event.touches[0].clientX + event.touches[1].clientX)/2, (event.touches[0].clientY + event.touches[1].clientY)/2] : [event.touches[0].clientX, event.touches[0].clientY]
    }

    const getZoomTouchDistance = (event) => {
        return Math.sqrt((event.touches[0].clientX - event.touches[1].clientX)**2 + (event.touches[0].clientY - event.touches[1].clientY)**2)
    }

    const handleZoomClick = (zoomIn) => {
        const zoomRatio = zoomIn ? 1.3 : 1/1.3;
        const previousHeight = baseHeightSVG/zoom;
        const previousWidth = baseHeightSVG*aspectRatio/zoom;
        const layerX = document.getElementById("svgContainer").clientWidth/2;
        const layerY = document.getElementById("svgContainer").clientHeight/2;
        if(zoom*zoomRatio >= 0.5) {
            setMapState({...mapState, zoom: zoom * zoomRatio, position: [position[0] + previousWidth*(1-1/zoomRatio)*layerX/(heightMap*aspectRatio), position[1] + previousHeight*(1-1/zoomRatio)*layerY/heightMap]})
        }
    }

    const handleWheel = (e) => {
        if(interactive) {
            const path = e.path ?? e.composedPath();
            if (path.length <= 15) {
                const zoomRatio = e.wheelDelta > 1 ? 1.3 : 1 / 1.3;
                const previousHeight = baseHeightSVG / zoom;
                const previousWidth = baseHeightSVG * aspectRatio / zoom;
                const layerX = getEventPosition(e, true, true)[0] - getPosition(document.getElementById("svgContainer")).left;
                const layerY = getEventPosition(e, true, true)[1] - getPosition(document.getElementById("svgContainer")).top;
                if (zoom * zoomRatio >= 0.5) {
                    setMapState({
                        ...mapState,
                        zoom: zoom * zoomRatio,
                        position: [position[0] + previousWidth * (1 - 1 / zoomRatio) * layerX / (heightMap * aspectRatio), position[1] + previousHeight * (1 - 1 / zoomRatio) * layerY / heightMap]
                    })
                }
            }
        }
    }

    const handleTouchMove = (e) => {
        if(interactive) {
            if (e.touches.length === 1) handleMoveSVG(e, false)
            else handlePinchZoom(e)
        }
    }

    const handleMouseDownSVG = (e) => {
        if(interactive && !dragged) {
            setMapState({...mapState, dragged: true, panningOrigin: getEventPosition(e, true, false)});
        }
    }

    const handleTouchStart = (e) => {
        if(interactive)
            setMapState({...mapState,dragged:true, panningOrigin: e.touches.length === 1 ? getEventPosition(e, false, false) : null, pinchZoomOriginState: e.touches[1] ? {position: getEventPosition(e, false, true), distance: getZoomTouchDistance(e)} : {position: null, distance: null}})
    }

    const handleMouseUpSVG = () => {
        if(interactive) {
            setMapState({...mapState, dragged: false, panningOrigin: null});
            setTimeout(() => { setSelectCountryEnabled(true)},50);
        }
    }

    const handleTouchEnd = (e) => {
        if(interactive)
            setMapState({...mapState,dragged:false, panningOrigin: e.touches[0] ? [e.touches[0].clientX, e.touches[0].clientY] : null, pinchZoomOriginState: {position: null, distance: null}})
    }

    const onSelectCountryOnSVG = (country) => {
        if(interactive && !dragged && selectCountryEnabled) {
            if (selectCountryEnabled) handleSelectCountry(country)
        }
    }

    const handleMoveSVG = (e, mouseBool) => {
        if(interactive) {
            const path = e.path ?? e.composedPath();
            if (path.length <= 15 && dragged) {
                if (panningOrigin) {
                    if (selectCountryEnabled) setSelectCountryEnabled(false)
                    const ratio = baseHeightSVG / zoom / heightMap;
                    const eventPosition = getEventPosition(e, mouseBool, false);
                    setMapState({
                        ...mapState,
                        panningOrigin: eventPosition,
                        position: [position[0] - (eventPosition[0] - panningOrigin[0]) * ratio, position[1] - (eventPosition[1] - panningOrigin[1]) * ratio]
                    })
                } else {
                    tooltipRef.current.selectToolTipPosition([
                        e.offsetX === undefined ? e.layerX : e.offsetX,
                        e.offsetY === undefined ? e.layerY : e.offsetY
                    ]);
                }
            }
        }
    }

    const handlePinchZoom = (e) => {
        if(pinchZoomOriginState.position){
            const distance = getZoomTouchDistance(e);
            if(Math.abs(distance - pinchZoomOriginState.distance) > 50) {
                const zoomRatio = distance > pinchZoomOriginState.distance ?  1.3 : 1/1.3;
                const previousHeight = baseHeightSVG/zoom;
                const previousWidth = baseHeightSVG*aspectRatio/zoom;
                const layerX = pinchZoomOriginState.position[0] - getPosition(document.getElementById("svgContainer")).left;
                const layerY = pinchZoomOriginState.position[1] - getPosition(document.getElementById("svgContainer")).top;
                if(zoom*zoomRatio >= 0.5) {
                    setMapState({...mapState,
                        dragged: true,
                                    zoom: zoom * zoomRatio,
                                    pinchZoomOriginState: {position: pinchZoomOriginState.position, distance},
                                    position: [position[0] + previousWidth*(1-1/zoomRatio)*layerX/(heightMap*aspectRatio), position[1] + previousHeight*(1-1/zoomRatio)*layerY/heightMap]
                                })
                }
            }
        } else {
            setMapState({...mapState, pinchZoomOriginState: {position: getEventPosition(e, false, true), distance: getZoomTouchDistance(e)}})
        }
    }

    const resize = () => {
        const smallScreen = !(window.innerWidth > 800
            || document.documentElement.clientWidth > 800
            || document.body.clientWidth > 800);
        setMapState({...mapState,
            heightMap: document.getElementById("homeMapContainer")?.clientHeight,
            zoom: smallScreen ? 1.5 : 2.3,
            position: [smallScreen ? 50 : -80, smallScreen ? baseHeightSVG*2/5 : baseHeightSVG*52/100],
            smallScreen
        })
    }

    // resize on load
    useEffect(() => {
        setTimeout(() =>{
            resize()
        }, 800)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const handleResize = debounce(() => {
            resize();
        }, 800)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    })

    useEffect(() => {
        if(selectedIndicator) handleSelectIndicator(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subtype, selectedClusterIndex, type])

    useEffect(() => {
        if(selectedCountry){
            const countryCode = Object.keys(mapData)[Object.values(mapData).findIndex(c => c.name === selectedCountry.name)]
            const countryBox = document.getElementById(countryCode).getBBox()
            const svgBox = document.getElementById("svgContainer")
            const centerPosition = countryCode !== "RU" ? [countryBox.x + countryBox.width / 2, countryBox.y + countryBox.height / 2] :  [500, 300]
            const newZoom = countryCode !== "RU" ? baseHeightSVG/countryBox.height/(smallScreen ? 6 : 4) : (smallScreen ? 0.8 : 0.7)
            const veryBigScreen = window.innerWidth > 1350
                || document.documentElement.clientWidth > 1350
                || document.body.clientWidth > 1350;
            setMapState({...mapState,
                zoom: newZoom,
                position: [centerPosition[0] - baseHeightSVG / newZoom * (svgBox.clientWidth - (!veryBigScreen || previouslyOpenedPanel ? 0 : 415)) / svgBox.clientHeight / 2, centerPosition[1] - (baseHeightSVG - (smallScreen ? 100 : 0)) / newZoom  / 2],
                previouslyOpenedPanel: true
            })
        }
        else setMapState({...mapState, previouslyOpenedPanel: false})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountry, countries, smallScreen])

    const russiaCountry = mapData["RU"];


    /*window.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) { event.preventDefault(); }
      }, { passive: false });
    */

    return (
        <div
            id="svgContainer"
            onMouseLeave={handleLeaveMap}
            className={styles.svgContainer}
            onWheel={(e) => handleWheel(e)}
            onMouseDownCapture={(e) => handleMouseDownSVG(e)}
            onTouchStart={(e) => handleTouchStart(e)}
            onTouchMove={(e) => handleTouchMove(e)}
            onTouchEnd={(e) => handleTouchEnd(e)}
            onMouseUpCapture={handleMouseUpSVG}
            onMouseMove={(e) => handleMoveSVG(e, true)}
        >
            <svg style={{transition: "viewbox 1s ease"}} height={height? height: heightMap} viewBox={viewBox? viewBox : `${position[0]} ${position[1]} ${baseHeightSVG*aspectRatio/zoom} ${baseHeightSVG/zoom}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                {
                    Object.keys(mapData).filter(c => mapData[c].included).map((k) => (
                        <CountrySVG
                            key={k}
                            id={k}
                            type={type}
                            definitions={definitions}
                            forceMainColor={forceMainColor}
                            selectedIndicator={selectedIndicator}
                            handleLeaveMap={handleLeaveMap}
                            handleSelectCountry={onSelectCountryOnSVG}
                            interactive={interactive}
                            countries={countries}
                            country={mapData[k]}
                            handleHover={handleHover}
                            selectedCountry={selectedCountry}
                            selectedZone={selectedZone}
                            getColor={getColor}
                            mainColor={mainColor}
                            zoom={zoom}
                            colors={colors}
                        />
                    ))
                }
                {
                    <g id="RU">
                        {
                            russiaCountry.g.map((d, i) => {
                                return <CountrySVG key={i} id={`RU${i}`} type={type} definitions={definitions} selectedIndicator={selectedIndicator} handleLeaveMap={handleLeaveMap} forceMainColor={forceMainColor} handleSelectCountry={onSelectCountryOnSVG} interactive={interactive} d={d} countries={countries} country={russiaCountry} handleHover={handleHover} selectedCountry={selectedCountry} selectedZone={selectedZone} getColor={getColor} mainColor={mainColor} zoom={zoom} colors={colors}/>
                            })
                        }
                    </g>
                }
            </svg>
            {interactive && <button className={`secondaryButton ${styles.zoom}`} onMouseEnter={() => handleLeaveMap()} ><button onClick={() => handleZoomClick(true)}><Icon.Plus /></button>|<button onClick={() => handleZoomClick(false)}><Icon.Minus /></button></button>}
            <ToolTipCountry ref={tooltipRef} getPoints={getPoints} maxPoints={maxPoints} getText={getText} type={type} countries={countries} selectedIndicator={selectedIndicator}/>
            {
                !smallScreen && subtype && selectedClusterIndex !== null
                    && <FilterCardCluster  {...{definitions, language, mainTabs, type, subtype, selectedIndicator, handleSelectIndicator, countries, colors, getColor, selectedClusterIndex, selectedZone}} />
            }
            {
                showIndicatorsInPanel && <FilterCardGradient {...{definitions, type, subtype, mainTabs, selectedIndicator, handleSelectIndicator}} smallScreen={smallScreen} />
            }
        </div>
    )
}

export default MapSVG;

export const CountrySVG = ({id, definitions, countries, country, d, handleHover, selectedCountry, handleSelectCountry, selectedIndicator, selectedZone, getColor, mainColor, forceMainColor, interactive, zoom, handleLeaveMap,colors}) => {

    const countryWithData = countries?.find(c => c.name === country.name)

    const getInitialColor = () => {
        return countries ? getColor(country): forceMainColor ? mainColor : "#9EA5AD"
    }

    const onHover = (e) => {
        if(interactive && inSelectedZone()) {
            setColor("#a0a5ac")
            handleHover(e, country)
        }
    }

    const onLeaveHover = () => {
        if(interactive) {
            setColor(getInitialColor())
            handleLeaveMap()
        }
    }

    const [color, setColor] = useState(getInitialColor())

    useEffect(() => {
        setColor(getInitialColor())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColor, selectedCountry, selectedZone])

    const isSelected = selectedCountry?.name === country.name;

    const fillColor = () => {
        if(selectedCountry && isSelected) return mainColor ?? "var(--main-color)"
        if(selectedIndicator) {
            const indicator = haveIndicator(definitions, selectedIndicator, countryWithData)
            if(indicator === null) return "#d8d8d8"
            return !haveIndicator(definitions, selectedIndicator, countryWithData) ? "#FEECE0" : colors.at(-1)
        }
        return color
    }

    return !inSelectedZone(countries, selectedZone, countryWithData) ? null : <path 
        id={id}
        style={{cursor: interactive ? "pointer" : "inherit"}}
        onMouseEnter={(e) => onHover(e)}
        onMouseLeave={onLeaveHover}
        onTouchEnd={onLeaveHover}
        onClick={() => {if(interactive){handleSelectCountry({...country}); handleLeaveMap();}}}
        d={d ? d : country.d} 
        fill={fillColor()}
        fillOpacity={(selectedCountry && !isSelected) ? "0.3" : "1"}
        stroke={isSelected? (mainColor ? mainColor : "var(--main-color)") : color === "#FEECE0" ? `#E5E7EA` : "#FEECE0"}
        stroke-width={`${0.6/zoom}`} 
        />
}

const FilterCardCluster = ({definitions, language, mainTabs, selectedIndicator, handleSelectIndicator, countries, colors, getColor, selectedClusterIndex, selectedZone, subtype}) => {

    const getCountriesInCluster = (clusterIndex) => {
        return countries.filter(c => colors.indexOf(getColor(c)) === clusterIndex && inSelectedZone(countries, selectedZone, c) && (!selectedIndicator || haveIndicator(definitions, selectedIndicator, c)));
    }

    const getNumberCountriesInSelectedZone = () => {
        return countries.filter(c => inSelectedZone(countries, selectedZone, c)).length
    }

    const [expandCard, setExpandcard] = useState(false)

    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        navigator.clipboard.writeText(getCountriesInCluster(selectedClusterIndex).map(c => c.name).join(", "))
        setCopied(true)
    }

    useEffect(() => {
        setCopied(false)
        setExpandcard(false)
    }, [selectedClusterIndex])

    return <div className={styles.detailsIndicators}>
        <h3>
            <span style={{fontWeight: "600"}}>
                {getCountriesInCluster(selectedClusterIndex).length}
            </span>
            /{getNumberCountriesInSelectedZone()} <Text id="maps.Countries" /></h3>
        {subtype.id === 1 && <p><Text id="maps.Have" /> <Text id={`maps.Cluster ${selectedClusterIndex}`} /></p>}
        <div className={styles.indicatorsList}>
            {
                subtype.id === 1 && Object.keys(definitions.EN.maps).filter(k => k.includes(`Cluster 1-${selectedClusterIndex} Indicator`)).map(k => {
                    if(k.includes("Jump to")) {
                        const [typeId, subtypeId] = [k.split("-")[2].trim()[k.split("-")[2].trim().length - 1], k.split("-")[3].trim()];
                        const tab = mainTabs.find(t => t.id == typeId);
                        return <Link href={`/home/${tab.link}/${tab.subtabs.find(s => s.id == subtypeId)?.link}`} key={k}>
                            <h5 style={{opacity: selectedIndicator ? 0.5: 1}}><Text id={`maps.${k}`} /></h5>
                        </Link>
                    }
                    return <button key={k} onClick={() => handleSelectIndicator(selectedIndicator === k ? null : k)} >
                            <h5 style={{opacity: selectedIndicator && selectedIndicator !== k ? 0.5: 1 }}><Text id={`maps.${k}`} /></h5>
                        </button>
                })
            }
        </div>
        <div className={styles.countriesList}>
            <h4 onClick={() => setExpandcard(!expandCard)}><Text id="maps.Countries" /></h4>
            <button className={styles.expandCountryList} onClick={() => setExpandcard(!expandCard)} >{expandCard ? <Icon.ChevronUp /> : <Icon.ChevronDown />}</button>
            {
                expandCard && <div>
                    {
                        getCountriesInCluster(selectedClusterIndex).map((c, i) => (
                            <p key={i}><Text id={`navigation.${c.name}`} /></p>
                        ))
                    }
                </div>
            }
            {expandCard && <button className="secondaryButton">
                    {
                        copied ? <Icon.Check style={{width: "16px", marginRight: "10px"}} onClick={handleCopy} />
                        : <p style={{color: "var(--main-color)", marginRight: "10px"}} onClick={handleCopy}><Text id="maps.Copy" /></p>
                    }
                    <img src={Icons.csv_icon} alt="csv_icon" onClick={() => downloadCountries(definitions, language, getCountriesInCluster(selectedClusterIndex), selectedClusterIndex, null)} />
                </button>
            }
        </div>
    </div>
}

const FilterCardGradient = ({definitions, type, subtype, mainTabs, selectedIndicator, handleSelectIndicator, smallScreen}) => {

    const [expandCard, setExpandcard] = useState(!smallScreen)

    return <div className={styles.detailsIndicators}>
        <h4 onClick={smallScreen ? () => setExpandcard(!expandCard) : null}><Text id={"maps.Filter introductory"}/>{smallScreen ? (expandCard ? " -" : " +"):    null}</h4>
        <div className={styles.indicatorsList} style={{maxHeight: expandCard ? '200px' : 0}}>
            {
                Object.keys(definitions.EN.maps).filter(k => k.includes(`Map ${type.id}-${subtype ? subtype.id: ""} Indicator`)).map(k => {
                    if(k.includes("Jump to")) {
                        const [typeId, subtypeId] = [k.split("-")[1].trim()[k.split("-")[1].trim().length - 1], k.split("-")[2].trim()];
                        const tab = mainTabs.find(t => t.id == typeId);
                        return <Link href={`/home/${tab.link}/${tab.subtabs.find(s => s.id == subtypeId)?.link}`} key={k}>
                            <h5 style={{opacity: selectedIndicator ? 0.5: 1}}><Text id={`maps.${k}`} /></h5>
                        </Link>
                    }
                    return <button key={k} onClick={() => handleSelectIndicator(selectedIndicator === k ? null : k)} >
                            <h5 style={{opacity: selectedIndicator && selectedIndicator !== k ? 0.5: 1 }}><Text id={`maps.${k}`} /></h5>
                        </button>
                })
            }
        </div>
    </div>
}

const ToolTipCountry = forwardRef(({getPoints, maxPoints, type, countries, getText, selectedIndicator}, ref) => {
    const [hoveredCountry, setHoveredCountry] = useState(null);
    const [tooltipPosition, setToolTipPosition] = useState([0, 0]);
    const [containerSize, setContainerSize] = useState([0, 0]);

    useImperativeHandle(ref, () => ({
        selectHoveredCountry (country) {
            setHoveredCountry(country)
        },
        selectToolTipPosition (position) {
            setToolTipPosition(position)
        },
        selectContainerSize (size) {
            setContainerSize(size)
        }
    }), [])

    const getPointFromString = (str) => {
        return str !== "N.A." ? eval(str).toString().replace('.', ',') : 0;
    }

    const mouseInLeftPart = tooltipPosition[0] / containerSize [0] < 0.5;
    const mouseInTopPart = tooltipPosition[1] / containerSize [1] < 0.5;

    return (
        <div>
            {
                hoveredCountry &&
                <div
                    className={styles.toolTipCountry}
                    style={{left: mouseInLeftPart ? `${tooltipPosition[0]}px` : 'inherit', top: mouseInTopPart ? `${tooltipPosition[1]}px` : 'inherit', right: !mouseInLeftPart ? `${containerSize[0] - tooltipPosition[0]}px` : 'inherit', bottom: !mouseInTopPart ? `${containerSize[1] - tooltipPosition[1]}px` : 'inherit'}}
                 >
                    <h2><Text id={`navigation.${hoveredCountry.name}`} /></h2>
                    <p>{getText ? getText(hoveredCountry) : (`${getPointFromString(getPoints(type.id - 1, countries.find(c => c.name === hoveredCountry.name), selectedIndicator))}/${selectedIndicator ? 1 : maxPoints}`)}</p>
                    <p>{getText ? null : <Text id="maps.Country preview" />}</p>
                </div>
            }
        </div>
    )
});