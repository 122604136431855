import IndicatorCard from '../../components/Cards/IndicatorCard/IndicatorCard';
import TemplateScreen from '../../components/Template/TemplateScreen';
import './styles.css';
import { useState } from 'preact/hooks'
import { MarkupText, Text } from 'preact-i18n';

const Indicators = ({definitions, mainTabs, bottomTabs, languages, language, setLanguage}) => {

    const indicators = [
        {
            name: <Text id="indicators.Category 1" />,
            description: <Text id="indicators.Definition 1" />,
            categories: [
                {
                    name: <Text id="indicators.Subtitle 1-1" />,
                    description: <Text id="indicators.Text 1-1" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-2" />,
                    description: <Text id="indicators.Text 1-2" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-3" />,
                    description: <Text id="indicators.Text 1-3" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-4" />,
                    description: <Text id="indicators.Text 1-4" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-5" />,
                    description: <MarkupText id="indicators.Text 1-5" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-6" />,
                    description: <Text id="indicators.Text 1-6" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-7" />,
                    description: <Text id="indicators.Text 1-7" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-8" />,
                    description: <Text id="indicators.Text 1-8" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-9" />,
                    description: <Text id="indicators.Text 1-9" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-10" />,
                    description: <Text id="indicators.Text 1-10" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-11" />,
                    description: <Text id="indicators.Text 1-11" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-12" />,
                    description: <MarkupText id="indicators.Text 1-12" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-13" />,
                    description: <MarkupText id="indicators.Text 1-13" />
                },
                {
                    name: <Text id="indicators.Subtitle 1-14" />,
                    description: <MarkupText id="indicators.Text 1-14" />
                },
            ],
        },
        {
            name: <Text id="indicators.Category 2" />,
            description: <Text id="indicators.Definition 2" />,
            categories: [
                {
                    name: <Text id="indicators.Subtitle 2-1" />,
                    description: <Text id="indicators.Text 2-1" />
                },
                {
                    name: <Text id="indicators.Subtitle 2-2" />,
                    description: <MarkupText id="indicators.Text 2-2" />
                },
                {
                    name: <Text id="indicators.Subtitle 2-3" />,
                    description: <MarkupText id="indicators.Text 2-3" />
                },
            ],
        },
        {
            name: <Text id="indicators.Category 3" />,
            description: <Text id="indicators.Definition 3" />,
            categories: [
                {
                    name: <Text id="indicators.Subtitle 3-1" />,
                    description: <Text id="indicators.Text 3-1" />
                },
                {
                    name: <Text id="indicators.Subtitle 3-2" />,
                    description: <Text id="indicators.Text 3-2" />
                },
                {
                    name: <Text id="indicators.Subtitle 3-3" />,
                    description: <MarkupText id="indicators.Text 3-3" />
                },
            ],
        },
        {
            name: <Text id="indicators.Category 4" />,
            description: <Text id="indicators.Definition 4" />,
            categories: [
                {
                    name: <Text id="indicators.Subtitle 4-1" />,
                    description: <Text id="indicators.Text 4-1" />
                },
                {
                    name: <Text id="indicators.Subtitle 4-2" />,
                    description: <Text id="indicators.Text 4-2" />
                },
                {
                    name: <Text id="indicators.Subtitle 4-3" />,
                    description: <Text id="indicators.Text 4-3" />
                },
                {
                    name: <Text id="indicators.Subtitle 4-4" />,
                    description: <Text id="indicators.Text 4-4" />
                },
                {
                    name: <Text id="indicators.Subtitle 4-5" />,
                    description: <Text id="indicators.Text 4-5" />
                },
                {
                    name: <Text id="indicators.Subtitle 4-6" />,
                    description: <Text id="indicators.Text 4-6" />
                },
                {
                    name: <Text id="indicators.Subtitle 4-7" />,
                    description: <MarkupText id="indicators.Text 4-7" />
                },
                {
                    name: <Text id="indicators.Subtitle 4-8" />,
                    description: <Text id="indicators.Text 4-8" />
                },
            ],
        },
        {
            name: <Text id="indicators.Category 5" />,
            description: <Text id="indicators.Definition 5" />,
            categories: [
                {
                    name: <Text id="indicators.Subtitle 5-1" />,
                    description: <MarkupText id="indicators.Text 5-1" />
                },
                {
                    name: <Text id="indicators.Subtitle 5-2" />,
                    description: <Text id="indicators.Text 5-2" />
                },
            ],
        },
        {
            name: <Text id="indicators.Category 6" />,
            description: <Text id="indicators.Definition 6" />,
            categories: [
                {
                    name: <Text id="indicators.Subtitle 6-1" />,
                    description: <Text id="indicators.Text 6-1" />
                },
                {
                    name: <Text id="indicators.Subtitle 6-2" />,
                    description: <MarkupText id="indicators.Text 6-2" />
                },
            ],
        },
    ]

    const [selectedIndicatorId, setSelectedIndicatorId] = useState(null);

    return <TemplateScreen definitions={definitions} mainTabs={mainTabs} bottomTabs={bottomTabs} title={<Text id="indicators.Main title (H3)" />} language={language} setLanguage={setLanguage} languages={languages} >
        <div className="indicatorsContainer">
           <p><Text id="indicators.Text" /></p>
            <div className="categoriesContainer">
                {
                    indicators && indicators.map((indicator, i) => (
                        <IndicatorCard selected={i === selectedIndicatorId} id={i} setSelectedIndicatorId={setSelectedIndicatorId} key={i} name={indicator.name} description={indicator.description} categories={indicator.categories} />
                    ))
                }
            </div>
        </div>
    </TemplateScreen>
}

export default Indicators;