import { Text } from "preact-i18n";

const IconAndText = ({position, catIndex, fontSize, subcatIndex, value, definitions, language, maxLength}) => {
    
    const [x, y] = position;

    const textSize = fontSize ?? 16;

     const getDetailIcon = (value) => {
        switch (value) {
            case "0":
                return <svg x={x} y ={y + 1} width={textSize+2} height={textSize+2} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="#CED2D6" />
                            <g clip-path="url(#clip0)">
                                <path d="M12.5613 5.43945L5.44043 12.5603" stroke="#E5E7EA" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.53906 5.53857L12.4621 12.4617" stroke="#E5E7EA" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                        </svg>
            case "N.A.":
                return <svg x={x} y ={y + 1} width={textSize+2} height={textSize+2} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="#CED2D6" />
                            <g clip-path="url(#clip0)">
                                <path d="M12.5613 5.43945L5.44043 12.5603" stroke="#E5E7EA" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.53906 5.53857L12.4621 12.4617" stroke="#E5E7EA" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                        </svg>
            case "1":
                return <svg x={x} y ={y + 1} width={textSize+2} height={textSize+2} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="var(--main-color)" />
                            <g>
                                <path d="M13.3412 6.49609L7.49988 12.3374L4.84473 9.68228" stroke="white" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                        </svg>

            default:
                return <svg x={x} y ={y + 1} width={textSize+2} height={textSize+2} viewBox="-2 -2 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="#E5E7EA" stroke="#383F45" stroke-width="1.5" />
                            <g clip-path="url(#clip0)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.02771 2.5824C3.86065 0.74946 6.34664 -0.280273 8.93881 -0.280273C11.531 -0.280273 14.017 0.74946 15.8499 2.5824C17.6828 4.41533 18.7126 6.90133 18.7126 9.49349C18.7126 12.0857 17.6828 14.5717 15.8499 16.4046C14.017 18.2375 11.531 19.2673 8.93881 19.2673C6.34664 19.2673 3.86065 18.2375 2.02771 16.4046C0.194773 14.5717 -0.834961 12.0857 -0.834961 9.49349C-0.834961 6.90133 0.194773 4.41533 2.02771 2.5824ZM8.93881 0.868072C6.6512 0.868072 4.45729 1.77682 2.83971 3.3944C1.22213 5.01198 0.313385 7.20589 0.313385 9.49349C0.313385 11.7811 1.22213 13.975 2.83971 15.5926C4.45729 17.2102 6.6512 18.1189 8.93881 18.1189C11.2264 18.1189 13.4203 17.2102 15.0379 15.5926C16.6555 13.975 17.5642 11.7811 17.5642 9.49349C17.5642 7.20589 16.6555 5.01198 15.0379 3.3944C13.4203 1.77682 11.2264 0.868072 8.93881 0.868072ZM8.93933 7.86435C9.35355 7.86435 9.68933 8.20014 9.68933 8.61435V12.045C9.68933 12.4593 9.35355 12.795 8.93933 12.795C8.52512 12.795 8.18933 12.4593 8.18933 12.045V8.61435C8.18933 8.20014 8.52512 7.86435 8.93933 7.86435ZM8.41891 5.69078C8.56776 5.59132 8.74276 5.53823 8.92177 5.53823C9.16183 5.53823 9.39205 5.63359 9.5618 5.80334C9.73155 5.97309 9.82691 6.20331 9.82691 6.44337C9.82691 6.62238 9.77383 6.79738 9.67437 6.94623C9.57491 7.09508 9.43355 7.2111 9.26816 7.2796C9.10276 7.34811 8.92077 7.36604 8.74519 7.33111C8.56962 7.29619 8.40834 7.20999 8.28175 7.0834C8.15516 6.95681 8.06896 6.79553 8.03403 6.61995C7.99911 6.44437 8.01703 6.26238 8.08554 6.09699C8.15404 5.9316 8.27005 5.79024 8.41891 5.69078Z" fill="#383F45" />
                            </g>
                        </svg>
        }
    }
    
    return (
        <g>
            <text x={x + 30} y={y + textSize}
                fill="#383F45" 
                font-family="Roboto, sans-serif" 
                font-style= "normal" 
                font-weight="normal" 
                font-size={textSize} 
                line-height={textSize*1.5}
                opacity={value === "0" || value === "N.A." ? 0.5: 1}
                textLength={ maxLength && definitions[language].country_page[`Subcategory ${catIndex+1}-${subcatIndex+1}`].length * 6 > 220 ? 220 : "initial"}
            >
                <Text id={`country_page.Subcategory ${catIndex+1}-${subcatIndex+1}`} />
            </text>
            {
                getDetailIcon(value)
            }
        </g>
    )
}

export default IconAndText;