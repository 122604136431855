import { Text } from 'preact-i18n';
import styles from './styles.css'
import * as Icon from 'react-feather'
import { useEffect, useState } from 'preact/hooks';
import {debounce, downloadCsvFile, downloadIndexPDF, downloadPDF, getFilteredCountries} from '../../utils';
import countryShortNames from '../../assets/short-names.json'

const CustomDataGrid = ({definitions, language, countries, categories, mainTabs}) => {

    const [filters, setFilters] = useState([])
    const [selectedZone, setSelectedZone] = useState("All regions")
    const [smallScreen, setSmallScreen] = useState(!(window.innerWidth > 800
        || document.documentElement.clientWidth > 800
        || document.body.clientWidth > 800));
    const [displayDownloadModal, setDisplayDownloadModal] = useState(false)

    const lgrColumns ={
        1: [2, 3, 8, 9, 10, 7,6],
        2: [2, 3, 4],
        3: [12, 13, 5],
        4: [11],
        5: [14],
        6: [10],
        7: [7]
    }

    useEffect(() => {
        const handleResize = debounce(() => {
            setSmallScreen(!(window.innerWidth > 800
                || document.documentElement.clientWidth > 800
                || document.body.clientWidth > 800));
        }, 500)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    })

    const zones = [
        {
            name:"All regions",
            countries: countries?.map(country => country.name)
        },
        {
            name:"Council of Europe",
            countries: [
                "Iceland",
                "France",
                "Italy",
                "Spain",
                "Ireland",
                "United Kingdom",
                "Portugal",
                "Belgium",
                "Denmark",
                "Netherlands",
                "Switzerland",
                "Luxembourg",
                "Sweden",
                "Norway",
                "Finland",
                "Estonia","Latvia","Lithuania","Ukraine","Georgia","Azerbaijan","Armenia","Turkey","Greece","Bulgaria","North Macedonia",
                "Albania","Montenegro","Bosnia & Herzegovina","Serbia","Romania","Moldova","Poland","Slovakia","Hungary","Croatia","Slovenia","Austria","Czechia","Germany",
                "Monaco","San Marino","Andorra","Malta","Cyprus","Kosovo","Liechtenstein"
            ]
        },
        {
            name:"Central Asia",
            countries: ["Kazakhstan","Uzbekistan","Turkmenistan","Tajikistan","Kyrgyzstan"]
        },
        {
            name:"EU countries",
            countries: [
                "France",
                "Italy",
                "Spain",
                "Ireland",
                "Portugal",
                "Belgium",
                "Netherlands",
                "Luxembourg",
                "Sweden",
                "Finland",
                "Estonia","Latvia","Lithuania","Greece","Bulgaria",
                "Romania","Poland","Slovakia","Hungary","Croatia","Slovenia","Austria","Czechia","Germany",
                "Cyprus","Denmark","Malta"
            ]
        },

    ]
    const getSmallCategories = () => {
        let smallCat = [];
        Object.values(categories ?? {})?.forEach(catArray => catArray.forEach(cat => smallCat.push(cat)))
        return smallCat
    }

    const getSmallCategoriesOfCategoryIndex = (i) => {
        return Object.values(categories)[i]
    }

    const getOtherSmallCategories = () => {
        if(!countries) return []
        return Object.keys(countries[0]?.data).filter(k => !getSmallCategories().includes(k))
    }

    const getAppliedFiltersAndValues = () => {
        const payload = []
        const smallCat = getSmallCategories()
        filters.forEach((filter,i) => {
            if(filter.length && i > 0) {
                payload.push(smallCat[i - 1])
            }
        })
        return payload.length ? payload : null
    }

    const resetFilters = () => {
        const filters = []
        
        filters.push([]) // for the country filter
        // eslint-disable-next-line no-unused-vars
        getSmallCategories().forEach(_ => filters.push([]))
        // eslint-disable-next-line no-unused-vars
        getOtherSmallCategories().forEach(_ => filters.push([]))
        setFilters( filters)
        setSelectedZone("All regions")
    }

    useEffect(() => {
        resetFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories])

    if(!categories) return null;

    const bigCategories = Object.keys(categories);

    const formatCell = (smallCat, cell) => {
        return cell === "1" ? <Icon.Check style={{height: "16px"}} /> : cell === "0" ? "" : cell.includes("N.A") ? "N.A." : cell
    }   
    
    const formatCellNotBinary = (cell) => {
        return cell.toLowerCase() === "true" ? <Icon.Check style={{height: "16px"}} /> : cell.toLowerCase() === "false" ? "" : cell
    }   

    const handleApplyFilter = (index, filter) => {
        setFilters( [...filters.slice(0, index), filter, ...filters.slice(index + 1)])
    }
    const handleClearFilter = (index) => {
        setFilters([...filters.slice(0, index), [], ...filters.slice(index + 1)])
    }

    const getColors = (index) => {
        return index%2 === 0 ? ["#E5E7EA", "#E5E7EA", "#FFFFFF"]: ["#E5E7EA", "#E5E7EA", "#FFFFFF"]
    }

    const selectZone = (zone) => {
        handleApplyFilter(0,zone.countries)
        setSelectedZone(zone.name)
    }

    const handleDownload = (type) => {
        if(type==="csv") {
            return downloadCsvFile(
                definitions,
                language,mainTabs,
                lgrColumns,
                null,
                filters[0].length ? countries.filter(c => filters[0].includes(c.name)) : countries,
                categories,
                null,
                null,
                null,
            )
        }
            return downloadIndexPDF(
                definitions,
                language,
                categories,
                getSmallCategories(),
                getFilteredCountries(
                    countries,
                    getSmallCategories(),
                    filters
                ),
                selectedZone,
                filters
            )
    }

    const cellWidth = 150;

    return ( countries && categories && countries.length > 0 && Object.keys(categories).length > 0 && definitions[language].maps && 
        <div className={styles.indexContainer}>
            {displayDownloadModal && <div className={styles.downloadModal}
                onClick={() => setDisplayDownloadModal(false)}
            >
                <div className={styles.downloadModalContent}>
                    <button
                        className={`secondaryButton ${styles.downloadItem}`}
                        onClick={() => handleDownload('csv')}
                    >
                        <Text id="maps.Export CSV"/><Icon.Download/>
                    </button>
                    <button
                        className={`secondaryButton ${styles.downloadItem}`}
                        onClick={() => handleDownload('pdf')}
                    >
                        <Text id="maps.Export PDF"/>
                        <Icon.Download/>
                    </button>
                </div>
            </div>}
            <div className={styles.actionsContainer}>
                <div className={styles.zonesFiltersContainer}>
                    {zones.map((zone, i) => (
                        <p
                            className={`${styles.zoneFilter} ${selectedZone === zone.name ? styles.activeZoneFilter : ''}`}
                            onClick={() => selectZone(zone)}
                         >
                            <Text id={`index.${zone.name}`}/>
                            <span>{zone.countries.length}</span>
                        </p>
                    ))}
                </div>
                <div className={styles.buttonsContainer}>
                <button className={`secondaryButton`} onClick={resetFilters}><Text id="index.Reset filters" /><Icon.RotateCcw /></button>
                {/*<a href={definitions[language].index.Link} target="_blank" rel="noreferrer" ><button className={`secondaryButton`} ><Text id="country_page.Action" /><Icon.Download /></button></a>*/}
                <button className={`secondaryButton`} onClick={() => setDisplayDownloadModal(true)}><Text id="country_page.Action" /><Icon.Download /></button>
            </div>
            </div>
            <div className={styles.datagridContainer} style={{gridTemplateColumns: `${cellWidth}px 1fr`}} >
                <div className={styles.bigColumns} style={{paddingLeft: `${smallScreen ? cellWidth - 40 : cellWidth + 60}px`}}>
                    {
                        bigCategories.map((_, i) => (
                            <div style={{ width: `${getSmallCategoriesOfCategoryIndex(i).length * cellWidth}px`, backgroundColor:i%2===0 ? "#676E76":"#9a9a9a"}} key={i}>
                                <p style={{color:"#FFFFFF"}}><Text id={`country_page.Category ${i+1}`} /></p>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.smallColumns}>
                    <div className={styles.countriesFilter} style={{width: `${smallScreen ? cellWidth - 40 : cellWidth + 60}px`}}>
                        <Text id="maps.Countries" />
                        <div className={styles.countriesTotal}>({getFilteredCountries(countries, getSmallCategories(), filters).length})</div>
                        <FilterPanel index={0} items={getFilteredCountries(countries, getSmallCategories(), [], filters).map(c => c.name).reverse()} filter={filters[0]} {...{handleApplyFilter, handleClearFilter, definitions, language}} />
                    </div>
                    {
                        bigCategories.map((_, i) => (
                            <div key={i}>
                                {
                                    getSmallCategoriesOfCategoryIndex(i).map((cat, j) => {
                                        const filterIndex = getSmallCategories().indexOf(cat) +1;
                                        return <div key={j} style={{width: `${cellWidth - 20}px`, gap:'4px'}}>
                                            <p><Text id={`country_page.Subcategory ${i+1}-${j+1}`} /></p>
                                            <FilterPanel
                                                color={getColors(i)[j%2]}
                                                index={filterIndex}
                                                items={new Set(countries.map(c => c.data[cat].value))}
                                                filter={filters[filterIndex]}
                                                {...{handleApplyFilter, handleClearFilter, definitions, language}}
                                            />
                                        </div>
                                    })
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={styles.countriesColumn} style={smallScreen && { width: `${cellWidth-40}px`}}>
                    {
                        getFilteredCountries(countries, getSmallCategories(), filters).map((country, i) => (
                            <div key={country.name} style={{backgroundColor: i%2 === 0 ? "white": "#f6f7f9", width: `${smallScreen ? cellWidth - 40 : cellWidth + 60}px`}}>
                                <div className={styles.countryCell}>
                                    {/*<img src={`https://countryflagsapi.com/png/${country.name.toLowerCase()}`} style={{borderRadius:'4px', margin:'8px'}} height={24} width={32}/>*/}
                                    <img src={`assets/flags/${country.name.toLowerCase()}.png`} style={{borderRadius:'4px', margin:'8px'}} height={24} width={32}/>
                                    <p>{smallScreen ?countryShortNames[country.name] :<Text id={`navigation.${country.name}`} />}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.rows} style={{marginLeft : smallScreen ? '-40px':'60px'}}>
                    {
                        getFilteredCountries(countries, getSmallCategories(), filters).map((country,countryIndex) => (
                            <div key={country.name} className={styles.row}>
                                {
                                    bigCategories.map((_, i) => (
                                        getSmallCategoriesOfCategoryIndex(i).map((smallCat, j) => {
                                            const {value} = country.data[smallCat];
                                            return <div
                                                style={{
                                                    //borderTop: countryIndex % 2 === 0 ? null : '1px solid #F6F7F9',
                                                    //borderBottom: countryIndex % 2 === 0 ? null : '1px solid #F6F7F9',
                                                    //borderWidth:  countryIndex % 2 === 0 ? null :'thin',
                                                    //borderColor: countryIndex % 2 === 0 ? null : '#F6F7F9',
                                                    //borderStyle: countryIndex % 2 === 0 ? null : 'solid',
                                                    //borderLeft: (j === 0 && i !== 0 ) ?'1px solid #F2F2F2' : null,
                                                    //borderRight: j === (getSmallCategoriesOfCategoryIndex(i).length -1) && (i !== bigCategories.length - 1) ?'1px solid #F2F2F2' : null,
                                                    width: `${cellWidth}px`,
                                                   // height:'36px'
                                            }} key={j}
                                            ><p>{formatCell(smallCat, value)}</p></div>
                                        })
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.summaryContainer}>
                {getAppliedFiltersAndValues() && <div><div>Selectors : </div><ul>{getAppliedFiltersAndValues().map(f => <li>{f}</li>)}</ul></div>}
            </div>
        </div>
        
    )
}

const FilterPanel = ({definitions, language, index, filter, items, handleApplyFilter, handleClearFilter, color}) => {

    const [selectedItems, setSelectedItems] = useState([])

    const [opened, setOpened] = useState(false)
    
    const handleOpenFilter = () => {
        setOpened(true)
    }
    const handleCloseFilter = () => {
        setOpened(false)
    }

    useEffect(() => {
        setSelectedItems(filter ?? [])
    }, [opened, filter])

    const handleChangeFilter = (event, label) => {
        event.target.checked 
            ? setSelectedItems([...selectedItems.filter(i => i !== label), label]) 
            : setSelectedItems([...selectedItems.filter(i => i !== label)])
    }

    return (
        <div className={styles.filterPanel}>
            <Icon.Filter
                style={{
                    backgroundColor: selectedItems?.length ? "orange": "transparent",
                    color: selectedItems?.length ? "white": "#383F45",
                    padding: '4px',
                    borderRadius:'100%'
                }}
                onClick={() => opened ? handleCloseFilter() : handleOpenFilter()}
            />
            <div style={{display: opened ? "flex": "none"}} >
                <div className={styles.checkboxes}>
                    {
                        Array.from(items).reverse().map(f => (
                            <CheckboxAndText key={f} label={f} checked={selectedItems?.includes(f)} {...{handleChangeFilter, definitions, language}} />
                        ))
                    }
                </div>
                <Icon.X onClick={handleCloseFilter} />
                <div className={styles.filterActions}>
                    <button onClick={() => {handleClearFilter(index); handleCloseFilter()}} >
                        <Text id="index.Clear filters" />
                    </button>
                    <button onClick={() => {handleApplyFilter(index, selectedItems); handleCloseFilter()}} >
                        <Text id="index.Apply filters" />
                    </button>
                </div>
            </div>
        </div>
    )
}

const CheckboxAndText = ({definitions, language, label, checked, handleChangeFilter}) => {

    return <div className={styles.checkbox}>
        <label>
          <input
            type="checkbox"
            value={label}
            checked={checked}
            onChange={(e) => handleChangeFilter(e, label)}
          />

          {label === "1" ? definitions[language].index["Value True"]: label === "0" ? definitions[language].index["Value False"] : label}
        </label>
      </div>
}

export default CustomDataGrid;