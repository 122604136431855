import { Text } from "preact-i18n";
import MapSVG from "./MapSVG";


const MapOfCategory = ({definitions, language, mainTabs, countries, selectedCountry, selectedZone, selectedClusterIndex, type, subtype, lgrColumns, handleSelectCountry, getOverallPoints, getOverallPointsPossible, getPointsBigCategoryOfCountry, getPointsPossibleInCategory, selectedIndicatorCallback, resetCountry}) => {

    const commonProps = {
        countries,
        interactive: true,
        selectedCountry,
        handleSelectCountry,
        mainTabs,
        type,
        subtype,
        selectedZone,
        selectedClusterIndex,
        definitions,
        language,
        resetCountry
    }

    let colors = []

    let customGetPoints;
    let customText;
    let customMaxPoints;
    let showIndicatorsInPanel = true;

    const getPointFromString = (str) => {
        return str !== "N.A." ? eval(str) : 0;
    }

    let getColor = (country) => {
        return colors[Math.ceil(getPointsBigCategoryOfCountry(type.id - 1, countries.find(c => c.name === country.name))/getPointsPossibleInCategory(type.id - 1) * (colors.length - 1))] ?? colors[colors.length - 1];
    }

    const sumFunction = (a, b) => a + b;
    switch (type.link) {
        case "":
            colors = definitions.colors['All categories'].split(',')
            getColor = (country) => {
                return colors[Math.ceil(getOverallPoints(null, countries.find(c => c.name === country.name))/getOverallPointsPossible() * (colors.length - 1))] ?? colors[colors.length - 1];
            }

            showIndicatorsInPanel = false;

            return <MapSVG
                {...commonProps}
                getPoints={getOverallPoints}
                maxPoints={getOverallPointsPossible()}
                getColor={getColor}
                mainColor="var(--secondary-color)"
                selectedIndicatorCallback={selectedIndicatorCallback}
            />

        case "legal-gender-recognition":

            // For each marker, we want different behaviors
            if(subtype.markers) {
                // On LGR availabity and LGR for minors
                if(subtype.id === 2 || subtype.id === 3){
                    switch(subtype.id) {
                        case 2:
                            colors = definitions.colors['LGR availability'].split(',')
                            break;
                        case 3:
                            colors = definitions.colors['LGR for minors'].split(',')
                            break;
                    }
                    // We override the getPoints function to get the points for the specific subtype
                    customGetPoints = (_, country,indicator) => {
                        const data = countries.find(c => c.name === country.name)?.data;
                        if(indicator) {
                            const indicatorIndex = indicator.split('-').at(-1);
                            return getPointFromString(data[definitions.EN.country_page[`Subcategory 1-${indicatorIndex}`]].value);
                        }
                        return lgrColumns[subtype.id].map(column => getPointFromString(data[definitions.EN.country_page[`Subcategory 1-${column}`]].value)).reduce(sumFunction);
                    }

                    // We override the text function to get the text for the specific subtype
                    customMaxPoints = subtype.markers.length;

                    // We override the text function to get the text for the specific subtype
                    getColor = (country) => {
                        return colors[Math.ceil(customGetPoints(null, country))] ?? colors[colors.length - 1];
                    }
                } else {
                    switch (subtype.id) {
                        case 4:
                            colors = definitions.colors['No-divorce requirement'].split(',');
                            break;
                        case 5:
                            colors = definitions.colors["Non-binary recognition"].split(',');
                            break;
                        case 6:
                            colors = definitions.colors["Sterilisation"].split(',');
                            break;
                        case 7:
                            colors = definitions.colors["Mental health diagnosis"].split(',');
                            break;
                    }
                    customGetPoints = (_, country) => {
                        const data = countries.find(c => c.name === country.name)?.data;
                        if([4, 5, 6, 7, 8].includes(subtype.id)) {
                           return data[definitions.EN.country_page[`Subcategory 1-${lgrColumns[subtype.id][0]}`]].value;
                        }
                    }

                    showIndicatorsInPanel = false;

                    getColor = (country) => {
                        let cellValue = customGetPoints(null, country);
                        let colorIndex;
                        colorIndex = cellValue === "N.A." ? 2 : cellValue === "1" ? 1 : 0;
                        if(selectedClusterIndex !== null) {
                            return selectedClusterIndex === colorIndex ? colors[selectedClusterIndex] : colors[colors.length - 1];
                        }
                        else return colors[colorIndex] ?? colors[colors.length - 1];
                    }
                    customText = () => {
                        return "";
                    }
                }
            } else {
                colors = definitions.colors['LGR cluster'].split(',')

                showIndicatorsInPanel = false;

                customGetPoints = (_, country) => {
                    const data = countries.find(c => c.name === country.name)?.data;
                    switch(selectedClusterIndex) {
                        case 0:
                            return data['No legal framework making LGR impossible'].value !== '1' ? "0" : "9";
                        case 1:
                            return data['Existence of legal measures'].value !== "1" && data['Existence of administrative measures'].value !== "1" ? "1" : "6";
                        case 2:
                            return ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && (data['No compulsory medical intervention required'].value !== "1" || data['No compulsory surgical intervention required'].value !== "1" || data['No compulsory sterilisation required'].value !== "1")) ? "2" : "6";
                        case 3:
                            return ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && data['No diagnosis or psychological opinion required'].value !== "1" && data['No compulsory medical intervention required'].value === "1" && data['No compulsory surgical intervention required'].value === "1" && data['No compulsory sterilisation required'].value === "1") ? "3" : "6";
                        case 4:
                            return ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && data['No diagnosis or psychological opinion required'].value === "1" && data["Self-determination"].value !== "1") ? "4" : "6";
                        case 5:
                            return data['Self-determination'].value === "1" ? "5" : "6";
                        default:
                            return data['No legal framework making LGR impossible'].value !== '1' ? "0": data['Existence of legal measures'].value !== "1" && data['Existence of administrative measures'].value !== "1" ? "1"
                            : ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && (data['No compulsory medical intervention required'].value !== "1" || data['No compulsory surgical intervention required'].value !== "1" || data['No compulsory sterilisation required'].value !== "1")) ? "2"
                            : ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && data['No diagnosis or psychological opinion required'].value !== "1" && data['No compulsory medical intervention required'].value === "1" && data['No compulsory surgical intervention required'].value === "1" && data['No compulsory sterilisation required'].value === "1") ? "3"
                            : ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && data['No diagnosis or psychological opinion required'].value === "1" && data["Self-determination"].value !== "1") ? "4"
                            : data['Self-determination'].value === "1" ? "5" : "6";
                    }
                }

                getColor = (country) => {
                    return colors[customGetPoints(null, country)] ?? colors[colors.length - 1];
                }

                // eslint-disable-next-line react/display-name
                customText = (country) => {
                    const points = eval(customGetPoints(null, country));
                    return  <Text id={`maps.Cluster ${points}`} />
                }
            }

            break;

        case "asylum":
            colors = definitions.colors['Asylum'].split(',')
            break;
        case "hate-speech-and-crime":
            colors = definitions.colors['Hate Speech / Crime'].split(',')
            break;
        case "non-discrimination":
            colors = definitions.colors['Non-Discrimination'].split(',')
            break;
        case "health":
            colors = definitions.colors['Health'].split(',')
            break;
        case "family":
            colors = definitions.colors['Family'].split(',')
            break;
        default:
            return <div />
    }

    return <MapSVG
        {...commonProps}
        showIndicatorsInPanel={showIndicatorsInPanel}
        getPoints={customGetPoints ?? getPointsBigCategoryOfCountry}
        maxPoints={customMaxPoints ?? getPointsPossibleInCategory(type.id - 1)}
        getText={customText}
        getColor={getColor}
        colors={colors}
        mainColor="var(--secondary-color)"
        selectedIndicatorCallback={selectedIndicatorCallback}
    />

}

export default MapOfCategory;