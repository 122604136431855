import { MarkupText, Text } from 'preact-i18n';
import TemplateScreen from '../../components/Template/TemplateScreen';
import './styles.css';

const About = ({definitions, mainTabs, bottomTabs, languages, language, setLanguage}) => {

    const previousMaps = [
        {
            year: 2021,
            link :"https://tgeu.org/trans-rights-map-2021/"
        },
        {
            year: 2020,
            link :"https://tgeu.org/trans-rights-europe-central-asia-index-maps-2020/"
        },
        {
            year: 2019,
            link :"https://tgeu.org/trans-rights-europe-central-asia-map-index-2019/"
        },
        {
            year: 2018,
            link :"https://tgeu.org/trans-rights-map-2018/"
        },
        {
            year: 2017,
            link :"https://tgeu.org/trans-rights-map-2017/"
        },
        {
            year: 2016,
            link :"https://tgeu.org/trans-rights_europe_map_2016/"
        },
        {
            year: 2015,
            link :"https://tgeu.org/trans-rights-europe-map-2015/"
        },
        {
            year: 2014,
            link :"https://tgeu.org/trans_rights_europe_map/"
        }
    ]

    return <TemplateScreen definitions={definitions} mainTabs={mainTabs} bottomTabs={bottomTabs} title={<Text id="about.Main title (H3)" />} language={language} setLanguage={setLanguage} languages={languages}>
        <div className="aboutContainer">
            <p><Text id="about.Text" /></p>
            <h2><Text id="about.Subtitle 1" /></h2>
            <p><Text id="about.Text 1-1" /></p>
            <div className="previousMaps">
                {
                    previousMaps && previousMaps.map((map, i) => (
                        <a key={i} href={map.link}><Text id="about.Map link" /> {map.year}</a>
                    ))
                }
            </div>
            <h2><Text id="about.Subtitle 2" /></h2>
            <p>
                <MarkupText id="about.Text 2-1" /><br />
                <br />
                <MarkupText id="about.Text 2-2" />
            </p>
            
            <br />
            <p className="copyright"><MarkupText id="navigation.Copyright" /></p>
        </div>
    </TemplateScreen>
}

export default About;