import { Text } from "preact-i18n";
import styles from "./styles.css";
import * as Icon from "react-feather"
import {copyEmbedCode, downloadCsvFile, downloadImage, downloadPDF, downloadZipImages} from "../../utils";
import SVGContainer from "../svgComponents/SVGContainer";

const DownloadOverlay = ({definitions, idsExport, callback, language, type, mainTabs, lgrColumns, subtype, countries, selectedCountry, selectedZone, categories, forceAllCategoriesInCSV, handleCancelDownload, getOverallPoints, getOverallPointsPossible, getPointsPossibleInCategory, getPointsBigCategoryOfCountry, selectedIndicator}) => {

    return (
        <div className={styles.downloadOverlay}>
            <div className={styles.downloadPanel}>
                <p><Text id="maps.Main button" />:</p>
                <button className={`secondaryButton ${styles.downloadItem}`} onClick={() => downloadCsvFile(definitions, language, mainTabs, lgrColumns, subtype, countries, categories, selectedCountry, selectedZone, type.id > 0 && !forceAllCategoriesInCSV ? Object.keys(categories)[type.id - 1] : null, callback)} ><Text id="maps.Export CSV" /><Icon.Download /></button>
                <button className={`secondaryButton ${styles.downloadItem}`} onClick={() => idsExport.length > 1 ? downloadZipImages(idsExport, definitions, language, type, subtype, selectedCountry, callback) : downloadImage(idsExport[0], definitions, language, type, subtype, selectedCountry, callback)} ><Text id="maps.Export Image" /><Icon.Download /></button>
                <button className={`secondaryButton ${styles.downloadItem}`} onClick={() => downloadPDF(idsExport, definitions, language, type, subtype, selectedCountry, callback)} ><Text id="maps.Export PDF" /><Icon.Download /></button>
                <button className={`secondaryButton ${styles.downloadItem}`} onClick={() => copyEmbedCode()} ><Text id="maps.Copy embed" /><Icon.Download /></button>
            </div>
            {/* <button className={`secondaryButton ${styles.download}`} onClick={() => downloadCsvFile(mapType.id > 0  ? Object.keys(categories)[mapType.id - 1] : null)} ><Text id="maps.Main button" /><Icon.Download /></button> */}
            {/* { countries && <SVGContainer idExport={3} definitions={definitions} smallScreen={smallScreen} categories={categories} countriesData={countries} language={language} type={mapType} subtype={mapSubtype} country={countries[15]} getOverallPoints={getOverallPoints} getOverallPointsPossible={getOverallPointsPossible} getPointsPossibleInCategory={getPointsPossibleInCategory} getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry} />} */}
            <button className={styles.cancelButton} onClick={handleCancelDownload}><Icon.X /><Text id="maps.Cancel" /></button>
            {
                selectedCountry && <SVGContainer 
                visible={true}
                idExport={1} 
                definitions={definitions} 
                categories={categories} 
                countriesData={countries} 
                language={language} 
                type={type} 
                subtype={subtype} 
                country={selectedCountry}
                getOverallPoints={getOverallPoints} 
                getOverallPointsPossible={getOverallPointsPossible} 
                getPointsPossibleInCategory={getPointsPossibleInCategory} 
                getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry} />
            }
            {
                selectedCountry && <SVGContainer 
                visible={true}
                idExport={2} 
                definitions={definitions} 
                categories={categories} 
                countriesData={countries} 
                language={language} 
                type={type} 
                subtype={subtype} 
                country={selectedCountry}
                getOverallPoints={getOverallPoints} 
                getOverallPointsPossible={getOverallPointsPossible} 
                getPointsPossibleInCategory={getPointsPossibleInCategory} 
                getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry} />
            }
            <SVGContainer 
                visible={true}
                idExport={3} 
                definitions={definitions} 
                categories={categories} 
                countriesData={countries} 
                language={language} 
                type={type} 
                subtype={subtype} 
                country={selectedCountry}
                getOverallPoints={getOverallPoints} 
                getOverallPointsPossible={getOverallPointsPossible} 
                getPointsPossibleInCategory={getPointsPossibleInCategory} 
                getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry}
                selectedIndicator={selectedIndicator}
            />
            {
                selectedCountry && <SVGContainer 
                visible={true}
                idExport={4} 
                definitions={definitions} 
                categories={categories} 
                countriesData={countries} 
                language={language} 
                type={type} 
                subtype={subtype} 
                country={selectedCountry}
                getOverallPoints={getOverallPoints} 
                getOverallPointsPossible={getOverallPointsPossible} 
                getPointsPossibleInCategory={getPointsPossibleInCategory} 
                getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry} />
            }
            {
                selectedCountry && <SVGContainer 
                visible={true}
                idExport={5} 
                definitions={definitions} 
                categories={categories} 
                countriesData={countries} 
                language={language} 
                type={type} 
                subtype={subtype} 
                country={selectedCountry}
                getOverallPoints={getOverallPoints} 
                getOverallPointsPossible={getOverallPointsPossible} 
                getPointsPossibleInCategory={getPointsPossibleInCategory} 
                getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry} />
            }
            <SVGContainer 
                visible={true}
                idExport={6} 
                definitions={definitions} 
                categories={categories} 
                countriesData={countries} 
                language={language} 
                type={type} 
                subtype={subtype} 
                country={selectedCountry}
                getOverallPoints={getOverallPoints} 
                getOverallPointsPossible={getOverallPointsPossible} 
                getPointsPossibleInCategory={getPointsPossibleInCategory} 
                getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry}
                selectedIndicator={selectedIndicator}
            />
        </div>
    )
}

export default DownloadOverlay;