import { MarkupText, Text } from 'preact-i18n';
import { Link } from 'preact-router';
import styles from './styles.css'

const Footer = () => {

    return (
        <div className={styles.footer}>
            <p><MarkupText id="navigation.Copyright" /></p>
            <div className={styles.linksContainer}>
                <Link href="/about"><Text id="navigation.About the project" /></Link>
                <div className={styles.divider} />
                <Link href="/acknowledgements"><Text id="navigation.Acknowledgements" /></Link>
                <div className={styles.divider} />
                <a href="https://tgeu.org/privacy-policy/" target={"_blank"}><Text id="navigation.Privacy policy" /></a>
                <div className={styles.divider} />
                <a href="https://tgeu.org/disclaimer/" target={"_blank"}><Text id="navigation.Disclaimer" /></a>
            </div>
        </div>
    )
}

export default Footer;