import { Text } from "preact-i18n";
import { useEffect, useState } from "preact/hooks";
import styles from "./styles";
import * as Icon from "react-feather"

const MobileLegend = ({subtype,selectedClusterIndex, disableCluster0, handleClickOnClusters, colors}) => {

    const [expanded, setExpanded] = useState(false);

    return (
        <div className={styles.legendContainer} style={{ display: 'block'}}>
            <div onClick={() => setExpanded(!expanded)} className={styles.mobileHeader}>
                <p className={styles.mobileHeaderLabel}>{selectedClusterIndex === null ? <Text id={"maps.Keys introductory"}/>: <Text id={subtype.markers ? subtype.markers[selectedClusterIndex] : subtype.clusters[selectedClusterIndex].name}/>}</p>
                <Icon.ChevronUp className={styles.mobileHeaderIcon} style={{transform : expanded ? "rotate(180deg)" : null}}/>
            </div>
            <div style={{maxHeight: expanded ? '200px' : '0', transition:"all .5s ease", overflow:"hidden"}}>
                {subtype.markers ?
                    subtype.markers.map((marker, i) => {
                        const color = colors[i%colors.length];
                        return <div key={i} className={styles.legendItem} onClick={() => {handleClickOnClusters(i);setExpanded(false)}} style={{backgroundColor: i === selectedClusterIndex ? "#EEE": "white"}}>
                            <div style={{backgroundColor: color, border: color === "#FFFFFF" ? `1px solid #E5E7EA` : 'inherit'}} />
                            <p><Text id={marker} /></p>
                        </div>
                    })
                    :
                    subtype.clusters.map((cluster, i) => {
                        const color = colors[i%colors.length];
                        if(disableCluster0 && i === 0) return null;
                        return <div key={i} className={styles.legendItem} onClick={() => {handleClickOnClusters(i);setExpanded(false)}} style={{backgroundColor: i === selectedClusterIndex ? "#EEE": "white"}}>
                            <div style={{backgroundColor: color, border: color === "#FFFFFF" ? `1px solid #E5E7EA` : 'inherit'}} />
                            <p><Text id={cluster.name} /></p>
                        </div>
                    })

                }
            </div>
        </div>
    );
};

const Legend = ({svgModel, definitions, language, position, widthSVG, heightSVG, type, subtype, getOverallPointsPossible, getPointsPossibleInCategory, selectedClusterIndex, setSelectedClusterIndex, handleSelectCountry, smallScreen, selectedIndicator}) => {

    let points = getPointsPossibleInCategory(type.id - 1) ?? getOverallPointsPossible();
    let backgroundGradient;

    let colors;

    const handleClickOnClusters = (index) => {
        setSelectedClusterIndex(index != selectedClusterIndex ? index : null);
        handleSelectCountry(null);
    }

    const gradientFromColors = (colors) => {
        if(selectedIndicator) {
            return `linear-gradient(90deg, #d8d8d8 0%,${colors[0]} 50%, ${colors} 100%)`;
        }
        const percentageStep = 100 / (colors.length - 1);
        const colorStops = colors.map((color, index) => {
            return `${color} ${percentageStep * index}%`;
        });
        return `linear-gradient(90deg, ${colorStops.join(', ')})`;
    }

    const disableCluster0 = false;

    const [widthsBackgroundSVG, setWidthsBackgroundSVG] = useState([0, 0, 0, 0, 0, 0]);

    useEffect(() => {
        if(svgModel && subtype && ![2, 3].includes(subtype.id)) {
            switch(subtype.id){
                case 1:
                    setWidthsBackgroundSVG([0, 1, 2, 3, 4, 5, 6].map(i => Math.min(document.getElementById(`legend-cluster-${heightSVG}-${i}`)?.getBBox()?.width + heightSVG*0.7, widthSVG/3-3.33)))
                    break;
                default:
                    setWidthsBackgroundSVG([0, 1, 2, 3, 4, 5, 6].map(i => Math.min(document.getElementById(`legend-marker-${heightSVG}-${i}`)?.getBBox()?.width + heightSVG*0.7, widthSVG/3-3.33)))
            }
        }
    }, [language, disableCluster0, type, subtype, svgModel, heightSVG, widthSVG])

    const maxWidthRows = !svgModel || !subtype ? null : subtype && subtype.id === 1  ?
            Math.max(widthsBackgroundSVG[1] + widthsBackgroundSVG[2] + widthsBackgroundSVG[3], widthsBackgroundSVG[4] + widthsBackgroundSVG[5])
            : subtype.markers.length <= 3 ? widthsBackgroundSVG[0] + widthsBackgroundSVG[1] + widthsBackgroundSVG[2] : Math.max(widthsBackgroundSVG[0] + widthsBackgroundSVG[1] + widthsBackgroundSVG[2], widthsBackgroundSVG[3] + widthsBackgroundSVG[4] + widthsBackgroundSVG[5])

    let positionLegendSVG;
    if(position) positionLegendSVG = maxWidthRows < widthSVG ? position[0] + (widthSVG - maxWidthRows) / 2 : position[0]
    switch (type.link) {
        case "":
            colors = definitions.colors['All categories'].split(',')
            backgroundGradient = "linear-gradient(100.25deg, #FEEDDE 7.66%, #FDBE85 25.3%, #FD8D3C 42.06%, #F16913 58.38%, #D94801 75.14%, #8C2D04 92.34%)"
            break;

        case "legal-gender-recognition":

            if(subtype.markers) {
                if(subtype.id === 2 || subtype.id === 3){
                    switch(subtype.id) {
                        case 2:
                            colors = definitions.colors['LGR availability'].split(',')
                            break;
                        case 3:
                            colors = definitions.colors['LGR for minors'].split(',')
                            break;
                    }
                    backgroundGradient = gradientFromColors(colors)
                    points = 3;
                    break;
                } else {
                    colors = ["#FDBE85","#FD8D3C", "#FEECE0"];
                    switch (subtype.id) {
                        case 4:
                            colors = definitions.colors['No-divorce requirement'].split(',');
                            break;
                        case 5:
                            colors = definitions.colors["Non-binary recognition"].split(',');
                            break;
                        case 6:
                            colors = definitions.colors["Sterilisation"].split(',');
                            break;
                        case 7:
                            colors = definitions.colors["Mental health diagnosis"].split(',');
                            break;
                    }
                    return svgModel ?
                        <svg x={positionLegendSVG} y={position[1]} width={widthSVG} height={heightSVG}>
                            {
                                subtype.markers.map((marker, i) => {
                                    const color = colors[i%colors.length];
                                    let pos;

                                    const heightBar = heightSVG/2.15;
                                    const posSecondRow = heightSVG*0.53;

                                    switch (i) {
                                        case 0: pos = [0, 0]; break;
                                        case 1: pos = [widthsBackgroundSVG[0] + 5, 0]; break;
                                        case 2: pos = [widthsBackgroundSVG[0] + widthsBackgroundSVG[1] + 10, 0]; break;
                                        case 3: pos = [0, posSecondRow]; break;
                                        case 4: pos = [widthsBackgroundSVG[3] + 5, posSecondRow]; break;
                                        case 5: pos = [widthsBackgroundSVG[3] + widthsBackgroundSVG[4] + 10, posSecondRow]; break;
                                        default: return null;
                                    }

                                    return <svg x={pos[0]} y={pos[1]+heightBar*0.53} width={widthsBackgroundSVG[i]} height="32" key={i}>
                                        <path d={`M 0 ${heightBar/2}
                                                C 0 ${heightBar/4} ${heightBar/4} 0 ${heightBar/2} 0
                                                H ${widthsBackgroundSVG[i] - heightBar/2}
                                                C ${widthsBackgroundSVG[i] - heightBar/4} 0 ${widthsBackgroundSVG[i]} ${heightBar/4} ${widthsBackgroundSVG[i]} ${heightBar/2}
                                                C ${widthsBackgroundSVG[i]} ${heightBar*3/4} ${widthsBackgroundSVG[i] - heightBar/4} ${heightBar} ${widthsBackgroundSVG[i] - heightBar/2} ${heightBar}
                                                H ${heightBar/2}
                                                C ${heightBar/4} ${heightBar} 0 ${heightBar*3/4} 0 ${heightBar/2}Z`} fill="white" />
                                        <circle cx={heightBar/2} cy={heightBar/2} r={heightBar*2/5}  fill={color} />
                                        <text id={`legend-marker-${heightSVG}-${i}`}  x={heightBar*1.15} y={heightBar*0.53} dominant-baseline="middle" fill="#383F45" font-family="Roboto, sans-serif" font-style= "normal" font-weight="400" font-size={heightBar*2.5/5} line-height={heightBar*4/5}><Text id={`${definitions[language].maps[`${marker.split(".")[1]} short name`]?.length > 0 ? `${marker} short name` : `${marker}`}`} /></text>
                                    </svg>
                                })
                            }
                        </svg>
                    : (smallScreen ? <MobileLegend
                                disableCluster0={disableCluster0}
                                handleClickOnClusters={handleClickOnClusters}
                                selectedClusterIndex={selectedClusterIndex}
                                subtype={subtype}
                                colors={colors}
                            />
                            : <div className={styles.legendContainer}>
                        {
                            subtype.markers.map((marker, i) => {
                                const color = colors[i%colors.length];
                                return <div key={i} className={styles.legendItem} onClick={() => handleClickOnClusters(i)} style={{backgroundColor: i === selectedClusterIndex ? "#EEE": "inherit"}}>
                                    <div style={{backgroundColor: color, border: color === "#FFFFFF" ? `1px solid #E5E7EA` : 'inherit'}} />
                                    <p><Text id={marker} /></p>
                                </div>
                            })
                        }
                    </div>)
                }
            }
            colors = definitions.colors['LGR cluster'].split(',')

            return svgModel ?
                    <svg x={positionLegendSVG} y={position[1]} width={widthSVG} height={heightSVG}>
                        {
                            subtype.clusters.map((cluster, i) => {
                                const color = colors[i%colors.length];
                                let pos;

                                const heightBar = heightSVG/2.15;
                                const posSecondRow = heightSVG*0.53;

                                switch (disableCluster0 ? i-1 : i) {
                                    case 0: pos = [0, 0]; break;
                                    case 1: pos = [widthsBackgroundSVG[disableCluster0 ? 1 : 0] + 5, 0]; break;
                                    case 2: pos = [widthsBackgroundSVG[disableCluster0 ? 1 : 0] + widthsBackgroundSVG[disableCluster0 ? 2 : 1] + 10, 0]; break;
                                    case 3: pos = [0, posSecondRow]; break;
                                    case 4: pos = [widthsBackgroundSVG[disableCluster0 ? 4 : 3] + 5, posSecondRow]; break;
                                    case 5: pos = [widthsBackgroundSVG[disableCluster0 ? 4 : 3] + widthsBackgroundSVG[disableCluster0 ? 5 : 4] + 10, posSecondRow]; break;
                                    default: return null;
                                }

                                return <svg x={pos[0]} y={pos[1]} width={widthsBackgroundSVG[i]} height={heightBar} key={i}>
                                    <path d={`M 0 ${heightBar/2}
                                            C 0 ${heightBar/4} ${heightBar/4} 0 ${heightBar/2} 0
                                            H ${widthsBackgroundSVG[i] - heightBar/2}
                                            C ${widthsBackgroundSVG[i] - heightBar/4} 0 ${widthsBackgroundSVG[i]} ${heightBar/4} ${widthsBackgroundSVG[i]} ${heightBar/2}
                                            C ${widthsBackgroundSVG[i]} ${heightBar*3/4} ${widthsBackgroundSVG[i] - heightBar/4} ${heightBar} ${widthsBackgroundSVG[i] - heightBar/2} ${heightBar}
                                            H ${heightBar/2}
                                            C ${heightBar/4} ${heightBar} 0 ${heightBar*3/4} 0 ${heightBar/2}Z`} fill="white" />
                                    <circle cx={heightBar/2} cy={heightBar/2} r={heightBar*2/5}  fill={color} />
                                    <text id={`legend-cluster-${heightSVG}-${i}`} x={heightBar*1.15} y={heightBar*0.53} dominant-baseline="middle" fill="#383F45" font-family="Roboto, sans-serif" font-style= "normal" font-weight="400" font-size={heightBar*2.5/5} line-height={heightBar*4/5}><Text id={`${definitions[language].maps[`${cluster.name.split(".")[1]} short name`]?.length > 0 ? `${cluster.name} short name` : `${cluster.name}`}`} /></text>
                                </svg>
                            })
                        }
                    </svg>
                : (smallScreen ? <MobileLegend
                    disableCluster0={disableCluster0}
                    handleClickOnClusters={handleClickOnClusters}
                    selectedClusterIndex={selectedClusterIndex}
                    subtype={subtype}
                    colors={colors}
                    />
                 : <div className={styles.legendContainer}>
                    {
                        subtype.clusters.map((cluster, i) => {
                            const color = colors[i%colors.length];
                            if(disableCluster0 && i === 0) return null;
                            return <div key={i} className={styles.legendItem} onClick={() => handleClickOnClusters(i)} style={{backgroundColor: i === selectedClusterIndex ? "#EEE": "inherit"}}>
                                <div style={{backgroundColor: color, border: color === "#FFFFFF" ? `1px solid #E5E7EA` : 'inherit'}} />
                                <p><Text id={cluster.name} /></p>
                            </div>
                        })
                    }
                </div>)

        case "asylum":
            colors = definitions.colors['Asylum'].split(',')
            backgroundGradient = gradientFromColors(colors)
            break;
        case "hate-speech-and-crime":
            colors = definitions.colors['Hate Speech / Crime'].split(',')
            backgroundGradient = gradientFromColors(colors)
            break;
        case "non-discrimination":
            colors = definitions.colors['Non-Discrimination'].split(',')
            backgroundGradient = gradientFromColors(colors)
            break;
        case "health":
            colors = definitions.colors['Health'].split(',')
            backgroundGradient = gradientFromColors(colors)
            break;
        case "family":
            colors = definitions.colors['Family'].split(',')
            backgroundGradient = gradientFromColors(colors)
            break;
        default:
            return <div />
    }

    const widthScaleSVGPercentage = 0.8;

    return (
        svgModel ?
            <svg x={position[0] + widthSVG*(1 - widthScaleSVGPercentage)/2} y={position[1] + 20} width={widthSVG*widthScaleSVGPercentage} height={heightSVG} viewBox={`0 0 ${widthSVG*widthScaleSVGPercentage} 69`}>
                <rect x="0.5" y="0.5" width="100%" height="39" rx="8" fill="url(#paint0_linear)" />
                <rect x="0.5" y="0.5" width="100%" height="39" rx="8" stroke="#E5E7EA" />
                <defs>
                    <linearGradient id="paint0_linear" x1="0" y1="150" x2={widthSVG*widthScaleSVGPercentage} y2="150" gradientUnits="userSpaceOnUse">
                        {colors.map((color,i) => (
                            <stop stopColor={colors[i]} offset={i === 0 ? 0 : (1/colors.length)*i} />
                        ))}
                    </linearGradient>
                </defs>
                <text x="20" y={20} dominant-baseline="middle" fill="#383F45" font-family="Roboto, sans-serif" font-style= "normal" font-weight="400" font-size="14" line-height="24">0</text>
                <text x={widthSVG*widthScaleSVGPercentage - 40} y={20} dominant-baseline="middle" fill="white" font-family="Roboto, sans-serif" font-style= "normal" font-weight="400" font-size="14" line-height="24">{points}</text>
            </svg>
        : <div className={styles.scaleContainer}>
                <p className={styles.points}><Text id="maps.Points" /></p>
                <div class={styles.scaleWrapper}>
                    <div className={styles.scale} style={{background: backgroundGradient}}/>
                    <div className={styles.scaleLegend}>
                        {selectedIndicator && <p>N.A</p>}
                        <p>0</p>
                        <p>{selectedIndicator ? 1 : points}</p>
                    </div>
                </div>
        </div>
    )

}

export default Legend;