import { Text } from 'preact-i18n';
import { Link } from 'preact-router';
import { useState } from 'preact/hooks';
import * as Icon from 'react-feather';
import styles from './styles.css'

const SideBar = ({currentTab, currentSubtab, mainTabs, bottomTabs, languages, language, setLanguage}) => {

    const sideMenuLinkStyle = (tab) => {
        return currentTab === tab ? 
        {
            fontWeight: '600'
        } : {
            fontWeight: '400'
        }
    }

    const sideMenuSubLinkStyle = (subtab) => (
        currentSubtab === subtab ? 
        {
            fontWeight: '700',
            borderBottom: "2px solid var(--main-color)"
        } : {
            fontWeight: '400'
        }
    )

    const [expandSmallScreenMenu, setExpandSmallScreenMenu] = useState(false);

    return (
        <div className={styles.sideBarContainer}>
            <div className={styles.sideBar}>
                <div className={styles.mainSideBar}>
                    <div className={styles.topContainer}>
                        <div className={styles.titleContainer}>
                            <Link href={`/`}><h1><Text id="navigation.Title" /></h1></Link>
                            <h2><Text id="navigation.Subtitle" /></h2>
                        </div>
                        <div className={`dropdown ${styles.dropdown}`}>
                            <div className="dropdown-main">
                                <p>{language}</p>
                                <Icon.ChevronDown className="arrowIcon" />
                            </div>
                            <div className={"dropdown-content dropdownLanguagesContent"}>
                                {languages && languages.filter(l => l!== language).map((lang, i) =>(
                                    <button key={i} onClick={() => setLanguage(lang)} >{lang}</button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.sideMenu}>
                        {
                            mainTabs && mainTabs.map(tab => (
                                <div key={tab.link} >
                                    <div className={styles.sideMenuLink}>
                                        {currentTab === tab.link && (
                                            <div className={styles.marker} />
                                        )}
                                        <Link href={`/home/${tab.link === "legal-gender-recognition" ? `${tab.link}/${tab.subtabs[0].link}` : tab.link}`} style={sideMenuLinkStyle(tab.link)}><Text id={tab.tabName} /></Link>
                                    </div>
                                {
                                        currentTab === "legal-gender-recognition" && tab.link === "legal-gender-recognition" && <div className={styles.sideMenuSubLinks}>
                                            {
                                                tab.subtabs.map((subtab, j) => {
                                                    return <Link key={j}  href={`/home/${tab.link}/${subtab.link}`} style={sideMenuSubLinkStyle(subtab.link)}><Text id={subtab.name} /></Link>
                                                })
                                            }                                    
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className={styles.smallScreenMenuContainer}>
                        <p className={styles.tgeuProject}>A project of <e>TGEU</e></p>
                        <div className={styles.dropdownMenu}>
                            <div className={styles.currentTab} onClick={() => setExpandSmallScreenMenu(!expandSmallScreenMenu)}>
                                <p><Text id={mainTabs[1].subtabs.find(t => t.link === currentSubtab)?.name ?? mainTabs.find(t => t.link === currentTab)?.name ?? bottomTabs.find(t => currentTab === t.name)?.name ?? mainTabs[0].name} /></p>
                                <Icon.Menu className={styles.dropdownArrow} />
                            </div>
                            <div className={styles.expandedMenu} style={{display: expandSmallScreenMenu ? "flex" : "none"}}>
                                {
                                    mainTabs && mainTabs.map(tab => (
                                        <div key={tab.link} >
                                            <div className={styles.sideMenuLink}>
                                                {currentTab === tab.link && (
                                                    <div className={styles.marker} />
                                                )}
                                                <Link onClick={() => setExpandSmallScreenMenu(false)} href={`/home/${tab.link === "legal-gender-recognition" ? `${tab.link}/${tab.subtabs[0].link}` : tab.link}`} style={sideMenuLinkStyle(tab.link)}><Text id={tab.tabName} /></Link>
                                            </div>
                                        {
                                                currentTab === "legal-gender-recognition" && tab.link === "legal-gender-recognition" && <div className={styles.sideMenuSubLinks}>
                                                    {
                                                        tab.subtabs.map((subtab, j) => {
                                                            return <Link key={j} onClick={() => setExpandSmallScreenMenu(false)} href={`/home/${tab.link}/${subtab.link}`} style={sideMenuSubLinkStyle(subtab.link)}><Text id={subtab.name} /></Link>
                                                        })
                                                    }                                    
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                                <div style={{marginTop: '10px'}} />
                                {
                                    bottomTabs && bottomTabs.map((tab, i) => {
                                        return <Link href={`${tab.link}`} onClick={() => setExpandSmallScreenMenu(false)} key={i} className={styles.sideMenuBottomLink} style={sideMenuLinkStyle(tab.link)}><Text id={tab.name} /></Link>
                                    })
                                }
                                
                                <Link href="/about"><Text id="navigation.About the project" /></Link>
                                <Link href="/acknowledgements"><Text id="navigation.Acknowledgements" /></Link>
                                <a href="https://tgeu.org/privacy-policy" target={"_blank"}><Text id="navigation.Privacy policy" /></a>
                                <a href="https://tgeu.org/disclaimer" target={"_blank"}><Text id="navigation.Disclaimer" /></a>
                                {expandSmallScreenMenu ? <div style={{position: "relative"}}><Icon.ChevronUp className={styles.unexpandArrow} onClick={() => setExpandSmallScreenMenu(false)} /></div> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.sideMenuBottom}>
                    {
                        bottomTabs && bottomTabs.map((tab, i) => (
                            <Link href={`${tab.link}`} onClick={() => setExpandSmallScreenMenu(false)} key={i} className={styles.sideMenuBottomLink} style={sideMenuLinkStyle(tab.link)}><Text id={tab.name} /></Link>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default SideBar;