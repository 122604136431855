import Bar from "./Bar";

const TextAndBar = ({position, text, fontSize, points, total, width}) => {
    
    const [x, y] = position;

    const textSize = fontSize ?? 17;
    
    return (
        <g>
            <text x={x} y={y + textSize} fill="#383F45" font-family="Roboto, sans-serif" font-style= "normal" font-weight="normal" font-size={textSize} line-height={textSize*1.5}>
                {`${points}/${total} `} {text}
            </text>
            <Bar position={[x, y + textSize + 4]} percentage={points/total * 100} width={width} />
        </g>
    )
}

export default TextAndBar;