import ue_flag from './ue_flag.svg';
import csv_icon from "./csv-file-format-extension.svg";
import ilga from './partner_ilga.svg';
import tgeu from './partner_tgeu.svg';
import ue_vertical from './ue_flag_vertical.png';
import ue_horizontal from './ue_flag_horizontal.png';
import wolfox from './partner_wolfox.png';
import previewFastFactsEN from './preview-fast-facts-en.PNG';
import previewFastFactsRU from './preview-fast-facts-ru.PNG';

export const IntroPictures = { ue_flag }

export const Icons = { csv_icon };

export const Partners = { ilga, tgeu, ue_vertical, wolfox }

export const Previews = {previewFastFactsEN, previewFastFactsRU}