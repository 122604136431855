//import * as Icon from 'react-feather';
import TextAndBar from './TextAndBar';
import styles from './styles'
import {Text, MarkupText} from 'preact-i18n';
import mapData from '../../assets/map.json'
import {CountrySVG} from '../Map/MapSVG';
import {useEffect, useState} from 'preact/hooks';
import IconAndText from './IconAndText';
import Legend from '../Legend/Legend';
import {unmountComponentAtNode} from "preact/compat";
import {haveIndicator} from "../../utils";

const ue_flag = <svg x="60" y={1010} width="203" height="43" viewBox="0 0 203 43" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1904_42973)">
        <path d="M63.6456 0H0V43H63.6456V0Z" fill="white"/>
        <path d="M62.4846 1.08594H1.15463V41.7573H62.4846V1.08594Z" fill="#003399"/>
        <path
            d="M30.5183 9.95047L31.8069 9.01141L33.1019 9.95047L32.6107 8.42767L33.9248 7.48861H32.3045L31.8069 5.95312L31.3157 7.49496L29.6953 7.48861L31.0095 8.42767L30.5183 9.95047Z"
            fill="#FFCC00"/>
        <path
            d="M23.8201 11.7395L25.115 10.8005L26.4036 11.7395L25.9124 10.2231L27.2266 9.27768H25.6062L25.1087 7.74219L24.6175 9.28402L22.9971 9.27768L24.3113 10.2231L23.8201 11.7395Z"
            fill="#FFCC00"/>
        <path
            d="M20.2094 12.625L19.7182 14.1668L18.0979 14.1605L19.412 15.0995L18.9208 16.6223L20.2094 15.6833L21.5044 16.6223L21.0132 15.0995L22.3273 14.1605H20.707L20.2094 12.625Z"
            fill="#FFCC00"/>
        <path
            d="M18.4169 22.3356L19.7055 23.2747L19.2143 21.7582L20.5284 20.8192H18.9081L18.4169 19.2773L17.9193 20.8192H16.299L17.6131 21.7582L17.1219 23.2747L18.4169 22.3356Z"
            fill="#FFCC00"/>
        <path
            d="M20.707 27.4911L20.2094 25.9492L19.7182 27.4911H18.0979L19.412 28.4301L18.9208 29.9466L20.2094 29.0075L21.5044 29.9466L21.0132 28.4301L22.3273 27.4911H20.707Z"
            fill="#FFCC00"/>
        <path
            d="M25.619 32.3714L25.1214 30.8359L24.6238 32.3714H23.0035L24.3176 33.3105L23.8264 34.8333L25.1214 33.8942L26.4164 34.8333L25.9252 33.3105L27.2393 32.3714H25.619Z"
            fill="#FFCC00"/>
        <path
            d="M32.3045 34.141L31.8133 32.6055L31.3157 34.141H29.6953L31.0095 35.08L30.5183 36.6028L31.8133 35.6638L33.1019 36.6028L32.6107 35.08L33.9248 34.141H32.3045Z"
            fill="#FFCC00"/>
        <path
            d="M38.9963 32.3714L38.4987 30.8359L38.0011 32.3714H36.3871L37.6949 33.3105L37.2037 34.8333L38.4987 33.8942L39.7937 34.8333L39.3025 33.3105L40.6166 32.3714H38.9963Z"
            fill="#FFCC00"/>
        <path
            d="M43.9083 27.4911L43.4107 25.9492L42.9131 27.4911H41.2928L42.6069 28.4301L42.1157 29.9466L43.4107 29.0075L44.7057 29.9466L44.2081 28.4301L45.5222 27.4911H43.9083Z"
            fill="#FFCC00"/>
        <path
            d="M47.302 20.7996H45.6817L45.1841 19.2578L44.6929 20.7996H43.0726L44.3867 21.7387L43.8955 23.2552L45.1841 22.3161L46.4791 23.2552L45.9879 21.7387L47.302 20.7996Z"
            fill="#FFCC00"/>
        <path
            d="M42.1157 16.6067L43.4107 15.6677L44.7057 16.6067L44.2081 15.0839L45.5222 14.1449H43.9083L43.4107 12.6094L42.9131 14.1512L41.2928 14.1449L42.6069 15.0839L42.1157 16.6067Z"
            fill="#FFCC00"/>
        <path
            d="M38.5178 7.74219L38.0202 9.28402L36.4063 9.27768L37.714 10.2231L37.2228 11.7395L38.5178 10.8005L39.8128 11.7395L39.3216 10.2231L40.6357 9.27768H39.0154L38.5178 7.74219Z"
            fill="#FFCC00"/>
        <path
            d="M77.5332 14.4045L79.5108 15.0263C79.211 16.124 78.707 16.9361 77.9989 17.4691C77.2972 18.0021 76.4041 18.2686 75.3196 18.2686C73.98 18.2686 72.8764 17.8118 72.0152 16.9044C71.154 15.9907 70.7202 14.7471 70.7202 13.1672C70.7202 11.4985 71.154 10.1978 72.0216 9.27774C72.8892 8.35137 74.031 7.89453 75.4409 7.89453C76.672 7.89453 77.68 8.2562 78.4455 8.98587C78.9048 9.41733 79.2493 10.0328 79.4789 10.8323L77.4631 11.3145C77.3419 10.7942 77.0931 10.3818 76.7167 10.0836C76.3403 9.78534 75.881 9.63306 75.3388 9.63306C74.5924 9.63306 73.9864 9.89955 73.5207 10.4325C73.055 10.9655 72.8254 11.8284 72.8254 13.0276C72.8254 14.2966 73.055 15.1976 73.5143 15.7369C73.9736 16.2763 74.5669 16.5428 75.3069 16.5428C75.8491 16.5428 76.3148 16.3714 76.7039 16.0288C77.0931 15.6862 77.3738 15.1469 77.546 14.4108L77.5332 14.4045Z"
            fill="#003399"/>
        <path
            d="M80.7994 14.3568C80.7994 13.716 80.9589 13.1005 81.2715 12.5041C81.5904 11.9077 82.037 11.4508 82.6175 11.1336C83.198 10.8163 83.8487 10.6641 84.5631 10.6641C85.6668 10.6641 86.5726 11.0194 87.2807 11.7364C87.9888 12.4533 88.3396 13.3543 88.3396 14.4457C88.3396 15.537 87.9824 16.457 87.2679 17.1804C86.5535 17.9037 85.654 18.2654 84.5759 18.2654C83.9061 18.2654 83.2682 18.1131 82.6621 17.8149C82.0561 17.5167 81.5904 17.0725 81.2778 16.4888C80.9589 15.905 80.8058 15.2007 80.8058 14.3632L80.7994 14.3568ZM82.7833 14.4584C82.7833 15.1817 82.9556 15.7337 83.3001 16.1144C83.6445 16.4951 84.0656 16.6918 84.5759 16.6918C85.0862 16.6918 85.5009 16.5015 85.8454 16.1144C86.1899 15.7337 86.3557 15.1754 86.3557 14.4457C86.3557 13.716 86.1835 13.1894 85.8454 12.8023C85.5009 12.4216 85.0799 12.2249 84.5759 12.2249C84.0719 12.2249 83.6445 12.4153 83.3001 12.8023C82.9556 13.183 82.7833 13.735 82.7833 14.4584Z"
            fill="#003399"/>
        <path d="M89.6346 15.4186V13.4961H93.4367V15.4186H89.6346Z" fill="#003399"/>
        <path
            d="M93.6982 10.8244H94.7699V10.2788C94.7699 9.66966 94.8337 9.21282 94.9677 8.90826C95.0953 8.61005 95.3377 8.36259 95.6885 8.17224C96.0394 7.98189 96.4859 7.88672 97.0218 7.88672C97.5576 7.88672 98.1126 7.9692 98.6421 8.13417L98.3806 9.47297C98.0744 9.39683 97.7745 9.3651 97.4939 9.3651C97.2132 9.3651 97.0154 9.42855 96.8942 9.5618C96.773 9.6887 96.7092 9.9425 96.7092 10.3105V10.8244H98.1573V12.3346H96.7092V18.0895H94.7763V12.3346H93.7046V10.8244H93.6982Z"
            fill="#003399"/>
        <path
            d="M104.052 18.0941V17.0028C103.784 17.3898 103.433 17.6944 102.999 17.9228C102.565 18.1449 102.106 18.2591 101.628 18.2591C101.149 18.2591 100.696 18.1512 100.307 17.9355C99.918 17.7198 99.6373 17.4215 99.4587 17.0345C99.2864 16.6475 99.1971 16.1081 99.1971 15.4229V10.8228H101.13V14.1602C101.13 15.1818 101.168 15.8099 101.238 16.0383C101.309 16.2668 101.436 16.4508 101.628 16.584C101.813 16.7172 102.055 16.787 102.342 16.787C102.674 16.787 102.967 16.6982 103.229 16.5142C103.49 16.3366 103.669 16.1081 103.765 15.8416C103.86 15.5752 103.911 14.9216 103.911 13.881V10.8164H105.844V18.0878H104.045L104.052 18.0941Z"
            fill="#003399"/>
        <path
            d="M114.501 18.095H112.568V14.3832C112.568 13.5964 112.53 13.0888 112.447 12.8604C112.364 12.632 112.23 12.448 112.045 12.3211C111.86 12.1942 111.637 12.1307 111.375 12.1307C111.037 12.1307 110.737 12.2196 110.476 12.4036C110.214 12.5876 110.029 12.8287 109.927 13.1269C109.825 13.4251 109.78 13.9835 109.78 14.7956V18.0887H107.847V10.8173H109.646V11.8833C110.284 11.0648 111.088 10.6523 112.058 10.6523C112.485 10.6523 112.874 10.7285 113.225 10.8808C113.576 11.033 113.844 11.2297 114.029 11.4645C114.207 11.6993 114.335 11.9721 114.405 12.2703C114.475 12.5685 114.514 13 114.514 13.5647V18.0823L114.501 18.095Z"
            fill="#003399"/>
        <path
            d="M123.164 18.0964H121.365V17.0304C121.065 17.4428 120.714 17.7537 120.306 17.9568C119.898 18.1598 119.489 18.2613 119.075 18.2613C118.233 18.2613 117.518 17.9251 116.919 17.2525C116.319 16.5799 116.019 15.6409 116.019 14.4353C116.019 13.2298 116.313 12.2653 116.893 11.6245C117.474 10.9836 118.214 10.66 119.1 10.66C119.917 10.66 120.625 10.9963 121.218 11.6752V8.05859H123.151V18.0964H123.164ZM118.003 14.3021C118.003 15.0762 118.112 15.6409 118.328 15.9835C118.641 16.4847 119.075 16.7385 119.636 16.7385C120.083 16.7385 120.459 16.5482 120.772 16.1738C121.084 15.7995 121.237 15.2348 121.237 14.4861C121.237 13.6485 121.084 13.0521 120.784 12.6841C120.485 12.3161 120.095 12.1321 119.623 12.1321C119.151 12.1321 118.781 12.3161 118.469 12.6777C118.156 13.0394 118.003 13.5851 118.003 14.3021Z"
            fill="#003399"/>
        <path
            d="M129.301 15.7781L131.227 16.1017C130.978 16.806 130.589 17.339 130.053 17.707C129.518 18.075 128.848 18.259 128.044 18.259C126.775 18.259 125.83 17.8466 125.224 17.0217C124.746 16.3619 124.504 15.5243 124.504 14.5155C124.504 13.3099 124.822 12.3645 125.454 11.6856C126.086 11.0003 126.889 10.6641 127.853 10.6641C128.937 10.6641 129.798 11.0194 130.43 11.7364C131.055 12.4534 131.361 13.5447 131.329 15.0167H126.487C126.5 15.5878 126.66 16.0319 126.953 16.3492C127.253 16.6664 127.623 16.825 128.07 16.825C128.376 16.825 128.625 16.7426 128.835 16.5776C129.039 16.4126 129.199 16.1461 129.301 15.7845V15.7781ZM129.409 13.8366C129.396 13.2782 129.25 12.8594 128.975 12.5676C128.701 12.2757 128.363 12.1298 127.967 12.1298C127.546 12.1298 127.196 12.282 126.921 12.5866C126.647 12.8912 126.513 13.3099 126.513 13.8302H129.403L129.409 13.8366Z"
            fill="#003399"/>
        <path
            d="M139.61 18.0964H137.811V17.0304C137.511 17.4428 137.16 17.7537 136.752 17.9568C136.343 18.1598 135.935 18.2613 135.52 18.2613C134.678 18.2613 133.964 17.9251 133.364 17.2525C132.765 16.5799 132.465 15.6409 132.465 14.4353C132.465 13.2298 132.758 12.2653 133.339 11.6245C133.919 10.9836 134.659 10.66 135.546 10.66C136.363 10.66 137.071 10.9963 137.664 11.6752V8.05859H139.597V18.0964H139.61ZM134.449 14.3021C134.449 15.0762 134.557 15.6409 134.774 15.9835C135.087 16.4847 135.52 16.7385 136.082 16.7385C136.528 16.7385 136.905 16.5482 137.217 16.1738C137.53 15.7995 137.683 15.2348 137.683 14.4861C137.683 13.6485 137.53 13.0521 137.23 12.6841C136.93 12.3161 136.541 12.1321 136.069 12.1321C135.597 12.1321 135.227 12.3161 134.914 12.6777C134.602 13.0394 134.449 13.5851 134.449 14.3021Z"
            fill="#003399"/>
        <path
            d="M145.351 18.0964V8.05859H147.284V11.6752C147.877 11.0027 148.585 10.66 149.402 10.66C150.295 10.66 151.028 10.9836 151.609 11.6245C152.189 12.2653 152.483 13.1853 152.483 14.3846C152.483 15.5838 152.183 16.5799 151.59 17.2525C150.997 17.9251 150.276 18.2613 149.427 18.2613C149.013 18.2613 148.598 18.1598 148.19 17.9504C147.781 17.7411 147.437 17.4365 147.143 17.0304V18.0964H145.344H145.351ZM147.271 14.3021C147.271 15.0571 147.392 15.6091 147.628 15.9708C147.96 16.4847 148.407 16.7385 148.962 16.7385C149.389 16.7385 149.753 16.5545 150.052 16.1929C150.352 15.8312 150.505 15.2602 150.505 14.4797C150.505 13.6485 150.352 13.0521 150.052 12.6841C149.753 12.3161 149.363 12.1321 148.891 12.1321C148.419 12.1321 148.043 12.3097 147.737 12.6714C147.431 13.0267 147.277 13.5724 147.277 14.3021H147.271Z"
            fill="#003399"/>
        <path
            d="M153.127 10.8242H155.188L156.936 15.9827L158.639 10.8242H160.642L158.058 17.8227L157.599 19.0917C157.427 19.5169 157.267 19.8405 157.114 20.0625C156.961 20.2846 156.782 20.4686 156.585 20.6082C156.387 20.7478 156.138 20.8556 155.851 20.9318C155.558 21.0079 155.232 21.046 154.862 21.046C154.492 21.046 154.122 21.0079 153.765 20.9318L153.593 19.428C153.893 19.4851 154.167 19.5169 154.409 19.5169C154.862 19.5169 155.194 19.3836 155.405 19.1235C155.615 18.8633 155.787 18.527 155.902 18.121L153.121 10.8306L153.127 10.8242Z"
            fill="#003399"/>
        <path
            d="M74.4202 27.6464V29.1819H73.0997V32.1133C73.0997 32.7033 73.1124 33.0523 73.1379 33.1475C73.1635 33.249 73.2209 33.3251 73.3102 33.3886C73.3995 33.452 73.5079 33.4838 73.6355 33.4838C73.8141 33.4838 74.0757 33.4203 74.4138 33.2998L74.5796 34.7908C74.1267 34.9812 73.6228 35.0764 73.0486 35.0764C72.6978 35.0764 72.3852 35.0193 72.1045 34.8987C71.8238 34.7845 71.6197 34.6322 71.4857 34.4482C71.3517 34.2642 71.2624 34.0168 71.2114 33.6995C71.1731 33.4774 71.1476 33.0269 71.1476 32.3417V29.1755H70.2609V27.64H71.1476V26.1934L73.0869 25.0703V27.64H74.4074L74.4202 27.6464Z"
            fill="#003399"/>
        <path
            d="M77.6863 24.8789V28.5653C78.3115 27.842 79.0579 27.4804 79.9254 27.4804C80.372 27.4804 80.7739 27.5628 81.1311 27.7278C81.4883 27.8928 81.7563 28.1022 81.9413 28.356C82.1263 28.6098 82.2475 28.8953 82.3113 29.2062C82.3751 29.5171 82.4133 29.9993 82.4133 30.6529V34.9167H80.4804V31.078C80.4804 30.3166 80.4422 29.8343 80.372 29.625C80.2954 29.4219 80.1678 29.2569 79.9828 29.1364C79.7978 29.0158 79.5618 28.9524 79.2875 28.9524C78.9686 28.9524 78.6815 29.0285 78.4263 29.1872C78.1711 29.3394 77.9861 29.5742 77.8713 29.8915C77.7565 30.2024 77.6927 30.6655 77.6927 31.2747V34.9167H75.7598V24.8789H77.6927H77.6863Z"
            fill="#003399"/>
        <path
            d="M88.6012 32.6023L90.5277 32.9259C90.2789 33.6302 89.8898 34.1632 89.354 34.5312C88.8181 34.8992 88.1483 35.0832 87.3509 35.0832C86.0814 35.0832 85.1373 34.6708 84.5312 33.846C84.0464 33.1861 83.8104 32.3485 83.8104 31.3397C83.8104 30.1341 84.1293 29.1951 84.7609 28.5098C85.3924 27.8309 86.1962 27.4883 87.1659 27.4883C88.2503 27.4883 89.1115 27.8436 89.7367 28.5606C90.3682 29.2712 90.6681 30.3689 90.6362 31.8409H85.7943C85.8071 32.412 85.9666 32.8561 86.26 33.1734C86.5598 33.4906 86.9298 33.6493 87.3764 33.6493C87.6762 33.6493 87.9314 33.5668 88.1419 33.4018C88.346 33.2368 88.5055 32.9704 88.6076 32.6087L88.6012 32.6023ZM88.7096 30.6608C88.6969 30.1024 88.5502 29.6836 88.2759 29.3918C88.0016 29.0999 87.6634 28.954 87.2743 28.954C86.8533 28.954 86.5024 29.1063 86.2281 29.4108C85.9538 29.7154 85.8198 30.1341 85.8198 30.6544H88.7096V30.6608Z"
            fill="#003399"/>
        <path
            d="M96.1351 34.9167V24.8789H103.618V26.5794H98.1764V28.8065H103.242V30.5006H98.1764V33.2289H103.816V34.923H96.1415L96.1351 34.9167Z"
            fill="#003399"/>
        <path
            d="M110.335 34.9159V33.8309C110.067 34.2179 109.723 34.5225 109.289 34.7509C108.855 34.9793 108.396 35.0872 107.918 35.0872C107.439 35.0872 106.986 34.9793 106.597 34.7636C106.208 34.5479 105.927 34.2497 105.749 33.8626C105.576 33.4756 105.487 32.9363 105.487 32.251V27.6509H107.42V30.9883C107.42 32.0099 107.458 32.638 107.528 32.8665C107.599 33.0949 107.726 33.2789 107.918 33.4121C108.109 33.5454 108.345 33.6152 108.632 33.6152C108.964 33.6152 109.257 33.5263 109.519 33.3423C109.78 33.1583 109.959 32.9363 110.055 32.6698C110.15 32.4033 110.201 31.7497 110.201 30.7092V27.6445H112.134V34.9159H110.335Z"
            fill="#003399"/>
        <path
            d="M115.987 34.9168H114.054V27.6454H115.853V28.6797C116.159 28.1911 116.44 27.8675 116.683 27.7152C116.925 27.5566 117.206 27.4805 117.518 27.4805C117.958 27.4805 118.386 27.601 118.794 27.8421L118.194 29.5172C117.869 29.3078 117.563 29.2 117.289 29.2C117.014 29.2 116.791 29.2761 116.6 29.422C116.408 29.568 116.262 29.8408 116.153 30.2278C116.045 30.6149 115.994 31.4271 115.994 32.6643V34.9105L115.987 34.9168Z"
            fill="#003399"/>
        <path
            d="M119.17 31.1811C119.17 30.5402 119.33 29.9248 119.643 29.3283C119.962 28.7319 120.408 28.2751 120.989 27.9578C121.569 27.6406 122.22 27.4883 122.934 27.4883C124.038 27.4883 124.944 27.8436 125.652 28.5606C126.36 29.2712 126.711 30.1786 126.711 31.2699C126.711 32.3612 126.353 33.2813 125.639 34.0046C124.925 34.7279 124.025 35.0896 122.947 35.0896C122.277 35.0896 121.639 34.9373 121.033 34.6391C120.427 34.3409 119.962 33.8967 119.649 33.313C119.336 32.7292 119.177 32.025 119.177 31.1874L119.17 31.1811ZM121.154 31.2826C121.154 32.0059 121.327 32.5579 121.671 32.9386C122.016 33.3193 122.437 33.516 122.947 33.516C123.457 33.516 123.872 33.3257 124.216 32.9386C124.561 32.5579 124.727 31.9996 124.727 31.2699C124.727 30.5402 124.555 30.0072 124.216 29.6265C123.872 29.2458 123.451 29.0491 122.947 29.0491C122.443 29.0491 122.016 29.2395 121.671 29.6265C121.327 30.0072 121.154 30.5593 121.154 31.2826Z"
            fill="#003399"/>
        <path
            d="M128.172 27.6454H129.977V28.7114C130.213 28.3434 130.526 28.0515 130.927 27.8231C131.329 27.5947 131.769 27.4805 132.254 27.4805C133.103 27.4805 133.824 27.8104 134.417 28.4703C135.01 29.1302 135.304 30.0565 135.304 31.2367C135.304 32.4169 135.004 33.394 134.41 34.0666C133.817 34.7391 133.09 35.0754 132.242 35.0754C131.84 35.0754 131.47 34.9929 131.144 34.8343C130.819 34.6757 130.468 34.4029 130.111 34.0158V37.6769H128.178V27.6391L128.172 27.6454ZM130.085 31.1542C130.085 31.9727 130.245 32.5755 130.577 32.9625C130.908 33.3496 131.297 33.5463 131.769 33.5463C132.242 33.5463 132.592 33.3686 132.892 33.007C133.192 32.6516 133.339 32.0616 133.339 31.243C133.339 30.4816 133.186 29.9169 132.879 29.5426C132.573 29.1746 132.19 28.9906 131.738 28.9906C131.285 28.9906 130.87 29.1746 130.564 29.5362C130.251 29.8979 130.098 30.4372 130.098 31.1479L130.085 31.1542Z"
            fill="#003399"/>
        <path
            d="M141.07 32.6023L142.997 32.9259C142.748 33.6302 142.359 34.1632 141.823 34.5312C141.287 34.8992 140.617 35.0832 139.814 35.0832C138.544 35.0832 137.6 34.6708 136.994 33.846C136.516 33.1861 136.273 32.3485 136.273 31.3397C136.273 30.1341 136.592 29.1951 137.224 28.5098C137.855 27.8309 138.659 27.4883 139.622 27.4883C140.707 27.4883 141.568 27.8436 142.2 28.5606C142.825 29.2712 143.131 30.3689 143.099 31.8409H138.257C138.27 32.412 138.429 32.8561 138.723 33.1734C139.023 33.4906 139.393 33.6493 139.839 33.6493C140.145 33.6493 140.394 33.5668 140.605 33.4018C140.809 33.2368 140.968 32.9704 141.07 32.6087V32.6023ZM141.179 30.6608C141.166 30.1024 141.019 29.6836 140.745 29.3918C140.471 29.0999 140.133 28.954 139.737 28.954C139.316 28.954 138.965 29.1063 138.691 29.4108C138.417 29.7154 138.283 30.1341 138.283 30.6544H141.172L141.179 30.6608Z"
            fill="#003399"/>
        <path
            d="M146.123 29.8662L144.368 29.5489C144.566 28.8446 144.904 28.3244 145.389 27.9881C145.868 27.6518 146.588 27.4805 147.539 27.4805C148.4 27.4805 149.044 27.582 149.465 27.785C149.886 27.9881 150.186 28.2482 150.359 28.5591C150.531 28.87 150.614 29.4474 150.614 30.2786L150.595 32.5247C150.595 33.1656 150.626 33.6351 150.69 33.9397C150.754 34.2442 150.869 34.5678 151.035 34.9168H149.121C149.07 34.7899 149.006 34.5995 148.936 34.3457C148.904 34.2315 148.879 34.1554 148.866 34.1173C148.534 34.4346 148.183 34.6757 147.807 34.8343C147.43 34.9929 147.029 35.0754 146.601 35.0754C145.848 35.0754 145.255 34.8724 144.821 34.4663C144.388 34.0602 144.171 33.5463 144.171 32.9245C144.171 32.512 144.273 32.1504 144.464 31.8268C144.662 31.5032 144.936 31.2557 145.293 31.0844C145.651 30.9131 146.161 30.7672 146.831 30.6339C147.737 30.4626 148.362 30.3103 148.706 30.1581V29.9677C148.706 29.5997 148.617 29.3332 148.432 29.1746C148.247 29.016 147.903 28.9398 147.392 28.9398C147.048 28.9398 146.78 29.0096 146.588 29.1429C146.397 29.2761 146.238 29.5109 146.123 29.8535V29.8662ZM148.713 31.427C148.464 31.5095 148.075 31.6047 147.539 31.7189C147.003 31.8331 146.652 31.9473 146.486 32.0552C146.231 32.2329 146.11 32.4613 146.11 32.7341C146.11 33.007 146.212 33.2354 146.41 33.4321C146.608 33.6288 146.869 33.7239 147.182 33.7239C147.533 33.7239 147.864 33.6097 148.177 33.3813C148.413 33.21 148.566 32.9943 148.636 32.7468C148.687 32.5818 148.713 32.2709 148.713 31.8078V31.427Z"
            fill="#003399"/>
        <path
            d="M159.162 34.9153H157.229V31.2035C157.229 30.4167 157.191 29.9091 157.108 29.6807C157.025 29.4523 156.891 29.2683 156.706 29.1414C156.521 29.0145 156.298 28.951 156.036 28.951C155.698 28.951 155.398 29.0399 155.137 29.2239C154.875 29.4079 154.69 29.649 154.588 29.9472C154.486 30.2454 154.441 30.8038 154.441 31.6159V34.909H152.508V27.6376H154.307V28.7036C154.945 27.8851 155.749 27.4727 156.719 27.4727C157.146 27.4727 157.535 27.5488 157.886 27.7011C158.237 27.8534 158.505 28.0501 158.69 28.2848C158.868 28.5196 158.996 28.7924 159.066 29.0906C159.136 29.3888 159.175 29.8203 159.175 30.385V34.9026L159.162 34.9153Z"
            fill="#003399"/>
        <path
            d="M165.037 24.8789H167.072V30.3166C167.072 31.1795 167.098 31.7378 167.149 31.9916C167.238 32.4041 167.442 32.734 167.774 32.9815C168.099 33.2289 168.552 33.3558 169.12 33.3558C169.688 33.3558 170.134 33.2416 170.428 33.0005C170.721 32.7657 170.9 32.4739 170.957 32.1376C171.015 31.7949 171.046 31.2302 171.046 30.4308V24.8789H173.081V30.1516C173.081 31.3571 173.024 32.2074 172.916 32.7023C172.807 33.1972 172.603 33.6223 172.303 33.9586C172.01 34.3012 171.614 34.5741 171.117 34.7771C170.619 34.9801 169.975 35.0817 169.177 35.0817C168.214 35.0817 167.48 34.9738 166.983 34.7517C166.485 34.5296 166.09 34.2441 165.803 33.8888C165.516 33.5335 165.324 33.1655 165.229 32.7784C165.095 32.201 165.031 31.3571 165.031 30.2341V24.8789H165.037Z"
            fill="#003399"/>
        <path
            d="M181.866 34.9153H179.933V31.2035C179.933 30.4167 179.894 29.9091 179.811 29.6807C179.729 29.4523 179.595 29.2683 179.41 29.1414C179.225 29.0145 179.001 28.951 178.74 28.951C178.402 28.951 178.102 29.0399 177.84 29.2239C177.579 29.4079 177.394 29.649 177.292 29.9472C177.19 30.2454 177.145 30.8038 177.145 31.6159V34.909H175.212V27.6376H177.011V28.7036C177.649 27.8851 178.453 27.4727 179.422 27.4727C179.85 27.4727 180.239 27.5488 180.59 27.7011C180.941 27.8534 181.209 28.0501 181.394 28.2848C181.572 28.5196 181.7 28.7924 181.77 29.0906C181.84 29.3888 181.878 29.8203 181.878 30.385V34.9026L181.866 34.9153Z"
            fill="#003399"/>
        <path d="M183.83 26.6633V24.8867H185.763V26.6633H183.83ZM183.83 34.9181V27.6468H185.763V34.9181H183.83Z"
              fill="#003399"/>
        <path
            d="M187.294 31.1811C187.294 30.5402 187.454 29.9248 187.766 29.3283C188.085 28.7319 188.532 28.2751 189.112 27.9578C189.693 27.6406 190.344 27.4883 191.058 27.4883C192.162 27.4883 193.068 27.8436 193.776 28.5606C194.484 29.2712 194.835 30.1786 194.835 31.2699C194.835 32.3612 194.477 33.2813 193.763 34.0046C193.048 34.7279 192.149 35.0896 191.071 35.0896C190.401 35.0896 189.763 34.9373 189.157 34.6391C188.551 34.3409 188.085 33.8967 187.773 33.313C187.46 32.7292 187.301 32.025 187.301 31.1874L187.294 31.1811ZM189.278 31.2826C189.278 32.0059 189.451 32.5579 189.795 32.9386C190.139 33.3193 190.56 33.516 191.071 33.516C191.581 33.516 191.996 33.3257 192.34 32.9386C192.685 32.5579 192.851 31.9996 192.851 31.2699C192.851 30.5402 192.678 30.0072 192.34 29.6265C191.996 29.2458 191.575 29.0491 191.071 29.0491C190.567 29.0491 190.139 29.2395 189.795 29.6265C189.451 30.0072 189.278 30.5593 189.278 31.2826Z"
            fill="#003399"/>
        <path
            d="M203 34.9153H201.067V31.2035C201.067 30.4167 201.029 29.9091 200.946 29.6807C200.863 29.4523 200.729 29.2683 200.544 29.1414C200.359 29.0145 200.136 28.951 199.874 28.951C199.536 28.951 199.236 29.0399 198.975 29.2239C198.713 29.4079 198.528 29.649 198.426 29.9472C198.324 30.2454 198.279 30.8038 198.279 31.6159V34.909H196.346V27.6376H198.145V28.7036C198.783 27.8851 199.587 27.4727 200.557 27.4727C200.984 27.4727 201.373 27.5488 201.724 27.7011C202.075 27.8534 202.343 28.0501 202.528 28.2848C202.707 28.5196 202.834 28.7924 202.904 29.0906C202.975 29.3888 203.013 29.8203 203.013 30.385V34.9026L203 34.9153Z"
            fill="#003399"/>
    </g>
    <defs>
        <clipPath id="clip0_1904_42973">
            <rect width="203" height="43" fill="white"/>
        </clipPath>
    </defs>
</svg>
const tgeu_logo_image = <svg x="800" y="40" width="151" height="53" viewBox="0 0 151 53" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_201_71)">
        <path
            d="M20.1906 8.59812V16.2138H13.2565V28.988C13.2565 30.0525 13.5225 30.8308 14.0544 31.3212C14.5862 31.8133 15.3641 32.0896 16.3857 32.1496C17.4075 32.2117 18.6771 32.2016 20.1906 32.1196V39.305C14.8301 39.9193 11.0171 39.4171 8.74564 37.8004C6.47419 36.1835 5.34057 33.2462 5.34057 28.988V16.2138H0V8.59812H5.33856V2.3952L13.2545 0V8.59812H20.1906Z"
            fill="#D65583"/>
        <path
            d="M45.9338 29.5104C47.5495 27.9356 48.3572 25.9187 48.3572 23.4614C48.3572 21.0042 47.5374 19.0193 45.902 17.5046C44.3064 15.9079 42.2609 15.1094 39.7655 15.1094C37.2702 15.1094 35.2448 15.9079 33.5673 17.5046C31.9717 19.0193 31.1739 21.0044 31.1739 23.4614C31.1739 25.9185 31.9816 27.9356 33.5973 29.5104C35.2128 31.0872 37.2684 31.8756 39.7637 31.8756C42.2591 31.8756 44.3145 31.0872 45.9302 29.5104H45.9338ZM48.3572 12.4061V8.59813H56.0892V37.8304C56.0892 42.5388 54.5136 46.1605 51.3645 48.6996C48.2552 51.1968 44.4923 52.4454 40.0735 52.4454C33.1172 52.4454 28.2706 49.9483 25.5294 44.9537L32.2797 41.0839C33.7934 43.9493 36.4725 45.382 40.3196 45.382C42.8149 45.382 44.7784 44.7256 46.2099 43.4171C47.6416 42.1065 48.3574 40.2435 48.3574 37.8284V34.5128C46.0261 37.6242 42.7528 39.1811 38.538 39.1811C34.3232 39.1811 30.5602 37.6664 27.6148 34.637C24.7096 31.5255 23.2579 27.7996 23.2579 23.4594C23.2579 19.1193 24.7315 15.4135 27.6767 12.344C30.6219 9.27447 34.243 7.73779 38.5378 7.73779C42.8327 7.73779 46.0239 9.29462 48.3572 12.4061Z"
            fill="#D65583"/>
        <path
            d="M69.8356 21.0663H85.4835C85.0336 19.0613 84.1237 17.5246 82.7521 16.4601C81.3805 15.3956 79.7549 14.8634 77.8734 14.8634C75.7461 14.8634 73.9865 15.4057 72.5968 16.4901C71.2052 17.5746 70.2854 19.0994 69.8356 21.0643V21.0663ZM93.0935 27.2074H69.9575C70.9793 31.0572 73.8644 32.9802 78.6113 32.9802C81.6385 32.9802 83.9299 31.9576 85.4835 29.9087L91.8657 33.5945C88.8385 37.9766 84.3797 40.1656 78.4873 40.1656C73.4146 40.1656 69.3436 38.6309 66.2745 35.5594C63.2054 32.4879 61.6718 28.6201 61.6718 23.9518C61.6718 19.2836 63.1855 15.5177 66.2125 12.4063C69.1977 9.29486 73.0847 7.73804 77.8714 7.73804C82.6582 7.73804 86.1153 9.29486 88.9784 12.4063C91.9236 15.5177 93.3972 19.3678 93.3972 23.9518C93.3972 24.8122 93.2952 25.8968 93.0913 27.2074H93.0933H93.0935Z"
            fill="#D65583"/>
        <path
            d="M119.175 25.1803V8.59814H127.091V39.305H119.175V35.8653C117.293 38.7307 114.244 40.1634 110.031 40.1634C106.676 40.1634 103.895 39.0388 101.685 36.7857C99.5156 34.4927 98.432 31.381 98.432 27.4512V8.59814H106.348V26.4689C106.348 28.476 106.89 30.0306 107.973 31.1372C109.057 32.2418 110.541 32.796 112.422 32.796C114.51 32.796 116.155 32.1517 117.363 30.8611C118.569 29.5705 119.173 27.6775 119.173 25.1803H119.175Z"
            fill="#D65583"/>
        <path
            d="M143.073 32.7748C142.872 32.5075 142.636 32.2711 142.368 32.071C141.785 31.6351 141.07 31.3677 140.287 31.3677H138.654C136.728 31.3677 135.16 32.9348 135.16 34.8614V36.5293C135.167 37.3001 135.432 38.0053 135.863 38.5805C136.064 38.8475 136.3 39.0837 136.567 39.2838C137.15 39.7211 137.866 39.9898 138.65 39.9898H143.782V34.8616C143.782 34.0765 143.512 33.359 143.073 32.775L143.073 32.7748Z"
            fill="#61CDF6"/>
        <path
            d="M143.078 39.9897H138.65C137.867 39.9897 137.15 39.721 136.567 39.2837C136.3 39.0834 136.064 38.8474 135.863 38.5804C135.432 38.0052 135.167 37.3 135.16 36.5292V42.9C135.16 45.2772 137.094 47.2113 139.47 47.2113C141.847 47.2113 143.782 45.2772 143.782 42.9V39.9897H143.078Z"
            fill="#00A5D2"/>
        <path
            d="M146.689 31.3677H140.287C141.069 31.3677 141.785 31.6351 142.368 32.071C142.636 32.2711 142.872 32.5075 143.073 32.7748C143.512 33.3588 143.782 34.0763 143.782 34.8614V39.9896H146.69C149.066 39.9896 151 38.0555 151 35.6782C151 33.301 149.066 31.3677 146.689 31.3677Z"
            fill="#00A5D2"/>
    </g>
    <defs>
        <clipPath id="clip0_201_71">
            <rect width="151" height="52.4454" fill="white"/>
        </clipPath>
    </defs>
</svg>

const tgeu_logo_pdf = <svg x="400" y="49" width="151" height="53" viewBox="0 0 151 53" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_201_71)">
        <path
            d="M20.1906 8.59812V16.2138H13.2565V28.988C13.2565 30.0525 13.5225 30.8308 14.0544 31.3212C14.5862 31.8133 15.3641 32.0896 16.3857 32.1496C17.4075 32.2117 18.6771 32.2016 20.1906 32.1196V39.305C14.8301 39.9193 11.0171 39.4171 8.74564 37.8004C6.47419 36.1835 5.34057 33.2462 5.34057 28.988V16.2138H0V8.59812H5.33856V2.3952L13.2545 0V8.59812H20.1906Z"
            fill="#D65583"/>
        <path
            d="M45.9338 29.5104C47.5495 27.9356 48.3572 25.9187 48.3572 23.4614C48.3572 21.0042 47.5374 19.0193 45.902 17.5046C44.3064 15.9079 42.2609 15.1094 39.7655 15.1094C37.2702 15.1094 35.2448 15.9079 33.5673 17.5046C31.9717 19.0193 31.1739 21.0044 31.1739 23.4614C31.1739 25.9185 31.9816 27.9356 33.5973 29.5104C35.2128 31.0872 37.2684 31.8756 39.7637 31.8756C42.2591 31.8756 44.3145 31.0872 45.9302 29.5104H45.9338ZM48.3572 12.4061V8.59813H56.0892V37.8304C56.0892 42.5388 54.5136 46.1605 51.3645 48.6996C48.2552 51.1968 44.4923 52.4454 40.0735 52.4454C33.1172 52.4454 28.2706 49.9483 25.5294 44.9537L32.2797 41.0839C33.7934 43.9493 36.4725 45.382 40.3196 45.382C42.8149 45.382 44.7784 44.7256 46.2099 43.4171C47.6416 42.1065 48.3574 40.2435 48.3574 37.8284V34.5128C46.0261 37.6242 42.7528 39.1811 38.538 39.1811C34.3232 39.1811 30.5602 37.6664 27.6148 34.637C24.7096 31.5255 23.2579 27.7996 23.2579 23.4594C23.2579 19.1193 24.7315 15.4135 27.6767 12.344C30.6219 9.27447 34.243 7.73779 38.5378 7.73779C42.8327 7.73779 46.0239 9.29462 48.3572 12.4061Z"
            fill="#D65583"/>
        <path
            d="M69.8356 21.0663H85.4835C85.0336 19.0613 84.1237 17.5246 82.7521 16.4601C81.3805 15.3956 79.7549 14.8634 77.8734 14.8634C75.7461 14.8634 73.9865 15.4057 72.5968 16.4901C71.2052 17.5746 70.2854 19.0994 69.8356 21.0643V21.0663ZM93.0935 27.2074H69.9575C70.9793 31.0572 73.8644 32.9802 78.6113 32.9802C81.6385 32.9802 83.9299 31.9576 85.4835 29.9087L91.8657 33.5945C88.8385 37.9766 84.3797 40.1656 78.4873 40.1656C73.4146 40.1656 69.3436 38.6309 66.2745 35.5594C63.2054 32.4879 61.6718 28.6201 61.6718 23.9518C61.6718 19.2836 63.1855 15.5177 66.2125 12.4063C69.1977 9.29486 73.0847 7.73804 77.8714 7.73804C82.6582 7.73804 86.1153 9.29486 88.9784 12.4063C91.9236 15.5177 93.3972 19.3678 93.3972 23.9518C93.3972 24.8122 93.2952 25.8968 93.0913 27.2074H93.0933H93.0935Z"
            fill="#D65583"/>
        <path
            d="M119.175 25.1803V8.59814H127.091V39.305H119.175V35.8653C117.293 38.7307 114.244 40.1634 110.031 40.1634C106.676 40.1634 103.895 39.0388 101.685 36.7857C99.5156 34.4927 98.432 31.381 98.432 27.4512V8.59814H106.348V26.4689C106.348 28.476 106.89 30.0306 107.973 31.1372C109.057 32.2418 110.541 32.796 112.422 32.796C114.51 32.796 116.155 32.1517 117.363 30.8611C118.569 29.5705 119.173 27.6775 119.173 25.1803H119.175Z"
            fill="#D65583"/>
        <path
            d="M143.073 32.7748C142.872 32.5075 142.636 32.2711 142.368 32.071C141.785 31.6351 141.07 31.3677 140.287 31.3677H138.654C136.728 31.3677 135.16 32.9348 135.16 34.8614V36.5293C135.167 37.3001 135.432 38.0053 135.863 38.5805C136.064 38.8475 136.3 39.0837 136.567 39.2838C137.15 39.7211 137.866 39.9898 138.65 39.9898H143.782V34.8616C143.782 34.0765 143.512 33.359 143.073 32.775L143.073 32.7748Z"
            fill="#61CDF6"/>
        <path
            d="M143.078 39.9897H138.65C137.867 39.9897 137.15 39.721 136.567 39.2837C136.3 39.0834 136.064 38.8474 135.863 38.5804C135.432 38.0052 135.167 37.3 135.16 36.5292V42.9C135.16 45.2772 137.094 47.2113 139.47 47.2113C141.847 47.2113 143.782 45.2772 143.782 42.9V39.9897H143.078Z"
            fill="#00A5D2"/>
        <path
            d="M146.689 31.3677H140.287C141.069 31.3677 141.785 31.6351 142.368 32.071C142.636 32.2711 142.872 32.5075 143.073 32.7748C143.512 33.3588 143.782 34.0763 143.782 34.8614V39.9896H146.69C149.066 39.9896 151 38.0555 151 35.6782C151 33.301 149.066 31.3677 146.689 31.3677Z"
            fill="#00A5D2"/>
    </g>
    <defs>
        <clipPath id="clip0_201_71">
            <rect width="151" height="52.4454" fill="white"/>
        </clipPath>
    </defs>
</svg>

const tgeu_logo_pdf_small = <svg x="40" y="785" width="47" height="15" viewBox="0 0 151 53" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_201_71)">
        <path
            d="M20.1906 8.59812V16.2138H13.2565V28.988C13.2565 30.0525 13.5225 30.8308 14.0544 31.3212C14.5862 31.8133 15.3641 32.0896 16.3857 32.1496C17.4075 32.2117 18.6771 32.2016 20.1906 32.1196V39.305C14.8301 39.9193 11.0171 39.4171 8.74564 37.8004C6.47419 36.1835 5.34057 33.2462 5.34057 28.988V16.2138H0V8.59812H5.33856V2.3952L13.2545 0V8.59812H20.1906Z"
            fill="#D65583"/>
        <path
            d="M45.9338 29.5104C47.5495 27.9356 48.3572 25.9187 48.3572 23.4614C48.3572 21.0042 47.5374 19.0193 45.902 17.5046C44.3064 15.9079 42.2609 15.1094 39.7655 15.1094C37.2702 15.1094 35.2448 15.9079 33.5673 17.5046C31.9717 19.0193 31.1739 21.0044 31.1739 23.4614C31.1739 25.9185 31.9816 27.9356 33.5973 29.5104C35.2128 31.0872 37.2684 31.8756 39.7637 31.8756C42.2591 31.8756 44.3145 31.0872 45.9302 29.5104H45.9338ZM48.3572 12.4061V8.59813H56.0892V37.8304C56.0892 42.5388 54.5136 46.1605 51.3645 48.6996C48.2552 51.1968 44.4923 52.4454 40.0735 52.4454C33.1172 52.4454 28.2706 49.9483 25.5294 44.9537L32.2797 41.0839C33.7934 43.9493 36.4725 45.382 40.3196 45.382C42.8149 45.382 44.7784 44.7256 46.2099 43.4171C47.6416 42.1065 48.3574 40.2435 48.3574 37.8284V34.5128C46.0261 37.6242 42.7528 39.1811 38.538 39.1811C34.3232 39.1811 30.5602 37.6664 27.6148 34.637C24.7096 31.5255 23.2579 27.7996 23.2579 23.4594C23.2579 19.1193 24.7315 15.4135 27.6767 12.344C30.6219 9.27447 34.243 7.73779 38.5378 7.73779C42.8327 7.73779 46.0239 9.29462 48.3572 12.4061Z"
            fill="#D65583"/>
        <path
            d="M69.8356 21.0663H85.4835C85.0336 19.0613 84.1237 17.5246 82.7521 16.4601C81.3805 15.3956 79.7549 14.8634 77.8734 14.8634C75.7461 14.8634 73.9865 15.4057 72.5968 16.4901C71.2052 17.5746 70.2854 19.0994 69.8356 21.0643V21.0663ZM93.0935 27.2074H69.9575C70.9793 31.0572 73.8644 32.9802 78.6113 32.9802C81.6385 32.9802 83.9299 31.9576 85.4835 29.9087L91.8657 33.5945C88.8385 37.9766 84.3797 40.1656 78.4873 40.1656C73.4146 40.1656 69.3436 38.6309 66.2745 35.5594C63.2054 32.4879 61.6718 28.6201 61.6718 23.9518C61.6718 19.2836 63.1855 15.5177 66.2125 12.4063C69.1977 9.29486 73.0847 7.73804 77.8714 7.73804C82.6582 7.73804 86.1153 9.29486 88.9784 12.4063C91.9236 15.5177 93.3972 19.3678 93.3972 23.9518C93.3972 24.8122 93.2952 25.8968 93.0913 27.2074H93.0933H93.0935Z"
            fill="#D65583"/>
        <path
            d="M119.175 25.1803V8.59814H127.091V39.305H119.175V35.8653C117.293 38.7307 114.244 40.1634 110.031 40.1634C106.676 40.1634 103.895 39.0388 101.685 36.7857C99.5156 34.4927 98.432 31.381 98.432 27.4512V8.59814H106.348V26.4689C106.348 28.476 106.89 30.0306 107.973 31.1372C109.057 32.2418 110.541 32.796 112.422 32.796C114.51 32.796 116.155 32.1517 117.363 30.8611C118.569 29.5705 119.173 27.6775 119.173 25.1803H119.175Z"
            fill="#D65583"/>
        <path
            d="M143.073 32.7748C142.872 32.5075 142.636 32.2711 142.368 32.071C141.785 31.6351 141.07 31.3677 140.287 31.3677H138.654C136.728 31.3677 135.16 32.9348 135.16 34.8614V36.5293C135.167 37.3001 135.432 38.0053 135.863 38.5805C136.064 38.8475 136.3 39.0837 136.567 39.2838C137.15 39.7211 137.866 39.9898 138.65 39.9898H143.782V34.8616C143.782 34.0765 143.512 33.359 143.073 32.775L143.073 32.7748Z"
            fill="#61CDF6"/>
        <path
            d="M143.078 39.9897H138.65C137.867 39.9897 137.15 39.721 136.567 39.2837C136.3 39.0834 136.064 38.8474 135.863 38.5804C135.432 38.0052 135.167 37.3 135.16 36.5292V42.9C135.16 45.2772 137.094 47.2113 139.47 47.2113C141.847 47.2113 143.782 45.2772 143.782 42.9V39.9897H143.078Z"
            fill="#00A5D2"/>
        <path
            d="M146.689 31.3677H140.287C141.069 31.3677 141.785 31.6351 142.368 32.071C142.636 32.2711 142.872 32.5075 143.073 32.7748C143.512 33.3588 143.782 34.0763 143.782 34.8614V39.9896H146.69C149.066 39.9896 151 38.0555 151 35.6782C151 33.301 149.066 31.3677 146.689 31.3677Z"
            fill="#00A5D2"/>
    </g>
    <defs>
        <clipPath id="clip0_201_71">
            <rect width="151" height="52.4454" fill="white"/>
        </clipPath>
    </defs>
</svg>

const tgeu_logo_pdf_landscape = <svg x="716" y="30" width="86" height="28" viewBox="0 0 151 53" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_201_71)">
        <path
            d="M20.1906 8.59812V16.2138H13.2565V28.988C13.2565 30.0525 13.5225 30.8308 14.0544 31.3212C14.5862 31.8133 15.3641 32.0896 16.3857 32.1496C17.4075 32.2117 18.6771 32.2016 20.1906 32.1196V39.305C14.8301 39.9193 11.0171 39.4171 8.74564 37.8004C6.47419 36.1835 5.34057 33.2462 5.34057 28.988V16.2138H0V8.59812H5.33856V2.3952L13.2545 0V8.59812H20.1906Z"
            fill="#D65583"/>
        <path
            d="M45.9338 29.5104C47.5495 27.9356 48.3572 25.9187 48.3572 23.4614C48.3572 21.0042 47.5374 19.0193 45.902 17.5046C44.3064 15.9079 42.2609 15.1094 39.7655 15.1094C37.2702 15.1094 35.2448 15.9079 33.5673 17.5046C31.9717 19.0193 31.1739 21.0044 31.1739 23.4614C31.1739 25.9185 31.9816 27.9356 33.5973 29.5104C35.2128 31.0872 37.2684 31.8756 39.7637 31.8756C42.2591 31.8756 44.3145 31.0872 45.9302 29.5104H45.9338ZM48.3572 12.4061V8.59813H56.0892V37.8304C56.0892 42.5388 54.5136 46.1605 51.3645 48.6996C48.2552 51.1968 44.4923 52.4454 40.0735 52.4454C33.1172 52.4454 28.2706 49.9483 25.5294 44.9537L32.2797 41.0839C33.7934 43.9493 36.4725 45.382 40.3196 45.382C42.8149 45.382 44.7784 44.7256 46.2099 43.4171C47.6416 42.1065 48.3574 40.2435 48.3574 37.8284V34.5128C46.0261 37.6242 42.7528 39.1811 38.538 39.1811C34.3232 39.1811 30.5602 37.6664 27.6148 34.637C24.7096 31.5255 23.2579 27.7996 23.2579 23.4594C23.2579 19.1193 24.7315 15.4135 27.6767 12.344C30.6219 9.27447 34.243 7.73779 38.5378 7.73779C42.8327 7.73779 46.0239 9.29462 48.3572 12.4061Z"
            fill="#D65583"/>
        <path
            d="M69.8356 21.0663H85.4835C85.0336 19.0613 84.1237 17.5246 82.7521 16.4601C81.3805 15.3956 79.7549 14.8634 77.8734 14.8634C75.7461 14.8634 73.9865 15.4057 72.5968 16.4901C71.2052 17.5746 70.2854 19.0994 69.8356 21.0643V21.0663ZM93.0935 27.2074H69.9575C70.9793 31.0572 73.8644 32.9802 78.6113 32.9802C81.6385 32.9802 83.9299 31.9576 85.4835 29.9087L91.8657 33.5945C88.8385 37.9766 84.3797 40.1656 78.4873 40.1656C73.4146 40.1656 69.3436 38.6309 66.2745 35.5594C63.2054 32.4879 61.6718 28.6201 61.6718 23.9518C61.6718 19.2836 63.1855 15.5177 66.2125 12.4063C69.1977 9.29486 73.0847 7.73804 77.8714 7.73804C82.6582 7.73804 86.1153 9.29486 88.9784 12.4063C91.9236 15.5177 93.3972 19.3678 93.3972 23.9518C93.3972 24.8122 93.2952 25.8968 93.0913 27.2074H93.0933H93.0935Z"
            fill="#D65583"/>
        <path
            d="M119.175 25.1803V8.59814H127.091V39.305H119.175V35.8653C117.293 38.7307 114.244 40.1634 110.031 40.1634C106.676 40.1634 103.895 39.0388 101.685 36.7857C99.5156 34.4927 98.432 31.381 98.432 27.4512V8.59814H106.348V26.4689C106.348 28.476 106.89 30.0306 107.973 31.1372C109.057 32.2418 110.541 32.796 112.422 32.796C114.51 32.796 116.155 32.1517 117.363 30.8611C118.569 29.5705 119.173 27.6775 119.173 25.1803H119.175Z"
            fill="#D65583"/>
        <path
            d="M143.073 32.7748C142.872 32.5075 142.636 32.2711 142.368 32.071C141.785 31.6351 141.07 31.3677 140.287 31.3677H138.654C136.728 31.3677 135.16 32.9348 135.16 34.8614V36.5293C135.167 37.3001 135.432 38.0053 135.863 38.5805C136.064 38.8475 136.3 39.0837 136.567 39.2838C137.15 39.7211 137.866 39.9898 138.65 39.9898H143.782V34.8616C143.782 34.0765 143.512 33.359 143.073 32.775L143.073 32.7748Z"
            fill="#61CDF6"/>
        <path
            d="M143.078 39.9897H138.65C137.867 39.9897 137.15 39.721 136.567 39.2837C136.3 39.0834 136.064 38.8474 135.863 38.5804C135.432 38.0052 135.167 37.3 135.16 36.5292V42.9C135.16 45.2772 137.094 47.2113 139.47 47.2113C141.847 47.2113 143.782 45.2772 143.782 42.9V39.9897H143.078Z"
            fill="#00A5D2"/>
        <path
            d="M146.689 31.3677H140.287C141.069 31.3677 141.785 31.6351 142.368 32.071C142.636 32.2711 142.872 32.5075 143.073 32.7748C143.512 33.3588 143.782 34.0763 143.782 34.8614V39.9896H146.69C149.066 39.9896 151 38.0555 151 35.6782C151 33.301 149.066 31.3677 146.689 31.3677Z"
            fill="#00A5D2"/>
    </g>
    <defs>
        <clipPath id="clip0_201_71">
            <rect width="151" height="52.4454" fill="white"/>
        </clipPath>
    </defs>
</svg>

const ue_flag_pdf = <svg x="40" y="770" width="203" height="43" viewBox="0 0 203 43" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1904_42973)">
        <path d="M63.6456 0H0V43H63.6456V0Z" fill="white"/>
        <path d="M62.4846 1.08594H1.15463V41.7573H62.4846V1.08594Z" fill="#003399"/>
        <path
            d="M30.5183 9.95047L31.8069 9.01141L33.1019 9.95047L32.6107 8.42767L33.9248 7.48861H32.3045L31.8069 5.95312L31.3157 7.49496L29.6953 7.48861L31.0095 8.42767L30.5183 9.95047Z"
            fill="#FFCC00"/>
        <path
            d="M23.8201 11.7395L25.115 10.8005L26.4036 11.7395L25.9124 10.2231L27.2266 9.27768H25.6062L25.1087 7.74219L24.6175 9.28402L22.9971 9.27768L24.3113 10.2231L23.8201 11.7395Z"
            fill="#FFCC00"/>
        <path
            d="M20.2094 12.625L19.7182 14.1668L18.0979 14.1605L19.412 15.0995L18.9208 16.6223L20.2094 15.6833L21.5044 16.6223L21.0132 15.0995L22.3273 14.1605H20.707L20.2094 12.625Z"
            fill="#FFCC00"/>
        <path
            d="M18.4169 22.3356L19.7055 23.2747L19.2143 21.7582L20.5284 20.8192H18.9081L18.4169 19.2773L17.9193 20.8192H16.299L17.6131 21.7582L17.1219 23.2747L18.4169 22.3356Z"
            fill="#FFCC00"/>
        <path
            d="M20.707 27.4911L20.2094 25.9492L19.7182 27.4911H18.0979L19.412 28.4301L18.9208 29.9466L20.2094 29.0075L21.5044 29.9466L21.0132 28.4301L22.3273 27.4911H20.707Z"
            fill="#FFCC00"/>
        <path
            d="M25.619 32.3714L25.1214 30.8359L24.6238 32.3714H23.0035L24.3176 33.3105L23.8264 34.8333L25.1214 33.8942L26.4164 34.8333L25.9252 33.3105L27.2393 32.3714H25.619Z"
            fill="#FFCC00"/>
        <path
            d="M32.3045 34.141L31.8133 32.6055L31.3157 34.141H29.6953L31.0095 35.08L30.5183 36.6028L31.8133 35.6638L33.1019 36.6028L32.6107 35.08L33.9248 34.141H32.3045Z"
            fill="#FFCC00"/>
        <path
            d="M38.9963 32.3714L38.4987 30.8359L38.0011 32.3714H36.3871L37.6949 33.3105L37.2037 34.8333L38.4987 33.8942L39.7937 34.8333L39.3025 33.3105L40.6166 32.3714H38.9963Z"
            fill="#FFCC00"/>
        <path
            d="M43.9083 27.4911L43.4107 25.9492L42.9131 27.4911H41.2928L42.6069 28.4301L42.1157 29.9466L43.4107 29.0075L44.7057 29.9466L44.2081 28.4301L45.5222 27.4911H43.9083Z"
            fill="#FFCC00"/>
        <path
            d="M47.302 20.7996H45.6817L45.1841 19.2578L44.6929 20.7996H43.0726L44.3867 21.7387L43.8955 23.2552L45.1841 22.3161L46.4791 23.2552L45.9879 21.7387L47.302 20.7996Z"
            fill="#FFCC00"/>
        <path
            d="M42.1157 16.6067L43.4107 15.6677L44.7057 16.6067L44.2081 15.0839L45.5222 14.1449H43.9083L43.4107 12.6094L42.9131 14.1512L41.2928 14.1449L42.6069 15.0839L42.1157 16.6067Z"
            fill="#FFCC00"/>
        <path
            d="M38.5178 7.74219L38.0202 9.28402L36.4063 9.27768L37.714 10.2231L37.2228 11.7395L38.5178 10.8005L39.8128 11.7395L39.3216 10.2231L40.6357 9.27768H39.0154L38.5178 7.74219Z"
            fill="#FFCC00"/>
        <path
            d="M77.5332 14.4045L79.5108 15.0263C79.211 16.124 78.707 16.9361 77.9989 17.4691C77.2972 18.0021 76.4041 18.2686 75.3196 18.2686C73.98 18.2686 72.8764 17.8118 72.0152 16.9044C71.154 15.9907 70.7202 14.7471 70.7202 13.1672C70.7202 11.4985 71.154 10.1978 72.0216 9.27774C72.8892 8.35137 74.031 7.89453 75.4409 7.89453C76.672 7.89453 77.68 8.2562 78.4455 8.98587C78.9048 9.41733 79.2493 10.0328 79.4789 10.8323L77.4631 11.3145C77.3419 10.7942 77.0931 10.3818 76.7167 10.0836C76.3403 9.78534 75.881 9.63306 75.3388 9.63306C74.5924 9.63306 73.9864 9.89955 73.5207 10.4325C73.055 10.9655 72.8254 11.8284 72.8254 13.0276C72.8254 14.2966 73.055 15.1976 73.5143 15.7369C73.9736 16.2763 74.5669 16.5428 75.3069 16.5428C75.8491 16.5428 76.3148 16.3714 76.7039 16.0288C77.0931 15.6862 77.3738 15.1469 77.546 14.4108L77.5332 14.4045Z"
            fill="#003399"/>
        <path
            d="M80.7994 14.3568C80.7994 13.716 80.9589 13.1005 81.2715 12.5041C81.5904 11.9077 82.037 11.4508 82.6175 11.1336C83.198 10.8163 83.8487 10.6641 84.5631 10.6641C85.6668 10.6641 86.5726 11.0194 87.2807 11.7364C87.9888 12.4533 88.3396 13.3543 88.3396 14.4457C88.3396 15.537 87.9824 16.457 87.2679 17.1804C86.5535 17.9037 85.654 18.2654 84.5759 18.2654C83.9061 18.2654 83.2682 18.1131 82.6621 17.8149C82.0561 17.5167 81.5904 17.0725 81.2778 16.4888C80.9589 15.905 80.8058 15.2007 80.8058 14.3632L80.7994 14.3568ZM82.7833 14.4584C82.7833 15.1817 82.9556 15.7337 83.3001 16.1144C83.6445 16.4951 84.0656 16.6918 84.5759 16.6918C85.0862 16.6918 85.5009 16.5015 85.8454 16.1144C86.1899 15.7337 86.3557 15.1754 86.3557 14.4457C86.3557 13.716 86.1835 13.1894 85.8454 12.8023C85.5009 12.4216 85.0799 12.2249 84.5759 12.2249C84.0719 12.2249 83.6445 12.4153 83.3001 12.8023C82.9556 13.183 82.7833 13.735 82.7833 14.4584Z"
            fill="#003399"/>
        <path d="M89.6346 15.4186V13.4961H93.4367V15.4186H89.6346Z" fill="#003399"/>
        <path
            d="M93.6982 10.8244H94.7699V10.2788C94.7699 9.66966 94.8337 9.21282 94.9677 8.90826C95.0953 8.61005 95.3377 8.36259 95.6885 8.17224C96.0394 7.98189 96.4859 7.88672 97.0218 7.88672C97.5576 7.88672 98.1126 7.9692 98.6421 8.13417L98.3806 9.47297C98.0744 9.39683 97.7745 9.3651 97.4939 9.3651C97.2132 9.3651 97.0154 9.42855 96.8942 9.5618C96.773 9.6887 96.7092 9.9425 96.7092 10.3105V10.8244H98.1573V12.3346H96.7092V18.0895H94.7763V12.3346H93.7046V10.8244H93.6982Z"
            fill="#003399"/>
        <path
            d="M104.052 18.0941V17.0028C103.784 17.3898 103.433 17.6944 102.999 17.9228C102.565 18.1449 102.106 18.2591 101.628 18.2591C101.149 18.2591 100.696 18.1512 100.307 17.9355C99.918 17.7198 99.6373 17.4215 99.4587 17.0345C99.2864 16.6475 99.1971 16.1081 99.1971 15.4229V10.8228H101.13V14.1602C101.13 15.1818 101.168 15.8099 101.238 16.0383C101.309 16.2668 101.436 16.4508 101.628 16.584C101.813 16.7172 102.055 16.787 102.342 16.787C102.674 16.787 102.967 16.6982 103.229 16.5142C103.49 16.3366 103.669 16.1081 103.765 15.8416C103.86 15.5752 103.911 14.9216 103.911 13.881V10.8164H105.844V18.0878H104.045L104.052 18.0941Z"
            fill="#003399"/>
        <path
            d="M114.501 18.095H112.568V14.3832C112.568 13.5964 112.53 13.0888 112.447 12.8604C112.364 12.632 112.23 12.448 112.045 12.3211C111.86 12.1942 111.637 12.1307 111.375 12.1307C111.037 12.1307 110.737 12.2196 110.476 12.4036C110.214 12.5876 110.029 12.8287 109.927 13.1269C109.825 13.4251 109.78 13.9835 109.78 14.7956V18.0887H107.847V10.8173H109.646V11.8833C110.284 11.0648 111.088 10.6523 112.058 10.6523C112.485 10.6523 112.874 10.7285 113.225 10.8808C113.576 11.033 113.844 11.2297 114.029 11.4645C114.207 11.6993 114.335 11.9721 114.405 12.2703C114.475 12.5685 114.514 13 114.514 13.5647V18.0823L114.501 18.095Z"
            fill="#003399"/>
        <path
            d="M123.164 18.0964H121.365V17.0304C121.065 17.4428 120.714 17.7537 120.306 17.9568C119.898 18.1598 119.489 18.2613 119.075 18.2613C118.233 18.2613 117.518 17.9251 116.919 17.2525C116.319 16.5799 116.019 15.6409 116.019 14.4353C116.019 13.2298 116.313 12.2653 116.893 11.6245C117.474 10.9836 118.214 10.66 119.1 10.66C119.917 10.66 120.625 10.9963 121.218 11.6752V8.05859H123.151V18.0964H123.164ZM118.003 14.3021C118.003 15.0762 118.112 15.6409 118.328 15.9835C118.641 16.4847 119.075 16.7385 119.636 16.7385C120.083 16.7385 120.459 16.5482 120.772 16.1738C121.084 15.7995 121.237 15.2348 121.237 14.4861C121.237 13.6485 121.084 13.0521 120.784 12.6841C120.485 12.3161 120.095 12.1321 119.623 12.1321C119.151 12.1321 118.781 12.3161 118.469 12.6777C118.156 13.0394 118.003 13.5851 118.003 14.3021Z"
            fill="#003399"/>
        <path
            d="M129.301 15.7781L131.227 16.1017C130.978 16.806 130.589 17.339 130.053 17.707C129.518 18.075 128.848 18.259 128.044 18.259C126.775 18.259 125.83 17.8466 125.224 17.0217C124.746 16.3619 124.504 15.5243 124.504 14.5155C124.504 13.3099 124.822 12.3645 125.454 11.6856C126.086 11.0003 126.889 10.6641 127.853 10.6641C128.937 10.6641 129.798 11.0194 130.43 11.7364C131.055 12.4534 131.361 13.5447 131.329 15.0167H126.487C126.5 15.5878 126.66 16.0319 126.953 16.3492C127.253 16.6664 127.623 16.825 128.07 16.825C128.376 16.825 128.625 16.7426 128.835 16.5776C129.039 16.4126 129.199 16.1461 129.301 15.7845V15.7781ZM129.409 13.8366C129.396 13.2782 129.25 12.8594 128.975 12.5676C128.701 12.2757 128.363 12.1298 127.967 12.1298C127.546 12.1298 127.196 12.282 126.921 12.5866C126.647 12.8912 126.513 13.3099 126.513 13.8302H129.403L129.409 13.8366Z"
            fill="#003399"/>
        <path
            d="M139.61 18.0964H137.811V17.0304C137.511 17.4428 137.16 17.7537 136.752 17.9568C136.343 18.1598 135.935 18.2613 135.52 18.2613C134.678 18.2613 133.964 17.9251 133.364 17.2525C132.765 16.5799 132.465 15.6409 132.465 14.4353C132.465 13.2298 132.758 12.2653 133.339 11.6245C133.919 10.9836 134.659 10.66 135.546 10.66C136.363 10.66 137.071 10.9963 137.664 11.6752V8.05859H139.597V18.0964H139.61ZM134.449 14.3021C134.449 15.0762 134.557 15.6409 134.774 15.9835C135.087 16.4847 135.52 16.7385 136.082 16.7385C136.528 16.7385 136.905 16.5482 137.217 16.1738C137.53 15.7995 137.683 15.2348 137.683 14.4861C137.683 13.6485 137.53 13.0521 137.23 12.6841C136.93 12.3161 136.541 12.1321 136.069 12.1321C135.597 12.1321 135.227 12.3161 134.914 12.6777C134.602 13.0394 134.449 13.5851 134.449 14.3021Z"
            fill="#003399"/>
        <path
            d="M145.351 18.0964V8.05859H147.284V11.6752C147.877 11.0027 148.585 10.66 149.402 10.66C150.295 10.66 151.028 10.9836 151.609 11.6245C152.189 12.2653 152.483 13.1853 152.483 14.3846C152.483 15.5838 152.183 16.5799 151.59 17.2525C150.997 17.9251 150.276 18.2613 149.427 18.2613C149.013 18.2613 148.598 18.1598 148.19 17.9504C147.781 17.7411 147.437 17.4365 147.143 17.0304V18.0964H145.344H145.351ZM147.271 14.3021C147.271 15.0571 147.392 15.6091 147.628 15.9708C147.96 16.4847 148.407 16.7385 148.962 16.7385C149.389 16.7385 149.753 16.5545 150.052 16.1929C150.352 15.8312 150.505 15.2602 150.505 14.4797C150.505 13.6485 150.352 13.0521 150.052 12.6841C149.753 12.3161 149.363 12.1321 148.891 12.1321C148.419 12.1321 148.043 12.3097 147.737 12.6714C147.431 13.0267 147.277 13.5724 147.277 14.3021H147.271Z"
            fill="#003399"/>
        <path
            d="M153.127 10.8242H155.188L156.936 15.9827L158.639 10.8242H160.642L158.058 17.8227L157.599 19.0917C157.427 19.5169 157.267 19.8405 157.114 20.0625C156.961 20.2846 156.782 20.4686 156.585 20.6082C156.387 20.7478 156.138 20.8556 155.851 20.9318C155.558 21.0079 155.232 21.046 154.862 21.046C154.492 21.046 154.122 21.0079 153.765 20.9318L153.593 19.428C153.893 19.4851 154.167 19.5169 154.409 19.5169C154.862 19.5169 155.194 19.3836 155.405 19.1235C155.615 18.8633 155.787 18.527 155.902 18.121L153.121 10.8306L153.127 10.8242Z"
            fill="#003399"/>
        <path
            d="M74.4202 27.6464V29.1819H73.0997V32.1133C73.0997 32.7033 73.1124 33.0523 73.1379 33.1475C73.1635 33.249 73.2209 33.3251 73.3102 33.3886C73.3995 33.452 73.5079 33.4838 73.6355 33.4838C73.8141 33.4838 74.0757 33.4203 74.4138 33.2998L74.5796 34.7908C74.1267 34.9812 73.6228 35.0764 73.0486 35.0764C72.6978 35.0764 72.3852 35.0193 72.1045 34.8987C71.8238 34.7845 71.6197 34.6322 71.4857 34.4482C71.3517 34.2642 71.2624 34.0168 71.2114 33.6995C71.1731 33.4774 71.1476 33.0269 71.1476 32.3417V29.1755H70.2609V27.64H71.1476V26.1934L73.0869 25.0703V27.64H74.4074L74.4202 27.6464Z"
            fill="#003399"/>
        <path
            d="M77.6863 24.8789V28.5653C78.3115 27.842 79.0579 27.4804 79.9254 27.4804C80.372 27.4804 80.7739 27.5628 81.1311 27.7278C81.4883 27.8928 81.7563 28.1022 81.9413 28.356C82.1263 28.6098 82.2475 28.8953 82.3113 29.2062C82.3751 29.5171 82.4133 29.9993 82.4133 30.6529V34.9167H80.4804V31.078C80.4804 30.3166 80.4422 29.8343 80.372 29.625C80.2954 29.4219 80.1678 29.2569 79.9828 29.1364C79.7978 29.0158 79.5618 28.9524 79.2875 28.9524C78.9686 28.9524 78.6815 29.0285 78.4263 29.1872C78.1711 29.3394 77.9861 29.5742 77.8713 29.8915C77.7565 30.2024 77.6927 30.6655 77.6927 31.2747V34.9167H75.7598V24.8789H77.6927H77.6863Z"
            fill="#003399"/>
        <path
            d="M88.6012 32.6023L90.5277 32.9259C90.2789 33.6302 89.8898 34.1632 89.354 34.5312C88.8181 34.8992 88.1483 35.0832 87.3509 35.0832C86.0814 35.0832 85.1373 34.6708 84.5312 33.846C84.0464 33.1861 83.8104 32.3485 83.8104 31.3397C83.8104 30.1341 84.1293 29.1951 84.7609 28.5098C85.3924 27.8309 86.1962 27.4883 87.1659 27.4883C88.2503 27.4883 89.1115 27.8436 89.7367 28.5606C90.3682 29.2712 90.6681 30.3689 90.6362 31.8409H85.7943C85.8071 32.412 85.9666 32.8561 86.26 33.1734C86.5598 33.4906 86.9298 33.6493 87.3764 33.6493C87.6762 33.6493 87.9314 33.5668 88.1419 33.4018C88.346 33.2368 88.5055 32.9704 88.6076 32.6087L88.6012 32.6023ZM88.7096 30.6608C88.6969 30.1024 88.5502 29.6836 88.2759 29.3918C88.0016 29.0999 87.6634 28.954 87.2743 28.954C86.8533 28.954 86.5024 29.1063 86.2281 29.4108C85.9538 29.7154 85.8198 30.1341 85.8198 30.6544H88.7096V30.6608Z"
            fill="#003399"/>
        <path
            d="M96.1351 34.9167V24.8789H103.618V26.5794H98.1764V28.8065H103.242V30.5006H98.1764V33.2289H103.816V34.923H96.1415L96.1351 34.9167Z"
            fill="#003399"/>
        <path
            d="M110.335 34.9159V33.8309C110.067 34.2179 109.723 34.5225 109.289 34.7509C108.855 34.9793 108.396 35.0872 107.918 35.0872C107.439 35.0872 106.986 34.9793 106.597 34.7636C106.208 34.5479 105.927 34.2497 105.749 33.8626C105.576 33.4756 105.487 32.9363 105.487 32.251V27.6509H107.42V30.9883C107.42 32.0099 107.458 32.638 107.528 32.8665C107.599 33.0949 107.726 33.2789 107.918 33.4121C108.109 33.5454 108.345 33.6152 108.632 33.6152C108.964 33.6152 109.257 33.5263 109.519 33.3423C109.78 33.1583 109.959 32.9363 110.055 32.6698C110.15 32.4033 110.201 31.7497 110.201 30.7092V27.6445H112.134V34.9159H110.335Z"
            fill="#003399"/>
        <path
            d="M115.987 34.9168H114.054V27.6454H115.853V28.6797C116.159 28.1911 116.44 27.8675 116.683 27.7152C116.925 27.5566 117.206 27.4805 117.518 27.4805C117.958 27.4805 118.386 27.601 118.794 27.8421L118.194 29.5172C117.869 29.3078 117.563 29.2 117.289 29.2C117.014 29.2 116.791 29.2761 116.6 29.422C116.408 29.568 116.262 29.8408 116.153 30.2278C116.045 30.6149 115.994 31.4271 115.994 32.6643V34.9105L115.987 34.9168Z"
            fill="#003399"/>
        <path
            d="M119.17 31.1811C119.17 30.5402 119.33 29.9248 119.643 29.3283C119.962 28.7319 120.408 28.2751 120.989 27.9578C121.569 27.6406 122.22 27.4883 122.934 27.4883C124.038 27.4883 124.944 27.8436 125.652 28.5606C126.36 29.2712 126.711 30.1786 126.711 31.2699C126.711 32.3612 126.353 33.2813 125.639 34.0046C124.925 34.7279 124.025 35.0896 122.947 35.0896C122.277 35.0896 121.639 34.9373 121.033 34.6391C120.427 34.3409 119.962 33.8967 119.649 33.313C119.336 32.7292 119.177 32.025 119.177 31.1874L119.17 31.1811ZM121.154 31.2826C121.154 32.0059 121.327 32.5579 121.671 32.9386C122.016 33.3193 122.437 33.516 122.947 33.516C123.457 33.516 123.872 33.3257 124.216 32.9386C124.561 32.5579 124.727 31.9996 124.727 31.2699C124.727 30.5402 124.555 30.0072 124.216 29.6265C123.872 29.2458 123.451 29.0491 122.947 29.0491C122.443 29.0491 122.016 29.2395 121.671 29.6265C121.327 30.0072 121.154 30.5593 121.154 31.2826Z"
            fill="#003399"/>
        <path
            d="M128.172 27.6454H129.977V28.7114C130.213 28.3434 130.526 28.0515 130.927 27.8231C131.329 27.5947 131.769 27.4805 132.254 27.4805C133.103 27.4805 133.824 27.8104 134.417 28.4703C135.01 29.1302 135.304 30.0565 135.304 31.2367C135.304 32.4169 135.004 33.394 134.41 34.0666C133.817 34.7391 133.09 35.0754 132.242 35.0754C131.84 35.0754 131.47 34.9929 131.144 34.8343C130.819 34.6757 130.468 34.4029 130.111 34.0158V37.6769H128.178V27.6391L128.172 27.6454ZM130.085 31.1542C130.085 31.9727 130.245 32.5755 130.577 32.9625C130.908 33.3496 131.297 33.5463 131.769 33.5463C132.242 33.5463 132.592 33.3686 132.892 33.007C133.192 32.6516 133.339 32.0616 133.339 31.243C133.339 30.4816 133.186 29.9169 132.879 29.5426C132.573 29.1746 132.19 28.9906 131.738 28.9906C131.285 28.9906 130.87 29.1746 130.564 29.5362C130.251 29.8979 130.098 30.4372 130.098 31.1479L130.085 31.1542Z"
            fill="#003399"/>
        <path
            d="M141.07 32.6023L142.997 32.9259C142.748 33.6302 142.359 34.1632 141.823 34.5312C141.287 34.8992 140.617 35.0832 139.814 35.0832C138.544 35.0832 137.6 34.6708 136.994 33.846C136.516 33.1861 136.273 32.3485 136.273 31.3397C136.273 30.1341 136.592 29.1951 137.224 28.5098C137.855 27.8309 138.659 27.4883 139.622 27.4883C140.707 27.4883 141.568 27.8436 142.2 28.5606C142.825 29.2712 143.131 30.3689 143.099 31.8409H138.257C138.27 32.412 138.429 32.8561 138.723 33.1734C139.023 33.4906 139.393 33.6493 139.839 33.6493C140.145 33.6493 140.394 33.5668 140.605 33.4018C140.809 33.2368 140.968 32.9704 141.07 32.6087V32.6023ZM141.179 30.6608C141.166 30.1024 141.019 29.6836 140.745 29.3918C140.471 29.0999 140.133 28.954 139.737 28.954C139.316 28.954 138.965 29.1063 138.691 29.4108C138.417 29.7154 138.283 30.1341 138.283 30.6544H141.172L141.179 30.6608Z"
            fill="#003399"/>
        <path
            d="M146.123 29.8662L144.368 29.5489C144.566 28.8446 144.904 28.3244 145.389 27.9881C145.868 27.6518 146.588 27.4805 147.539 27.4805C148.4 27.4805 149.044 27.582 149.465 27.785C149.886 27.9881 150.186 28.2482 150.359 28.5591C150.531 28.87 150.614 29.4474 150.614 30.2786L150.595 32.5247C150.595 33.1656 150.626 33.6351 150.69 33.9397C150.754 34.2442 150.869 34.5678 151.035 34.9168H149.121C149.07 34.7899 149.006 34.5995 148.936 34.3457C148.904 34.2315 148.879 34.1554 148.866 34.1173C148.534 34.4346 148.183 34.6757 147.807 34.8343C147.43 34.9929 147.029 35.0754 146.601 35.0754C145.848 35.0754 145.255 34.8724 144.821 34.4663C144.388 34.0602 144.171 33.5463 144.171 32.9245C144.171 32.512 144.273 32.1504 144.464 31.8268C144.662 31.5032 144.936 31.2557 145.293 31.0844C145.651 30.9131 146.161 30.7672 146.831 30.6339C147.737 30.4626 148.362 30.3103 148.706 30.1581V29.9677C148.706 29.5997 148.617 29.3332 148.432 29.1746C148.247 29.016 147.903 28.9398 147.392 28.9398C147.048 28.9398 146.78 29.0096 146.588 29.1429C146.397 29.2761 146.238 29.5109 146.123 29.8535V29.8662ZM148.713 31.427C148.464 31.5095 148.075 31.6047 147.539 31.7189C147.003 31.8331 146.652 31.9473 146.486 32.0552C146.231 32.2329 146.11 32.4613 146.11 32.7341C146.11 33.007 146.212 33.2354 146.41 33.4321C146.608 33.6288 146.869 33.7239 147.182 33.7239C147.533 33.7239 147.864 33.6097 148.177 33.3813C148.413 33.21 148.566 32.9943 148.636 32.7468C148.687 32.5818 148.713 32.2709 148.713 31.8078V31.427Z"
            fill="#003399"/>
        <path
            d="M159.162 34.9153H157.229V31.2035C157.229 30.4167 157.191 29.9091 157.108 29.6807C157.025 29.4523 156.891 29.2683 156.706 29.1414C156.521 29.0145 156.298 28.951 156.036 28.951C155.698 28.951 155.398 29.0399 155.137 29.2239C154.875 29.4079 154.69 29.649 154.588 29.9472C154.486 30.2454 154.441 30.8038 154.441 31.6159V34.909H152.508V27.6376H154.307V28.7036C154.945 27.8851 155.749 27.4727 156.719 27.4727C157.146 27.4727 157.535 27.5488 157.886 27.7011C158.237 27.8534 158.505 28.0501 158.69 28.2848C158.868 28.5196 158.996 28.7924 159.066 29.0906C159.136 29.3888 159.175 29.8203 159.175 30.385V34.9026L159.162 34.9153Z"
            fill="#003399"/>
        <path
            d="M165.037 24.8789H167.072V30.3166C167.072 31.1795 167.098 31.7378 167.149 31.9916C167.238 32.4041 167.442 32.734 167.774 32.9815C168.099 33.2289 168.552 33.3558 169.12 33.3558C169.688 33.3558 170.134 33.2416 170.428 33.0005C170.721 32.7657 170.9 32.4739 170.957 32.1376C171.015 31.7949 171.046 31.2302 171.046 30.4308V24.8789H173.081V30.1516C173.081 31.3571 173.024 32.2074 172.916 32.7023C172.807 33.1972 172.603 33.6223 172.303 33.9586C172.01 34.3012 171.614 34.5741 171.117 34.7771C170.619 34.9801 169.975 35.0817 169.177 35.0817C168.214 35.0817 167.48 34.9738 166.983 34.7517C166.485 34.5296 166.09 34.2441 165.803 33.8888C165.516 33.5335 165.324 33.1655 165.229 32.7784C165.095 32.201 165.031 31.3571 165.031 30.2341V24.8789H165.037Z"
            fill="#003399"/>
        <path
            d="M181.866 34.9153H179.933V31.2035C179.933 30.4167 179.894 29.9091 179.811 29.6807C179.729 29.4523 179.595 29.2683 179.41 29.1414C179.225 29.0145 179.001 28.951 178.74 28.951C178.402 28.951 178.102 29.0399 177.84 29.2239C177.579 29.4079 177.394 29.649 177.292 29.9472C177.19 30.2454 177.145 30.8038 177.145 31.6159V34.909H175.212V27.6376H177.011V28.7036C177.649 27.8851 178.453 27.4727 179.422 27.4727C179.85 27.4727 180.239 27.5488 180.59 27.7011C180.941 27.8534 181.209 28.0501 181.394 28.2848C181.572 28.5196 181.7 28.7924 181.77 29.0906C181.84 29.3888 181.878 29.8203 181.878 30.385V34.9026L181.866 34.9153Z"
            fill="#003399"/>
        <path d="M183.83 26.6633V24.8867H185.763V26.6633H183.83ZM183.83 34.9181V27.6468H185.763V34.9181H183.83Z"
              fill="#003399"/>
        <path
            d="M187.294 31.1811C187.294 30.5402 187.454 29.9248 187.766 29.3283C188.085 28.7319 188.532 28.2751 189.112 27.9578C189.693 27.6406 190.344 27.4883 191.058 27.4883C192.162 27.4883 193.068 27.8436 193.776 28.5606C194.484 29.2712 194.835 30.1786 194.835 31.2699C194.835 32.3612 194.477 33.2813 193.763 34.0046C193.048 34.7279 192.149 35.0896 191.071 35.0896C190.401 35.0896 189.763 34.9373 189.157 34.6391C188.551 34.3409 188.085 33.8967 187.773 33.313C187.46 32.7292 187.301 32.025 187.301 31.1874L187.294 31.1811ZM189.278 31.2826C189.278 32.0059 189.451 32.5579 189.795 32.9386C190.139 33.3193 190.56 33.516 191.071 33.516C191.581 33.516 191.996 33.3257 192.34 32.9386C192.685 32.5579 192.851 31.9996 192.851 31.2699C192.851 30.5402 192.678 30.0072 192.34 29.6265C191.996 29.2458 191.575 29.0491 191.071 29.0491C190.567 29.0491 190.139 29.2395 189.795 29.6265C189.451 30.0072 189.278 30.5593 189.278 31.2826Z"
            fill="#003399"/>
        <path
            d="M203 34.9153H201.067V31.2035C201.067 30.4167 201.029 29.9091 200.946 29.6807C200.863 29.4523 200.729 29.2683 200.544 29.1414C200.359 29.0145 200.136 28.951 199.874 28.951C199.536 28.951 199.236 29.0399 198.975 29.2239C198.713 29.4079 198.528 29.649 198.426 29.9472C198.324 30.2454 198.279 30.8038 198.279 31.6159V34.909H196.346V27.6376H198.145V28.7036C198.783 27.8851 199.587 27.4727 200.557 27.4727C200.984 27.4727 201.373 27.5488 201.724 27.7011C202.075 27.8534 202.343 28.0501 202.528 28.2848C202.707 28.5196 202.834 28.7924 202.904 29.0906C202.975 29.3888 203.013 29.8203 203.013 30.385V34.9026L203 34.9153Z"
            fill="#003399"/>
    </g>
    <defs>
        <clipPath id="clip0_1904_42973">
            <rect width="203" height="43" fill="white"/>
        </clipPath>
    </defs>
</svg>

const ue_flag_bottom_right = <svg x="400" y="770" width="152" height="32" viewBox="0 0 203 43" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1904_42973)">
        <path d="M63.6456 0H0V43H63.6456V0Z" fill="white"/>
        <path d="M62.4846 1.08594H1.15463V41.7573H62.4846V1.08594Z" fill="#003399"/>
        <path
            d="M30.5183 9.95047L31.8069 9.01141L33.1019 9.95047L32.6107 8.42767L33.9248 7.48861H32.3045L31.8069 5.95312L31.3157 7.49496L29.6953 7.48861L31.0095 8.42767L30.5183 9.95047Z"
            fill="#FFCC00"/>
        <path
            d="M23.8201 11.7395L25.115 10.8005L26.4036 11.7395L25.9124 10.2231L27.2266 9.27768H25.6062L25.1087 7.74219L24.6175 9.28402L22.9971 9.27768L24.3113 10.2231L23.8201 11.7395Z"
            fill="#FFCC00"/>
        <path
            d="M20.2094 12.625L19.7182 14.1668L18.0979 14.1605L19.412 15.0995L18.9208 16.6223L20.2094 15.6833L21.5044 16.6223L21.0132 15.0995L22.3273 14.1605H20.707L20.2094 12.625Z"
            fill="#FFCC00"/>
        <path
            d="M18.4169 22.3356L19.7055 23.2747L19.2143 21.7582L20.5284 20.8192H18.9081L18.4169 19.2773L17.9193 20.8192H16.299L17.6131 21.7582L17.1219 23.2747L18.4169 22.3356Z"
            fill="#FFCC00"/>
        <path
            d="M20.707 27.4911L20.2094 25.9492L19.7182 27.4911H18.0979L19.412 28.4301L18.9208 29.9466L20.2094 29.0075L21.5044 29.9466L21.0132 28.4301L22.3273 27.4911H20.707Z"
            fill="#FFCC00"/>
        <path
            d="M25.619 32.3714L25.1214 30.8359L24.6238 32.3714H23.0035L24.3176 33.3105L23.8264 34.8333L25.1214 33.8942L26.4164 34.8333L25.9252 33.3105L27.2393 32.3714H25.619Z"
            fill="#FFCC00"/>
        <path
            d="M32.3045 34.141L31.8133 32.6055L31.3157 34.141H29.6953L31.0095 35.08L30.5183 36.6028L31.8133 35.6638L33.1019 36.6028L32.6107 35.08L33.9248 34.141H32.3045Z"
            fill="#FFCC00"/>
        <path
            d="M38.9963 32.3714L38.4987 30.8359L38.0011 32.3714H36.3871L37.6949 33.3105L37.2037 34.8333L38.4987 33.8942L39.7937 34.8333L39.3025 33.3105L40.6166 32.3714H38.9963Z"
            fill="#FFCC00"/>
        <path
            d="M43.9083 27.4911L43.4107 25.9492L42.9131 27.4911H41.2928L42.6069 28.4301L42.1157 29.9466L43.4107 29.0075L44.7057 29.9466L44.2081 28.4301L45.5222 27.4911H43.9083Z"
            fill="#FFCC00"/>
        <path
            d="M47.302 20.7996H45.6817L45.1841 19.2578L44.6929 20.7996H43.0726L44.3867 21.7387L43.8955 23.2552L45.1841 22.3161L46.4791 23.2552L45.9879 21.7387L47.302 20.7996Z"
            fill="#FFCC00"/>
        <path
            d="M42.1157 16.6067L43.4107 15.6677L44.7057 16.6067L44.2081 15.0839L45.5222 14.1449H43.9083L43.4107 12.6094L42.9131 14.1512L41.2928 14.1449L42.6069 15.0839L42.1157 16.6067Z"
            fill="#FFCC00"/>
        <path
            d="M38.5178 7.74219L38.0202 9.28402L36.4063 9.27768L37.714 10.2231L37.2228 11.7395L38.5178 10.8005L39.8128 11.7395L39.3216 10.2231L40.6357 9.27768H39.0154L38.5178 7.74219Z"
            fill="#FFCC00"/>
        <path
            d="M77.5332 14.4045L79.5108 15.0263C79.211 16.124 78.707 16.9361 77.9989 17.4691C77.2972 18.0021 76.4041 18.2686 75.3196 18.2686C73.98 18.2686 72.8764 17.8118 72.0152 16.9044C71.154 15.9907 70.7202 14.7471 70.7202 13.1672C70.7202 11.4985 71.154 10.1978 72.0216 9.27774C72.8892 8.35137 74.031 7.89453 75.4409 7.89453C76.672 7.89453 77.68 8.2562 78.4455 8.98587C78.9048 9.41733 79.2493 10.0328 79.4789 10.8323L77.4631 11.3145C77.3419 10.7942 77.0931 10.3818 76.7167 10.0836C76.3403 9.78534 75.881 9.63306 75.3388 9.63306C74.5924 9.63306 73.9864 9.89955 73.5207 10.4325C73.055 10.9655 72.8254 11.8284 72.8254 13.0276C72.8254 14.2966 73.055 15.1976 73.5143 15.7369C73.9736 16.2763 74.5669 16.5428 75.3069 16.5428C75.8491 16.5428 76.3148 16.3714 76.7039 16.0288C77.0931 15.6862 77.3738 15.1469 77.546 14.4108L77.5332 14.4045Z"
            fill="#003399"/>
        <path
            d="M80.7994 14.3568C80.7994 13.716 80.9589 13.1005 81.2715 12.5041C81.5904 11.9077 82.037 11.4508 82.6175 11.1336C83.198 10.8163 83.8487 10.6641 84.5631 10.6641C85.6668 10.6641 86.5726 11.0194 87.2807 11.7364C87.9888 12.4533 88.3396 13.3543 88.3396 14.4457C88.3396 15.537 87.9824 16.457 87.2679 17.1804C86.5535 17.9037 85.654 18.2654 84.5759 18.2654C83.9061 18.2654 83.2682 18.1131 82.6621 17.8149C82.0561 17.5167 81.5904 17.0725 81.2778 16.4888C80.9589 15.905 80.8058 15.2007 80.8058 14.3632L80.7994 14.3568ZM82.7833 14.4584C82.7833 15.1817 82.9556 15.7337 83.3001 16.1144C83.6445 16.4951 84.0656 16.6918 84.5759 16.6918C85.0862 16.6918 85.5009 16.5015 85.8454 16.1144C86.1899 15.7337 86.3557 15.1754 86.3557 14.4457C86.3557 13.716 86.1835 13.1894 85.8454 12.8023C85.5009 12.4216 85.0799 12.2249 84.5759 12.2249C84.0719 12.2249 83.6445 12.4153 83.3001 12.8023C82.9556 13.183 82.7833 13.735 82.7833 14.4584Z"
            fill="#003399"/>
        <path d="M89.6346 15.4186V13.4961H93.4367V15.4186H89.6346Z" fill="#003399"/>
        <path
            d="M93.6982 10.8244H94.7699V10.2788C94.7699 9.66966 94.8337 9.21282 94.9677 8.90826C95.0953 8.61005 95.3377 8.36259 95.6885 8.17224C96.0394 7.98189 96.4859 7.88672 97.0218 7.88672C97.5576 7.88672 98.1126 7.9692 98.6421 8.13417L98.3806 9.47297C98.0744 9.39683 97.7745 9.3651 97.4939 9.3651C97.2132 9.3651 97.0154 9.42855 96.8942 9.5618C96.773 9.6887 96.7092 9.9425 96.7092 10.3105V10.8244H98.1573V12.3346H96.7092V18.0895H94.7763V12.3346H93.7046V10.8244H93.6982Z"
            fill="#003399"/>
        <path
            d="M104.052 18.0941V17.0028C103.784 17.3898 103.433 17.6944 102.999 17.9228C102.565 18.1449 102.106 18.2591 101.628 18.2591C101.149 18.2591 100.696 18.1512 100.307 17.9355C99.918 17.7198 99.6373 17.4215 99.4587 17.0345C99.2864 16.6475 99.1971 16.1081 99.1971 15.4229V10.8228H101.13V14.1602C101.13 15.1818 101.168 15.8099 101.238 16.0383C101.309 16.2668 101.436 16.4508 101.628 16.584C101.813 16.7172 102.055 16.787 102.342 16.787C102.674 16.787 102.967 16.6982 103.229 16.5142C103.49 16.3366 103.669 16.1081 103.765 15.8416C103.86 15.5752 103.911 14.9216 103.911 13.881V10.8164H105.844V18.0878H104.045L104.052 18.0941Z"
            fill="#003399"/>
        <path
            d="M114.501 18.095H112.568V14.3832C112.568 13.5964 112.53 13.0888 112.447 12.8604C112.364 12.632 112.23 12.448 112.045 12.3211C111.86 12.1942 111.637 12.1307 111.375 12.1307C111.037 12.1307 110.737 12.2196 110.476 12.4036C110.214 12.5876 110.029 12.8287 109.927 13.1269C109.825 13.4251 109.78 13.9835 109.78 14.7956V18.0887H107.847V10.8173H109.646V11.8833C110.284 11.0648 111.088 10.6523 112.058 10.6523C112.485 10.6523 112.874 10.7285 113.225 10.8808C113.576 11.033 113.844 11.2297 114.029 11.4645C114.207 11.6993 114.335 11.9721 114.405 12.2703C114.475 12.5685 114.514 13 114.514 13.5647V18.0823L114.501 18.095Z"
            fill="#003399"/>
        <path
            d="M123.164 18.0964H121.365V17.0304C121.065 17.4428 120.714 17.7537 120.306 17.9568C119.898 18.1598 119.489 18.2613 119.075 18.2613C118.233 18.2613 117.518 17.9251 116.919 17.2525C116.319 16.5799 116.019 15.6409 116.019 14.4353C116.019 13.2298 116.313 12.2653 116.893 11.6245C117.474 10.9836 118.214 10.66 119.1 10.66C119.917 10.66 120.625 10.9963 121.218 11.6752V8.05859H123.151V18.0964H123.164ZM118.003 14.3021C118.003 15.0762 118.112 15.6409 118.328 15.9835C118.641 16.4847 119.075 16.7385 119.636 16.7385C120.083 16.7385 120.459 16.5482 120.772 16.1738C121.084 15.7995 121.237 15.2348 121.237 14.4861C121.237 13.6485 121.084 13.0521 120.784 12.6841C120.485 12.3161 120.095 12.1321 119.623 12.1321C119.151 12.1321 118.781 12.3161 118.469 12.6777C118.156 13.0394 118.003 13.5851 118.003 14.3021Z"
            fill="#003399"/>
        <path
            d="M129.301 15.7781L131.227 16.1017C130.978 16.806 130.589 17.339 130.053 17.707C129.518 18.075 128.848 18.259 128.044 18.259C126.775 18.259 125.83 17.8466 125.224 17.0217C124.746 16.3619 124.504 15.5243 124.504 14.5155C124.504 13.3099 124.822 12.3645 125.454 11.6856C126.086 11.0003 126.889 10.6641 127.853 10.6641C128.937 10.6641 129.798 11.0194 130.43 11.7364C131.055 12.4534 131.361 13.5447 131.329 15.0167H126.487C126.5 15.5878 126.66 16.0319 126.953 16.3492C127.253 16.6664 127.623 16.825 128.07 16.825C128.376 16.825 128.625 16.7426 128.835 16.5776C129.039 16.4126 129.199 16.1461 129.301 15.7845V15.7781ZM129.409 13.8366C129.396 13.2782 129.25 12.8594 128.975 12.5676C128.701 12.2757 128.363 12.1298 127.967 12.1298C127.546 12.1298 127.196 12.282 126.921 12.5866C126.647 12.8912 126.513 13.3099 126.513 13.8302H129.403L129.409 13.8366Z"
            fill="#003399"/>
        <path
            d="M139.61 18.0964H137.811V17.0304C137.511 17.4428 137.16 17.7537 136.752 17.9568C136.343 18.1598 135.935 18.2613 135.52 18.2613C134.678 18.2613 133.964 17.9251 133.364 17.2525C132.765 16.5799 132.465 15.6409 132.465 14.4353C132.465 13.2298 132.758 12.2653 133.339 11.6245C133.919 10.9836 134.659 10.66 135.546 10.66C136.363 10.66 137.071 10.9963 137.664 11.6752V8.05859H139.597V18.0964H139.61ZM134.449 14.3021C134.449 15.0762 134.557 15.6409 134.774 15.9835C135.087 16.4847 135.52 16.7385 136.082 16.7385C136.528 16.7385 136.905 16.5482 137.217 16.1738C137.53 15.7995 137.683 15.2348 137.683 14.4861C137.683 13.6485 137.53 13.0521 137.23 12.6841C136.93 12.3161 136.541 12.1321 136.069 12.1321C135.597 12.1321 135.227 12.3161 134.914 12.6777C134.602 13.0394 134.449 13.5851 134.449 14.3021Z"
            fill="#003399"/>
        <path
            d="M145.351 18.0964V8.05859H147.284V11.6752C147.877 11.0027 148.585 10.66 149.402 10.66C150.295 10.66 151.028 10.9836 151.609 11.6245C152.189 12.2653 152.483 13.1853 152.483 14.3846C152.483 15.5838 152.183 16.5799 151.59 17.2525C150.997 17.9251 150.276 18.2613 149.427 18.2613C149.013 18.2613 148.598 18.1598 148.19 17.9504C147.781 17.7411 147.437 17.4365 147.143 17.0304V18.0964H145.344H145.351ZM147.271 14.3021C147.271 15.0571 147.392 15.6091 147.628 15.9708C147.96 16.4847 148.407 16.7385 148.962 16.7385C149.389 16.7385 149.753 16.5545 150.052 16.1929C150.352 15.8312 150.505 15.2602 150.505 14.4797C150.505 13.6485 150.352 13.0521 150.052 12.6841C149.753 12.3161 149.363 12.1321 148.891 12.1321C148.419 12.1321 148.043 12.3097 147.737 12.6714C147.431 13.0267 147.277 13.5724 147.277 14.3021H147.271Z"
            fill="#003399"/>
        <path
            d="M153.127 10.8242H155.188L156.936 15.9827L158.639 10.8242H160.642L158.058 17.8227L157.599 19.0917C157.427 19.5169 157.267 19.8405 157.114 20.0625C156.961 20.2846 156.782 20.4686 156.585 20.6082C156.387 20.7478 156.138 20.8556 155.851 20.9318C155.558 21.0079 155.232 21.046 154.862 21.046C154.492 21.046 154.122 21.0079 153.765 20.9318L153.593 19.428C153.893 19.4851 154.167 19.5169 154.409 19.5169C154.862 19.5169 155.194 19.3836 155.405 19.1235C155.615 18.8633 155.787 18.527 155.902 18.121L153.121 10.8306L153.127 10.8242Z"
            fill="#003399"/>
        <path
            d="M74.4202 27.6464V29.1819H73.0997V32.1133C73.0997 32.7033 73.1124 33.0523 73.1379 33.1475C73.1635 33.249 73.2209 33.3251 73.3102 33.3886C73.3995 33.452 73.5079 33.4838 73.6355 33.4838C73.8141 33.4838 74.0757 33.4203 74.4138 33.2998L74.5796 34.7908C74.1267 34.9812 73.6228 35.0764 73.0486 35.0764C72.6978 35.0764 72.3852 35.0193 72.1045 34.8987C71.8238 34.7845 71.6197 34.6322 71.4857 34.4482C71.3517 34.2642 71.2624 34.0168 71.2114 33.6995C71.1731 33.4774 71.1476 33.0269 71.1476 32.3417V29.1755H70.2609V27.64H71.1476V26.1934L73.0869 25.0703V27.64H74.4074L74.4202 27.6464Z"
            fill="#003399"/>
        <path
            d="M77.6863 24.8789V28.5653C78.3115 27.842 79.0579 27.4804 79.9254 27.4804C80.372 27.4804 80.7739 27.5628 81.1311 27.7278C81.4883 27.8928 81.7563 28.1022 81.9413 28.356C82.1263 28.6098 82.2475 28.8953 82.3113 29.2062C82.3751 29.5171 82.4133 29.9993 82.4133 30.6529V34.9167H80.4804V31.078C80.4804 30.3166 80.4422 29.8343 80.372 29.625C80.2954 29.4219 80.1678 29.2569 79.9828 29.1364C79.7978 29.0158 79.5618 28.9524 79.2875 28.9524C78.9686 28.9524 78.6815 29.0285 78.4263 29.1872C78.1711 29.3394 77.9861 29.5742 77.8713 29.8915C77.7565 30.2024 77.6927 30.6655 77.6927 31.2747V34.9167H75.7598V24.8789H77.6927H77.6863Z"
            fill="#003399"/>
        <path
            d="M88.6012 32.6023L90.5277 32.9259C90.2789 33.6302 89.8898 34.1632 89.354 34.5312C88.8181 34.8992 88.1483 35.0832 87.3509 35.0832C86.0814 35.0832 85.1373 34.6708 84.5312 33.846C84.0464 33.1861 83.8104 32.3485 83.8104 31.3397C83.8104 30.1341 84.1293 29.1951 84.7609 28.5098C85.3924 27.8309 86.1962 27.4883 87.1659 27.4883C88.2503 27.4883 89.1115 27.8436 89.7367 28.5606C90.3682 29.2712 90.6681 30.3689 90.6362 31.8409H85.7943C85.8071 32.412 85.9666 32.8561 86.26 33.1734C86.5598 33.4906 86.9298 33.6493 87.3764 33.6493C87.6762 33.6493 87.9314 33.5668 88.1419 33.4018C88.346 33.2368 88.5055 32.9704 88.6076 32.6087L88.6012 32.6023ZM88.7096 30.6608C88.6969 30.1024 88.5502 29.6836 88.2759 29.3918C88.0016 29.0999 87.6634 28.954 87.2743 28.954C86.8533 28.954 86.5024 29.1063 86.2281 29.4108C85.9538 29.7154 85.8198 30.1341 85.8198 30.6544H88.7096V30.6608Z"
            fill="#003399"/>
        <path
            d="M96.1351 34.9167V24.8789H103.618V26.5794H98.1764V28.8065H103.242V30.5006H98.1764V33.2289H103.816V34.923H96.1415L96.1351 34.9167Z"
            fill="#003399"/>
        <path
            d="M110.335 34.9159V33.8309C110.067 34.2179 109.723 34.5225 109.289 34.7509C108.855 34.9793 108.396 35.0872 107.918 35.0872C107.439 35.0872 106.986 34.9793 106.597 34.7636C106.208 34.5479 105.927 34.2497 105.749 33.8626C105.576 33.4756 105.487 32.9363 105.487 32.251V27.6509H107.42V30.9883C107.42 32.0099 107.458 32.638 107.528 32.8665C107.599 33.0949 107.726 33.2789 107.918 33.4121C108.109 33.5454 108.345 33.6152 108.632 33.6152C108.964 33.6152 109.257 33.5263 109.519 33.3423C109.78 33.1583 109.959 32.9363 110.055 32.6698C110.15 32.4033 110.201 31.7497 110.201 30.7092V27.6445H112.134V34.9159H110.335Z"
            fill="#003399"/>
        <path
            d="M115.987 34.9168H114.054V27.6454H115.853V28.6797C116.159 28.1911 116.44 27.8675 116.683 27.7152C116.925 27.5566 117.206 27.4805 117.518 27.4805C117.958 27.4805 118.386 27.601 118.794 27.8421L118.194 29.5172C117.869 29.3078 117.563 29.2 117.289 29.2C117.014 29.2 116.791 29.2761 116.6 29.422C116.408 29.568 116.262 29.8408 116.153 30.2278C116.045 30.6149 115.994 31.4271 115.994 32.6643V34.9105L115.987 34.9168Z"
            fill="#003399"/>
        <path
            d="M119.17 31.1811C119.17 30.5402 119.33 29.9248 119.643 29.3283C119.962 28.7319 120.408 28.2751 120.989 27.9578C121.569 27.6406 122.22 27.4883 122.934 27.4883C124.038 27.4883 124.944 27.8436 125.652 28.5606C126.36 29.2712 126.711 30.1786 126.711 31.2699C126.711 32.3612 126.353 33.2813 125.639 34.0046C124.925 34.7279 124.025 35.0896 122.947 35.0896C122.277 35.0896 121.639 34.9373 121.033 34.6391C120.427 34.3409 119.962 33.8967 119.649 33.313C119.336 32.7292 119.177 32.025 119.177 31.1874L119.17 31.1811ZM121.154 31.2826C121.154 32.0059 121.327 32.5579 121.671 32.9386C122.016 33.3193 122.437 33.516 122.947 33.516C123.457 33.516 123.872 33.3257 124.216 32.9386C124.561 32.5579 124.727 31.9996 124.727 31.2699C124.727 30.5402 124.555 30.0072 124.216 29.6265C123.872 29.2458 123.451 29.0491 122.947 29.0491C122.443 29.0491 122.016 29.2395 121.671 29.6265C121.327 30.0072 121.154 30.5593 121.154 31.2826Z"
            fill="#003399"/>
        <path
            d="M128.172 27.6454H129.977V28.7114C130.213 28.3434 130.526 28.0515 130.927 27.8231C131.329 27.5947 131.769 27.4805 132.254 27.4805C133.103 27.4805 133.824 27.8104 134.417 28.4703C135.01 29.1302 135.304 30.0565 135.304 31.2367C135.304 32.4169 135.004 33.394 134.41 34.0666C133.817 34.7391 133.09 35.0754 132.242 35.0754C131.84 35.0754 131.47 34.9929 131.144 34.8343C130.819 34.6757 130.468 34.4029 130.111 34.0158V37.6769H128.178V27.6391L128.172 27.6454ZM130.085 31.1542C130.085 31.9727 130.245 32.5755 130.577 32.9625C130.908 33.3496 131.297 33.5463 131.769 33.5463C132.242 33.5463 132.592 33.3686 132.892 33.007C133.192 32.6516 133.339 32.0616 133.339 31.243C133.339 30.4816 133.186 29.9169 132.879 29.5426C132.573 29.1746 132.19 28.9906 131.738 28.9906C131.285 28.9906 130.87 29.1746 130.564 29.5362C130.251 29.8979 130.098 30.4372 130.098 31.1479L130.085 31.1542Z"
            fill="#003399"/>
        <path
            d="M141.07 32.6023L142.997 32.9259C142.748 33.6302 142.359 34.1632 141.823 34.5312C141.287 34.8992 140.617 35.0832 139.814 35.0832C138.544 35.0832 137.6 34.6708 136.994 33.846C136.516 33.1861 136.273 32.3485 136.273 31.3397C136.273 30.1341 136.592 29.1951 137.224 28.5098C137.855 27.8309 138.659 27.4883 139.622 27.4883C140.707 27.4883 141.568 27.8436 142.2 28.5606C142.825 29.2712 143.131 30.3689 143.099 31.8409H138.257C138.27 32.412 138.429 32.8561 138.723 33.1734C139.023 33.4906 139.393 33.6493 139.839 33.6493C140.145 33.6493 140.394 33.5668 140.605 33.4018C140.809 33.2368 140.968 32.9704 141.07 32.6087V32.6023ZM141.179 30.6608C141.166 30.1024 141.019 29.6836 140.745 29.3918C140.471 29.0999 140.133 28.954 139.737 28.954C139.316 28.954 138.965 29.1063 138.691 29.4108C138.417 29.7154 138.283 30.1341 138.283 30.6544H141.172L141.179 30.6608Z"
            fill="#003399"/>
        <path
            d="M146.123 29.8662L144.368 29.5489C144.566 28.8446 144.904 28.3244 145.389 27.9881C145.868 27.6518 146.588 27.4805 147.539 27.4805C148.4 27.4805 149.044 27.582 149.465 27.785C149.886 27.9881 150.186 28.2482 150.359 28.5591C150.531 28.87 150.614 29.4474 150.614 30.2786L150.595 32.5247C150.595 33.1656 150.626 33.6351 150.69 33.9397C150.754 34.2442 150.869 34.5678 151.035 34.9168H149.121C149.07 34.7899 149.006 34.5995 148.936 34.3457C148.904 34.2315 148.879 34.1554 148.866 34.1173C148.534 34.4346 148.183 34.6757 147.807 34.8343C147.43 34.9929 147.029 35.0754 146.601 35.0754C145.848 35.0754 145.255 34.8724 144.821 34.4663C144.388 34.0602 144.171 33.5463 144.171 32.9245C144.171 32.512 144.273 32.1504 144.464 31.8268C144.662 31.5032 144.936 31.2557 145.293 31.0844C145.651 30.9131 146.161 30.7672 146.831 30.6339C147.737 30.4626 148.362 30.3103 148.706 30.1581V29.9677C148.706 29.5997 148.617 29.3332 148.432 29.1746C148.247 29.016 147.903 28.9398 147.392 28.9398C147.048 28.9398 146.78 29.0096 146.588 29.1429C146.397 29.2761 146.238 29.5109 146.123 29.8535V29.8662ZM148.713 31.427C148.464 31.5095 148.075 31.6047 147.539 31.7189C147.003 31.8331 146.652 31.9473 146.486 32.0552C146.231 32.2329 146.11 32.4613 146.11 32.7341C146.11 33.007 146.212 33.2354 146.41 33.4321C146.608 33.6288 146.869 33.7239 147.182 33.7239C147.533 33.7239 147.864 33.6097 148.177 33.3813C148.413 33.21 148.566 32.9943 148.636 32.7468C148.687 32.5818 148.713 32.2709 148.713 31.8078V31.427Z"
            fill="#003399"/>
        <path
            d="M159.162 34.9153H157.229V31.2035C157.229 30.4167 157.191 29.9091 157.108 29.6807C157.025 29.4523 156.891 29.2683 156.706 29.1414C156.521 29.0145 156.298 28.951 156.036 28.951C155.698 28.951 155.398 29.0399 155.137 29.2239C154.875 29.4079 154.69 29.649 154.588 29.9472C154.486 30.2454 154.441 30.8038 154.441 31.6159V34.909H152.508V27.6376H154.307V28.7036C154.945 27.8851 155.749 27.4727 156.719 27.4727C157.146 27.4727 157.535 27.5488 157.886 27.7011C158.237 27.8534 158.505 28.0501 158.69 28.2848C158.868 28.5196 158.996 28.7924 159.066 29.0906C159.136 29.3888 159.175 29.8203 159.175 30.385V34.9026L159.162 34.9153Z"
            fill="#003399"/>
        <path
            d="M165.037 24.8789H167.072V30.3166C167.072 31.1795 167.098 31.7378 167.149 31.9916C167.238 32.4041 167.442 32.734 167.774 32.9815C168.099 33.2289 168.552 33.3558 169.12 33.3558C169.688 33.3558 170.134 33.2416 170.428 33.0005C170.721 32.7657 170.9 32.4739 170.957 32.1376C171.015 31.7949 171.046 31.2302 171.046 30.4308V24.8789H173.081V30.1516C173.081 31.3571 173.024 32.2074 172.916 32.7023C172.807 33.1972 172.603 33.6223 172.303 33.9586C172.01 34.3012 171.614 34.5741 171.117 34.7771C170.619 34.9801 169.975 35.0817 169.177 35.0817C168.214 35.0817 167.48 34.9738 166.983 34.7517C166.485 34.5296 166.09 34.2441 165.803 33.8888C165.516 33.5335 165.324 33.1655 165.229 32.7784C165.095 32.201 165.031 31.3571 165.031 30.2341V24.8789H165.037Z"
            fill="#003399"/>
        <path
            d="M181.866 34.9153H179.933V31.2035C179.933 30.4167 179.894 29.9091 179.811 29.6807C179.729 29.4523 179.595 29.2683 179.41 29.1414C179.225 29.0145 179.001 28.951 178.74 28.951C178.402 28.951 178.102 29.0399 177.84 29.2239C177.579 29.4079 177.394 29.649 177.292 29.9472C177.19 30.2454 177.145 30.8038 177.145 31.6159V34.909H175.212V27.6376H177.011V28.7036C177.649 27.8851 178.453 27.4727 179.422 27.4727C179.85 27.4727 180.239 27.5488 180.59 27.7011C180.941 27.8534 181.209 28.0501 181.394 28.2848C181.572 28.5196 181.7 28.7924 181.77 29.0906C181.84 29.3888 181.878 29.8203 181.878 30.385V34.9026L181.866 34.9153Z"
            fill="#003399"/>
        <path d="M183.83 26.6633V24.8867H185.763V26.6633H183.83ZM183.83 34.9181V27.6468H185.763V34.9181H183.83Z"
              fill="#003399"/>
        <path
            d="M187.294 31.1811C187.294 30.5402 187.454 29.9248 187.766 29.3283C188.085 28.7319 188.532 28.2751 189.112 27.9578C189.693 27.6406 190.344 27.4883 191.058 27.4883C192.162 27.4883 193.068 27.8436 193.776 28.5606C194.484 29.2712 194.835 30.1786 194.835 31.2699C194.835 32.3612 194.477 33.2813 193.763 34.0046C193.048 34.7279 192.149 35.0896 191.071 35.0896C190.401 35.0896 189.763 34.9373 189.157 34.6391C188.551 34.3409 188.085 33.8967 187.773 33.313C187.46 32.7292 187.301 32.025 187.301 31.1874L187.294 31.1811ZM189.278 31.2826C189.278 32.0059 189.451 32.5579 189.795 32.9386C190.139 33.3193 190.56 33.516 191.071 33.516C191.581 33.516 191.996 33.3257 192.34 32.9386C192.685 32.5579 192.851 31.9996 192.851 31.2699C192.851 30.5402 192.678 30.0072 192.34 29.6265C191.996 29.2458 191.575 29.0491 191.071 29.0491C190.567 29.0491 190.139 29.2395 189.795 29.6265C189.451 30.0072 189.278 30.5593 189.278 31.2826Z"
            fill="#003399"/>
        <path
            d="M203 34.9153H201.067V31.2035C201.067 30.4167 201.029 29.9091 200.946 29.6807C200.863 29.4523 200.729 29.2683 200.544 29.1414C200.359 29.0145 200.136 28.951 199.874 28.951C199.536 28.951 199.236 29.0399 198.975 29.2239C198.713 29.4079 198.528 29.649 198.426 29.9472C198.324 30.2454 198.279 30.8038 198.279 31.6159V34.909H196.346V27.6376H198.145V28.7036C198.783 27.8851 199.587 27.4727 200.557 27.4727C200.984 27.4727 201.373 27.5488 201.724 27.7011C202.075 27.8534 202.343 28.0501 202.528 28.2848C202.707 28.5196 202.834 28.7924 202.904 29.0906C202.975 29.3888 203.013 29.8203 203.013 30.385V34.9026L203 34.9153Z"
            fill="#003399"/>
    </g>
    <defs>
        <clipPath id="clip0_1904_42973">
            <rect width="203" height="43" fill="white"/>
        </clipPath>
    </defs>
</svg>


const ue_flag_svg = <svg x="40" y="535" width="152" height="32" viewBox="0 0 203 43" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1904_42973)">
        <path d="M63.6456 0H0V43H63.6456V0Z" fill="white"/>
        <path d="M62.4846 1.08594H1.15463V41.7573H62.4846V1.08594Z" fill="#003399"/>
        <path
            d="M30.5183 9.95047L31.8069 9.01141L33.1019 9.95047L32.6107 8.42767L33.9248 7.48861H32.3045L31.8069 5.95312L31.3157 7.49496L29.6953 7.48861L31.0095 8.42767L30.5183 9.95047Z"
            fill="#FFCC00"/>
        <path
            d="M23.8201 11.7395L25.115 10.8005L26.4036 11.7395L25.9124 10.2231L27.2266 9.27768H25.6062L25.1087 7.74219L24.6175 9.28402L22.9971 9.27768L24.3113 10.2231L23.8201 11.7395Z"
            fill="#FFCC00"/>
        <path
            d="M20.2094 12.625L19.7182 14.1668L18.0979 14.1605L19.412 15.0995L18.9208 16.6223L20.2094 15.6833L21.5044 16.6223L21.0132 15.0995L22.3273 14.1605H20.707L20.2094 12.625Z"
            fill="#FFCC00"/>
        <path
            d="M18.4169 22.3356L19.7055 23.2747L19.2143 21.7582L20.5284 20.8192H18.9081L18.4169 19.2773L17.9193 20.8192H16.299L17.6131 21.7582L17.1219 23.2747L18.4169 22.3356Z"
            fill="#FFCC00"/>
        <path
            d="M20.707 27.4911L20.2094 25.9492L19.7182 27.4911H18.0979L19.412 28.4301L18.9208 29.9466L20.2094 29.0075L21.5044 29.9466L21.0132 28.4301L22.3273 27.4911H20.707Z"
            fill="#FFCC00"/>
        <path
            d="M25.619 32.3714L25.1214 30.8359L24.6238 32.3714H23.0035L24.3176 33.3105L23.8264 34.8333L25.1214 33.8942L26.4164 34.8333L25.9252 33.3105L27.2393 32.3714H25.619Z"
            fill="#FFCC00"/>
        <path
            d="M32.3045 34.141L31.8133 32.6055L31.3157 34.141H29.6953L31.0095 35.08L30.5183 36.6028L31.8133 35.6638L33.1019 36.6028L32.6107 35.08L33.9248 34.141H32.3045Z"
            fill="#FFCC00"/>
        <path
            d="M38.9963 32.3714L38.4987 30.8359L38.0011 32.3714H36.3871L37.6949 33.3105L37.2037 34.8333L38.4987 33.8942L39.7937 34.8333L39.3025 33.3105L40.6166 32.3714H38.9963Z"
            fill="#FFCC00"/>
        <path
            d="M43.9083 27.4911L43.4107 25.9492L42.9131 27.4911H41.2928L42.6069 28.4301L42.1157 29.9466L43.4107 29.0075L44.7057 29.9466L44.2081 28.4301L45.5222 27.4911H43.9083Z"
            fill="#FFCC00"/>
        <path
            d="M47.302 20.7996H45.6817L45.1841 19.2578L44.6929 20.7996H43.0726L44.3867 21.7387L43.8955 23.2552L45.1841 22.3161L46.4791 23.2552L45.9879 21.7387L47.302 20.7996Z"
            fill="#FFCC00"/>
        <path
            d="M42.1157 16.6067L43.4107 15.6677L44.7057 16.6067L44.2081 15.0839L45.5222 14.1449H43.9083L43.4107 12.6094L42.9131 14.1512L41.2928 14.1449L42.6069 15.0839L42.1157 16.6067Z"
            fill="#FFCC00"/>
        <path
            d="M38.5178 7.74219L38.0202 9.28402L36.4063 9.27768L37.714 10.2231L37.2228 11.7395L38.5178 10.8005L39.8128 11.7395L39.3216 10.2231L40.6357 9.27768H39.0154L38.5178 7.74219Z"
            fill="#FFCC00"/>
        <path
            d="M77.5332 14.4045L79.5108 15.0263C79.211 16.124 78.707 16.9361 77.9989 17.4691C77.2972 18.0021 76.4041 18.2686 75.3196 18.2686C73.98 18.2686 72.8764 17.8118 72.0152 16.9044C71.154 15.9907 70.7202 14.7471 70.7202 13.1672C70.7202 11.4985 71.154 10.1978 72.0216 9.27774C72.8892 8.35137 74.031 7.89453 75.4409 7.89453C76.672 7.89453 77.68 8.2562 78.4455 8.98587C78.9048 9.41733 79.2493 10.0328 79.4789 10.8323L77.4631 11.3145C77.3419 10.7942 77.0931 10.3818 76.7167 10.0836C76.3403 9.78534 75.881 9.63306 75.3388 9.63306C74.5924 9.63306 73.9864 9.89955 73.5207 10.4325C73.055 10.9655 72.8254 11.8284 72.8254 13.0276C72.8254 14.2966 73.055 15.1976 73.5143 15.7369C73.9736 16.2763 74.5669 16.5428 75.3069 16.5428C75.8491 16.5428 76.3148 16.3714 76.7039 16.0288C77.0931 15.6862 77.3738 15.1469 77.546 14.4108L77.5332 14.4045Z"
            fill="#003399"/>
        <path
            d="M80.7994 14.3568C80.7994 13.716 80.9589 13.1005 81.2715 12.5041C81.5904 11.9077 82.037 11.4508 82.6175 11.1336C83.198 10.8163 83.8487 10.6641 84.5631 10.6641C85.6668 10.6641 86.5726 11.0194 87.2807 11.7364C87.9888 12.4533 88.3396 13.3543 88.3396 14.4457C88.3396 15.537 87.9824 16.457 87.2679 17.1804C86.5535 17.9037 85.654 18.2654 84.5759 18.2654C83.9061 18.2654 83.2682 18.1131 82.6621 17.8149C82.0561 17.5167 81.5904 17.0725 81.2778 16.4888C80.9589 15.905 80.8058 15.2007 80.8058 14.3632L80.7994 14.3568ZM82.7833 14.4584C82.7833 15.1817 82.9556 15.7337 83.3001 16.1144C83.6445 16.4951 84.0656 16.6918 84.5759 16.6918C85.0862 16.6918 85.5009 16.5015 85.8454 16.1144C86.1899 15.7337 86.3557 15.1754 86.3557 14.4457C86.3557 13.716 86.1835 13.1894 85.8454 12.8023C85.5009 12.4216 85.0799 12.2249 84.5759 12.2249C84.0719 12.2249 83.6445 12.4153 83.3001 12.8023C82.9556 13.183 82.7833 13.735 82.7833 14.4584Z"
            fill="#003399"/>
        <path d="M89.6346 15.4186V13.4961H93.4367V15.4186H89.6346Z" fill="#003399"/>
        <path
            d="M93.6982 10.8244H94.7699V10.2788C94.7699 9.66966 94.8337 9.21282 94.9677 8.90826C95.0953 8.61005 95.3377 8.36259 95.6885 8.17224C96.0394 7.98189 96.4859 7.88672 97.0218 7.88672C97.5576 7.88672 98.1126 7.9692 98.6421 8.13417L98.3806 9.47297C98.0744 9.39683 97.7745 9.3651 97.4939 9.3651C97.2132 9.3651 97.0154 9.42855 96.8942 9.5618C96.773 9.6887 96.7092 9.9425 96.7092 10.3105V10.8244H98.1573V12.3346H96.7092V18.0895H94.7763V12.3346H93.7046V10.8244H93.6982Z"
            fill="#003399"/>
        <path
            d="M104.052 18.0941V17.0028C103.784 17.3898 103.433 17.6944 102.999 17.9228C102.565 18.1449 102.106 18.2591 101.628 18.2591C101.149 18.2591 100.696 18.1512 100.307 17.9355C99.918 17.7198 99.6373 17.4215 99.4587 17.0345C99.2864 16.6475 99.1971 16.1081 99.1971 15.4229V10.8228H101.13V14.1602C101.13 15.1818 101.168 15.8099 101.238 16.0383C101.309 16.2668 101.436 16.4508 101.628 16.584C101.813 16.7172 102.055 16.787 102.342 16.787C102.674 16.787 102.967 16.6982 103.229 16.5142C103.49 16.3366 103.669 16.1081 103.765 15.8416C103.86 15.5752 103.911 14.9216 103.911 13.881V10.8164H105.844V18.0878H104.045L104.052 18.0941Z"
            fill="#003399"/>
        <path
            d="M114.501 18.095H112.568V14.3832C112.568 13.5964 112.53 13.0888 112.447 12.8604C112.364 12.632 112.23 12.448 112.045 12.3211C111.86 12.1942 111.637 12.1307 111.375 12.1307C111.037 12.1307 110.737 12.2196 110.476 12.4036C110.214 12.5876 110.029 12.8287 109.927 13.1269C109.825 13.4251 109.78 13.9835 109.78 14.7956V18.0887H107.847V10.8173H109.646V11.8833C110.284 11.0648 111.088 10.6523 112.058 10.6523C112.485 10.6523 112.874 10.7285 113.225 10.8808C113.576 11.033 113.844 11.2297 114.029 11.4645C114.207 11.6993 114.335 11.9721 114.405 12.2703C114.475 12.5685 114.514 13 114.514 13.5647V18.0823L114.501 18.095Z"
            fill="#003399"/>
        <path
            d="M123.164 18.0964H121.365V17.0304C121.065 17.4428 120.714 17.7537 120.306 17.9568C119.898 18.1598 119.489 18.2613 119.075 18.2613C118.233 18.2613 117.518 17.9251 116.919 17.2525C116.319 16.5799 116.019 15.6409 116.019 14.4353C116.019 13.2298 116.313 12.2653 116.893 11.6245C117.474 10.9836 118.214 10.66 119.1 10.66C119.917 10.66 120.625 10.9963 121.218 11.6752V8.05859H123.151V18.0964H123.164ZM118.003 14.3021C118.003 15.0762 118.112 15.6409 118.328 15.9835C118.641 16.4847 119.075 16.7385 119.636 16.7385C120.083 16.7385 120.459 16.5482 120.772 16.1738C121.084 15.7995 121.237 15.2348 121.237 14.4861C121.237 13.6485 121.084 13.0521 120.784 12.6841C120.485 12.3161 120.095 12.1321 119.623 12.1321C119.151 12.1321 118.781 12.3161 118.469 12.6777C118.156 13.0394 118.003 13.5851 118.003 14.3021Z"
            fill="#003399"/>
        <path
            d="M129.301 15.7781L131.227 16.1017C130.978 16.806 130.589 17.339 130.053 17.707C129.518 18.075 128.848 18.259 128.044 18.259C126.775 18.259 125.83 17.8466 125.224 17.0217C124.746 16.3619 124.504 15.5243 124.504 14.5155C124.504 13.3099 124.822 12.3645 125.454 11.6856C126.086 11.0003 126.889 10.6641 127.853 10.6641C128.937 10.6641 129.798 11.0194 130.43 11.7364C131.055 12.4534 131.361 13.5447 131.329 15.0167H126.487C126.5 15.5878 126.66 16.0319 126.953 16.3492C127.253 16.6664 127.623 16.825 128.07 16.825C128.376 16.825 128.625 16.7426 128.835 16.5776C129.039 16.4126 129.199 16.1461 129.301 15.7845V15.7781ZM129.409 13.8366C129.396 13.2782 129.25 12.8594 128.975 12.5676C128.701 12.2757 128.363 12.1298 127.967 12.1298C127.546 12.1298 127.196 12.282 126.921 12.5866C126.647 12.8912 126.513 13.3099 126.513 13.8302H129.403L129.409 13.8366Z"
            fill="#003399"/>
        <path
            d="M139.61 18.0964H137.811V17.0304C137.511 17.4428 137.16 17.7537 136.752 17.9568C136.343 18.1598 135.935 18.2613 135.52 18.2613C134.678 18.2613 133.964 17.9251 133.364 17.2525C132.765 16.5799 132.465 15.6409 132.465 14.4353C132.465 13.2298 132.758 12.2653 133.339 11.6245C133.919 10.9836 134.659 10.66 135.546 10.66C136.363 10.66 137.071 10.9963 137.664 11.6752V8.05859H139.597V18.0964H139.61ZM134.449 14.3021C134.449 15.0762 134.557 15.6409 134.774 15.9835C135.087 16.4847 135.52 16.7385 136.082 16.7385C136.528 16.7385 136.905 16.5482 137.217 16.1738C137.53 15.7995 137.683 15.2348 137.683 14.4861C137.683 13.6485 137.53 13.0521 137.23 12.6841C136.93 12.3161 136.541 12.1321 136.069 12.1321C135.597 12.1321 135.227 12.3161 134.914 12.6777C134.602 13.0394 134.449 13.5851 134.449 14.3021Z"
            fill="#003399"/>
        <path
            d="M145.351 18.0964V8.05859H147.284V11.6752C147.877 11.0027 148.585 10.66 149.402 10.66C150.295 10.66 151.028 10.9836 151.609 11.6245C152.189 12.2653 152.483 13.1853 152.483 14.3846C152.483 15.5838 152.183 16.5799 151.59 17.2525C150.997 17.9251 150.276 18.2613 149.427 18.2613C149.013 18.2613 148.598 18.1598 148.19 17.9504C147.781 17.7411 147.437 17.4365 147.143 17.0304V18.0964H145.344H145.351ZM147.271 14.3021C147.271 15.0571 147.392 15.6091 147.628 15.9708C147.96 16.4847 148.407 16.7385 148.962 16.7385C149.389 16.7385 149.753 16.5545 150.052 16.1929C150.352 15.8312 150.505 15.2602 150.505 14.4797C150.505 13.6485 150.352 13.0521 150.052 12.6841C149.753 12.3161 149.363 12.1321 148.891 12.1321C148.419 12.1321 148.043 12.3097 147.737 12.6714C147.431 13.0267 147.277 13.5724 147.277 14.3021H147.271Z"
            fill="#003399"/>
        <path
            d="M153.127 10.8242H155.188L156.936 15.9827L158.639 10.8242H160.642L158.058 17.8227L157.599 19.0917C157.427 19.5169 157.267 19.8405 157.114 20.0625C156.961 20.2846 156.782 20.4686 156.585 20.6082C156.387 20.7478 156.138 20.8556 155.851 20.9318C155.558 21.0079 155.232 21.046 154.862 21.046C154.492 21.046 154.122 21.0079 153.765 20.9318L153.593 19.428C153.893 19.4851 154.167 19.5169 154.409 19.5169C154.862 19.5169 155.194 19.3836 155.405 19.1235C155.615 18.8633 155.787 18.527 155.902 18.121L153.121 10.8306L153.127 10.8242Z"
            fill="#003399"/>
        <path
            d="M74.4202 27.6464V29.1819H73.0997V32.1133C73.0997 32.7033 73.1124 33.0523 73.1379 33.1475C73.1635 33.249 73.2209 33.3251 73.3102 33.3886C73.3995 33.452 73.5079 33.4838 73.6355 33.4838C73.8141 33.4838 74.0757 33.4203 74.4138 33.2998L74.5796 34.7908C74.1267 34.9812 73.6228 35.0764 73.0486 35.0764C72.6978 35.0764 72.3852 35.0193 72.1045 34.8987C71.8238 34.7845 71.6197 34.6322 71.4857 34.4482C71.3517 34.2642 71.2624 34.0168 71.2114 33.6995C71.1731 33.4774 71.1476 33.0269 71.1476 32.3417V29.1755H70.2609V27.64H71.1476V26.1934L73.0869 25.0703V27.64H74.4074L74.4202 27.6464Z"
            fill="#003399"/>
        <path
            d="M77.6863 24.8789V28.5653C78.3115 27.842 79.0579 27.4804 79.9254 27.4804C80.372 27.4804 80.7739 27.5628 81.1311 27.7278C81.4883 27.8928 81.7563 28.1022 81.9413 28.356C82.1263 28.6098 82.2475 28.8953 82.3113 29.2062C82.3751 29.5171 82.4133 29.9993 82.4133 30.6529V34.9167H80.4804V31.078C80.4804 30.3166 80.4422 29.8343 80.372 29.625C80.2954 29.4219 80.1678 29.2569 79.9828 29.1364C79.7978 29.0158 79.5618 28.9524 79.2875 28.9524C78.9686 28.9524 78.6815 29.0285 78.4263 29.1872C78.1711 29.3394 77.9861 29.5742 77.8713 29.8915C77.7565 30.2024 77.6927 30.6655 77.6927 31.2747V34.9167H75.7598V24.8789H77.6927H77.6863Z"
            fill="#003399"/>
        <path
            d="M88.6012 32.6023L90.5277 32.9259C90.2789 33.6302 89.8898 34.1632 89.354 34.5312C88.8181 34.8992 88.1483 35.0832 87.3509 35.0832C86.0814 35.0832 85.1373 34.6708 84.5312 33.846C84.0464 33.1861 83.8104 32.3485 83.8104 31.3397C83.8104 30.1341 84.1293 29.1951 84.7609 28.5098C85.3924 27.8309 86.1962 27.4883 87.1659 27.4883C88.2503 27.4883 89.1115 27.8436 89.7367 28.5606C90.3682 29.2712 90.6681 30.3689 90.6362 31.8409H85.7943C85.8071 32.412 85.9666 32.8561 86.26 33.1734C86.5598 33.4906 86.9298 33.6493 87.3764 33.6493C87.6762 33.6493 87.9314 33.5668 88.1419 33.4018C88.346 33.2368 88.5055 32.9704 88.6076 32.6087L88.6012 32.6023ZM88.7096 30.6608C88.6969 30.1024 88.5502 29.6836 88.2759 29.3918C88.0016 29.0999 87.6634 28.954 87.2743 28.954C86.8533 28.954 86.5024 29.1063 86.2281 29.4108C85.9538 29.7154 85.8198 30.1341 85.8198 30.6544H88.7096V30.6608Z"
            fill="#003399"/>
        <path
            d="M96.1351 34.9167V24.8789H103.618V26.5794H98.1764V28.8065H103.242V30.5006H98.1764V33.2289H103.816V34.923H96.1415L96.1351 34.9167Z"
            fill="#003399"/>
        <path
            d="M110.335 34.9159V33.8309C110.067 34.2179 109.723 34.5225 109.289 34.7509C108.855 34.9793 108.396 35.0872 107.918 35.0872C107.439 35.0872 106.986 34.9793 106.597 34.7636C106.208 34.5479 105.927 34.2497 105.749 33.8626C105.576 33.4756 105.487 32.9363 105.487 32.251V27.6509H107.42V30.9883C107.42 32.0099 107.458 32.638 107.528 32.8665C107.599 33.0949 107.726 33.2789 107.918 33.4121C108.109 33.5454 108.345 33.6152 108.632 33.6152C108.964 33.6152 109.257 33.5263 109.519 33.3423C109.78 33.1583 109.959 32.9363 110.055 32.6698C110.15 32.4033 110.201 31.7497 110.201 30.7092V27.6445H112.134V34.9159H110.335Z"
            fill="#003399"/>
        <path
            d="M115.987 34.9168H114.054V27.6454H115.853V28.6797C116.159 28.1911 116.44 27.8675 116.683 27.7152C116.925 27.5566 117.206 27.4805 117.518 27.4805C117.958 27.4805 118.386 27.601 118.794 27.8421L118.194 29.5172C117.869 29.3078 117.563 29.2 117.289 29.2C117.014 29.2 116.791 29.2761 116.6 29.422C116.408 29.568 116.262 29.8408 116.153 30.2278C116.045 30.6149 115.994 31.4271 115.994 32.6643V34.9105L115.987 34.9168Z"
            fill="#003399"/>
        <path
            d="M119.17 31.1811C119.17 30.5402 119.33 29.9248 119.643 29.3283C119.962 28.7319 120.408 28.2751 120.989 27.9578C121.569 27.6406 122.22 27.4883 122.934 27.4883C124.038 27.4883 124.944 27.8436 125.652 28.5606C126.36 29.2712 126.711 30.1786 126.711 31.2699C126.711 32.3612 126.353 33.2813 125.639 34.0046C124.925 34.7279 124.025 35.0896 122.947 35.0896C122.277 35.0896 121.639 34.9373 121.033 34.6391C120.427 34.3409 119.962 33.8967 119.649 33.313C119.336 32.7292 119.177 32.025 119.177 31.1874L119.17 31.1811ZM121.154 31.2826C121.154 32.0059 121.327 32.5579 121.671 32.9386C122.016 33.3193 122.437 33.516 122.947 33.516C123.457 33.516 123.872 33.3257 124.216 32.9386C124.561 32.5579 124.727 31.9996 124.727 31.2699C124.727 30.5402 124.555 30.0072 124.216 29.6265C123.872 29.2458 123.451 29.0491 122.947 29.0491C122.443 29.0491 122.016 29.2395 121.671 29.6265C121.327 30.0072 121.154 30.5593 121.154 31.2826Z"
            fill="#003399"/>
        <path
            d="M128.172 27.6454H129.977V28.7114C130.213 28.3434 130.526 28.0515 130.927 27.8231C131.329 27.5947 131.769 27.4805 132.254 27.4805C133.103 27.4805 133.824 27.8104 134.417 28.4703C135.01 29.1302 135.304 30.0565 135.304 31.2367C135.304 32.4169 135.004 33.394 134.41 34.0666C133.817 34.7391 133.09 35.0754 132.242 35.0754C131.84 35.0754 131.47 34.9929 131.144 34.8343C130.819 34.6757 130.468 34.4029 130.111 34.0158V37.6769H128.178V27.6391L128.172 27.6454ZM130.085 31.1542C130.085 31.9727 130.245 32.5755 130.577 32.9625C130.908 33.3496 131.297 33.5463 131.769 33.5463C132.242 33.5463 132.592 33.3686 132.892 33.007C133.192 32.6516 133.339 32.0616 133.339 31.243C133.339 30.4816 133.186 29.9169 132.879 29.5426C132.573 29.1746 132.19 28.9906 131.738 28.9906C131.285 28.9906 130.87 29.1746 130.564 29.5362C130.251 29.8979 130.098 30.4372 130.098 31.1479L130.085 31.1542Z"
            fill="#003399"/>
        <path
            d="M141.07 32.6023L142.997 32.9259C142.748 33.6302 142.359 34.1632 141.823 34.5312C141.287 34.8992 140.617 35.0832 139.814 35.0832C138.544 35.0832 137.6 34.6708 136.994 33.846C136.516 33.1861 136.273 32.3485 136.273 31.3397C136.273 30.1341 136.592 29.1951 137.224 28.5098C137.855 27.8309 138.659 27.4883 139.622 27.4883C140.707 27.4883 141.568 27.8436 142.2 28.5606C142.825 29.2712 143.131 30.3689 143.099 31.8409H138.257C138.27 32.412 138.429 32.8561 138.723 33.1734C139.023 33.4906 139.393 33.6493 139.839 33.6493C140.145 33.6493 140.394 33.5668 140.605 33.4018C140.809 33.2368 140.968 32.9704 141.07 32.6087V32.6023ZM141.179 30.6608C141.166 30.1024 141.019 29.6836 140.745 29.3918C140.471 29.0999 140.133 28.954 139.737 28.954C139.316 28.954 138.965 29.1063 138.691 29.4108C138.417 29.7154 138.283 30.1341 138.283 30.6544H141.172L141.179 30.6608Z"
            fill="#003399"/>
        <path
            d="M146.123 29.8662L144.368 29.5489C144.566 28.8446 144.904 28.3244 145.389 27.9881C145.868 27.6518 146.588 27.4805 147.539 27.4805C148.4 27.4805 149.044 27.582 149.465 27.785C149.886 27.9881 150.186 28.2482 150.359 28.5591C150.531 28.87 150.614 29.4474 150.614 30.2786L150.595 32.5247C150.595 33.1656 150.626 33.6351 150.69 33.9397C150.754 34.2442 150.869 34.5678 151.035 34.9168H149.121C149.07 34.7899 149.006 34.5995 148.936 34.3457C148.904 34.2315 148.879 34.1554 148.866 34.1173C148.534 34.4346 148.183 34.6757 147.807 34.8343C147.43 34.9929 147.029 35.0754 146.601 35.0754C145.848 35.0754 145.255 34.8724 144.821 34.4663C144.388 34.0602 144.171 33.5463 144.171 32.9245C144.171 32.512 144.273 32.1504 144.464 31.8268C144.662 31.5032 144.936 31.2557 145.293 31.0844C145.651 30.9131 146.161 30.7672 146.831 30.6339C147.737 30.4626 148.362 30.3103 148.706 30.1581V29.9677C148.706 29.5997 148.617 29.3332 148.432 29.1746C148.247 29.016 147.903 28.9398 147.392 28.9398C147.048 28.9398 146.78 29.0096 146.588 29.1429C146.397 29.2761 146.238 29.5109 146.123 29.8535V29.8662ZM148.713 31.427C148.464 31.5095 148.075 31.6047 147.539 31.7189C147.003 31.8331 146.652 31.9473 146.486 32.0552C146.231 32.2329 146.11 32.4613 146.11 32.7341C146.11 33.007 146.212 33.2354 146.41 33.4321C146.608 33.6288 146.869 33.7239 147.182 33.7239C147.533 33.7239 147.864 33.6097 148.177 33.3813C148.413 33.21 148.566 32.9943 148.636 32.7468C148.687 32.5818 148.713 32.2709 148.713 31.8078V31.427Z"
            fill="#003399"/>
        <path
            d="M159.162 34.9153H157.229V31.2035C157.229 30.4167 157.191 29.9091 157.108 29.6807C157.025 29.4523 156.891 29.2683 156.706 29.1414C156.521 29.0145 156.298 28.951 156.036 28.951C155.698 28.951 155.398 29.0399 155.137 29.2239C154.875 29.4079 154.69 29.649 154.588 29.9472C154.486 30.2454 154.441 30.8038 154.441 31.6159V34.909H152.508V27.6376H154.307V28.7036C154.945 27.8851 155.749 27.4727 156.719 27.4727C157.146 27.4727 157.535 27.5488 157.886 27.7011C158.237 27.8534 158.505 28.0501 158.69 28.2848C158.868 28.5196 158.996 28.7924 159.066 29.0906C159.136 29.3888 159.175 29.8203 159.175 30.385V34.9026L159.162 34.9153Z"
            fill="#003399"/>
        <path
            d="M165.037 24.8789H167.072V30.3166C167.072 31.1795 167.098 31.7378 167.149 31.9916C167.238 32.4041 167.442 32.734 167.774 32.9815C168.099 33.2289 168.552 33.3558 169.12 33.3558C169.688 33.3558 170.134 33.2416 170.428 33.0005C170.721 32.7657 170.9 32.4739 170.957 32.1376C171.015 31.7949 171.046 31.2302 171.046 30.4308V24.8789H173.081V30.1516C173.081 31.3571 173.024 32.2074 172.916 32.7023C172.807 33.1972 172.603 33.6223 172.303 33.9586C172.01 34.3012 171.614 34.5741 171.117 34.7771C170.619 34.9801 169.975 35.0817 169.177 35.0817C168.214 35.0817 167.48 34.9738 166.983 34.7517C166.485 34.5296 166.09 34.2441 165.803 33.8888C165.516 33.5335 165.324 33.1655 165.229 32.7784C165.095 32.201 165.031 31.3571 165.031 30.2341V24.8789H165.037Z"
            fill="#003399"/>
        <path
            d="M181.866 34.9153H179.933V31.2035C179.933 30.4167 179.894 29.9091 179.811 29.6807C179.729 29.4523 179.595 29.2683 179.41 29.1414C179.225 29.0145 179.001 28.951 178.74 28.951C178.402 28.951 178.102 29.0399 177.84 29.2239C177.579 29.4079 177.394 29.649 177.292 29.9472C177.19 30.2454 177.145 30.8038 177.145 31.6159V34.909H175.212V27.6376H177.011V28.7036C177.649 27.8851 178.453 27.4727 179.422 27.4727C179.85 27.4727 180.239 27.5488 180.59 27.7011C180.941 27.8534 181.209 28.0501 181.394 28.2848C181.572 28.5196 181.7 28.7924 181.77 29.0906C181.84 29.3888 181.878 29.8203 181.878 30.385V34.9026L181.866 34.9153Z"
            fill="#003399"/>
        <path d="M183.83 26.6633V24.8867H185.763V26.6633H183.83ZM183.83 34.9181V27.6468H185.763V34.9181H183.83Z"
              fill="#003399"/>
        <path
            d="M187.294 31.1811C187.294 30.5402 187.454 29.9248 187.766 29.3283C188.085 28.7319 188.532 28.2751 189.112 27.9578C189.693 27.6406 190.344 27.4883 191.058 27.4883C192.162 27.4883 193.068 27.8436 193.776 28.5606C194.484 29.2712 194.835 30.1786 194.835 31.2699C194.835 32.3612 194.477 33.2813 193.763 34.0046C193.048 34.7279 192.149 35.0896 191.071 35.0896C190.401 35.0896 189.763 34.9373 189.157 34.6391C188.551 34.3409 188.085 33.8967 187.773 33.313C187.46 32.7292 187.301 32.025 187.301 31.1874L187.294 31.1811ZM189.278 31.2826C189.278 32.0059 189.451 32.5579 189.795 32.9386C190.139 33.3193 190.56 33.516 191.071 33.516C191.581 33.516 191.996 33.3257 192.34 32.9386C192.685 32.5579 192.851 31.9996 192.851 31.2699C192.851 30.5402 192.678 30.0072 192.34 29.6265C191.996 29.2458 191.575 29.0491 191.071 29.0491C190.567 29.0491 190.139 29.2395 189.795 29.6265C189.451 30.0072 189.278 30.5593 189.278 31.2826Z"
            fill="#003399"/>
        <path
            d="M203 34.9153H201.067V31.2035C201.067 30.4167 201.029 29.9091 200.946 29.6807C200.863 29.4523 200.729 29.2683 200.544 29.1414C200.359 29.0145 200.136 28.951 199.874 28.951C199.536 28.951 199.236 29.0399 198.975 29.2239C198.713 29.4079 198.528 29.649 198.426 29.9472C198.324 30.2454 198.279 30.8038 198.279 31.6159V34.909H196.346V27.6376H198.145V28.7036C198.783 27.8851 199.587 27.4727 200.557 27.4727C200.984 27.4727 201.373 27.5488 201.724 27.7011C202.075 27.8534 202.343 28.0501 202.528 28.2848C202.707 28.5196 202.834 28.7924 202.904 29.0906C202.975 29.3888 203.013 29.8203 203.013 30.385V34.9026L203 34.9153Z"
            fill="#003399"/>
    </g>
    <defs>
        <clipPath id="clip0_1904_42973">
            <rect width="203" height="43" fill="white"/>
        </clipPath>
    </defs>
</svg>

const getColorsAndGettersMap = (definitions,countriesData, type, subtype, getPointsBigCategoryOfCountry, getPointsPossibleInCategory, getOverallPoints, getOverallPointsPossible) => {
    let colors = []

    let customGetPoints;

    const getPointFromString = (str) => {
        return str !== "N.A." ? eval(str) : 0;
    }

    let getColor;

    switch (type.link) {
        case "":

            colors = ["#FEECE0", "#FDBE85", "#FD8D3C", "#F16913", "#D94801", "#8C2D04"]

            getColor = (country) => {
                return colors[Math.ceil(getOverallPoints(null, countriesData.find(c => c.name === country.name)) / getOverallPointsPossible() * (colors.length - 1))] ?? colors[colors.length - 1];
            }
            break;

        case "legal-gender-recognition":


            if (subtype.markers) {
                if (subtype.id === 2 || subtype.id === 3) {

                    switch(subtype.id) {
                        case 2:
                            colors = definitions.colors['LGR availability'].split(',')
                            break;
                        case 3:
                            colors = definitions.colors['LGR for minors'].split(',')
                            break;
                    }
                    customGetPoints = (_, country) => {
                        const data = countriesData.find(c => c.name === country.name)?.data;
                        switch (subtype.id) {
                            case 2:
                                return getPointFromString(data['Existence of legal measures'].value) + getPointFromString(data['Existence of administrative measures'].value) + getPointFromString(data['Name change'].value);
                            case 3:
                                return getPointFromString(data['LGR procedures exist for minors'].value) + getPointFromString(data['LGR without age restriction'].value) + getPointFromString(data['Name change (no age restriction)'].value);
                        }
                    }

                    getColor = (country) => {
                        return colors[Math.ceil(customGetPoints(null, country))] ?? colors[colors.length - 1];
                    }
                } else {
                    colors = ["#FDBE85", "#FD8D3C", "#FEECE0"];
                    switch (subtype.id) {
                        case 4:
                            colors = definitions.colors['No-divorce requirement'].split(',');
                            break;
                        case 5:
                            colors = definitions.colors["Non-binary recognition"].split(',');
                            break;
                        case 6:
                            colors = definitions.colors["Sterilisation"].split(',');
                            break;
                        case 7:
                            colors = definitions.colors["Mental health diagnosis"].split(',');
                            break;
                    }
                    switch (subtype.id) {
                        case 4:
                            colors = definitions.colors['No-divorce requirement'].split(',');
                            break;
                        case 5:
                            colors = definitions.colors["Non-binary recognition"].split(',');
                            break;
                        case 6:
                            colors = definitions.colors["Sterilisation"].split(',');
                            break;
                        case 7:
                            colors = definitions.colors["Mental health diagnosis"].split(',');
                            break;
                    }
                    customGetPoints = (_, country) => {
                        const data = countriesData.find(c => c.name === country.name)?.data;
                        switch (subtype.id) {
                            case 4:
                                return data['No compulsory divorce required'].value;
                            case 5:
                                return data['Non-binary Recognition'].value;
                            case 6:
                                return data['No compulsory sterilisation required'].value
                            case 7:
                                return data['No diagnosis or psychological opinion required'].value
                        }
                    }

                    getColor = (country) => {

                        let cellValue = customGetPoints(null, country);
                        let colorIndex;
                        colorIndex = cellValue === "N.A." ? 2 : cellValue === "1" ? 1 : 0;
                        return colors[colorIndex] ?? colors[colors.length - 1];
                    }

                }
            } else {
                colors = definitions.colors['LGR cluster'].split(',')

                customGetPoints = (_, country) => {
                    const data = countriesData.find(c => c.name === country.name)?.data;
                    return data['No legal framework making LGR impossible'].value !== '1' ? "0" : data['Existence of legal measures'].value !== "1" && data['Existence of administrative measures'].value !== "1" ? "1"
                        : ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && (data['No compulsory medical intervention required'].value !== "1" || data['No compulsory surgical intervention required'].value !== "1" || data['No compulsory sterilisation required'].value !== "1")) ? "2"
                            : ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && data['No diagnosis or psychological opinion required'].value !== "1" && data['No compulsory medical intervention required'].value === "1" && data['No compulsory surgical intervention required'].value === "1" && data['No compulsory sterilisation required'].value === "1") ? "3"
                                : ((data['Existence of legal measures'].value === "1" || data['Existence of administrative measures'].value === "1") && data['No diagnosis or psychological opinion required'].value === "1" && data["Self-determination"].value !== "1") ? "4"
                                    : data['Self-determination'].value === "1" ? "5" : "0";

                }

                getColor = (country) => {
                    return colors[customGetPoints(null, country)] ?? colors[colors.length - 1];
                }

            }

            break;

        case "asylum":

            colors = definitions.colors['Asylum'].split(',')
            getColor = (country) => {
                return colors[Math.ceil(getPointsBigCategoryOfCountry(type.id - 1, countriesData.find(c => c.name === country.name)) / getPointsPossibleInCategory(type.id - 1) * (colors.length - 1))] ?? colors[colors.length - 1];
            }
            break;

        case "hate-speech-and-crime":

            colors = definitions.colors['Hate Speech / Crime'].split(',')
            getColor = (country) => {
                return colors[Math.ceil(getPointsBigCategoryOfCountry(type.id - 1, countriesData.find(c => c.name === country.name)) / getPointsPossibleInCategory(type.id - 1) * (colors.length - 1))] ?? colors[colors.length - 1];
            }
            break;

        case "non-discrimination":

            colors = definitions.colors['Non-Discrimination'].split(',')
            getColor = (country) => {
                return colors[Math.ceil(getPointsBigCategoryOfCountry(type.id - 1, countriesData.find(c => c.name === country.name)) / getPointsPossibleInCategory(type.id - 1) * (colors.length - 1))] ?? colors[colors.length - 1];
            }
            break;

        case "health":

            colors = definitions.colors['Health'].split(',')
            getColor = (country) => {
                return colors[Math.ceil(getPointsBigCategoryOfCountry(type.id - 1, countriesData.find(c => c.name === country.name)) / getPointsPossibleInCategory(type.id - 1) * (colors.length - 1))] ?? colors[colors.length - 1];
            }
            break;

        case "family":

            colors = definitions.colors['Family'].split(',')
            getColor = (country) => {
                return colors[Math.ceil(getPointsBigCategoryOfCountry(type.id - 1, countriesData.find(c => c.name === country.name)) / getPointsPossibleInCategory(type.id - 1) * (colors.length - 1))] ?? colors[colors.length - 1];
            }
            break;

        default:
            return null
    }
    return {colors, getColor, customGetPoints}
}

const SVGContainer = ({
                          visible,
                          idExport,
                          definitions,
                          categories,
                          countriesData,
                          language,
                          type,
                          subtype,
                          country,
                          getOverallPoints,
                          getOverallPointsPossible,
                          getPointsBigCategoryOfCountry,
                          getPointsPossibleInCategory,
                          selectedIndicator
                      }) => {
    return (
        Object.keys(definitions[language]).length === 11 &&
        <div className={styles.svgExportContainer} style={{
            display: visible ? "visible" : "none",
            width: idExport <= 3 ? "1080px" : idExport === 6 ? "842px" : "595px",
            height: idExport <= 3 ? "1080px" : idExport === 6 ? "595px" : "842px"
        }}>
            {
                idExport === 1 ?
                    <ImageExport1 {...{
                        definitions,
                        categories,
                        language,
                        type,
                        subtype,
                        country,
                        getOverallPoints,
                        getOverallPointsPossible,
                        getPointsBigCategoryOfCountry,
                        getPointsPossibleInCategory
                    }} />
                    : idExport === 2 ?
                        <ImageExport2 {...{
                            definitions,
                            categories,
                            language,
                            type,
                            subtype,
                            country,
                            getOverallPoints,
                            getOverallPointsPossible,
                            getPointsBigCategoryOfCountry,
                            getPointsPossibleInCategory
                        }} />
                        : idExport === 3 ?
                            <ImageExport3 {...{
                                definitions,
                                categories,
                                countriesData,
                                language,
                                type,
                                subtype,
                                country,
                                getOverallPoints,
                                getOverallPointsPossible,
                                getPointsBigCategoryOfCountry,
                                getPointsPossibleInCategory,
                                selectedIndicator
                            }} />
                            : idExport === 4 ?
                                <PDFExport1 {...{
                                    definitions,
                                    categories,
                                    countriesData,
                                    language,
                                    type,
                                    subtype,
                                    country,
                                    getOverallPoints,
                                    getOverallPointsPossible,
                                    getPointsBigCategoryOfCountry,
                                    getPointsPossibleInCategory
                                }} />
                                : idExport === 5 ?
                                    <PDFExport2 {...{
                                        definitions,
                                        categories,
                                        countriesData,
                                        language,
                                        type,
                                        subtype,
                                        country,
                                        getOverallPoints,
                                        getOverallPointsPossible,
                                        getPointsBigCategoryOfCountry,
                                        getPointsPossibleInCategory
                                    }} />
                                    : idExport === 6 ?
                                        <PDFExport3 {...{
                                            definitions,
                                            categories,
                                            countriesData,
                                            language,
                                            type,
                                            subtype,
                                            country,
                                            getOverallPoints,
                                            getOverallPointsPossible,
                                            getPointsBigCategoryOfCountry,
                                            getPointsPossibleInCategory,
                                            selectedIndicator
                                        }} />
                                        : null
            }
        </div>
    )
}

export default SVGContainer;

export const ImageExport1 = ({
                                 definitions,
                                 categories,
                                 language,
                                 type,
                                 subtype,
                                 country,
                                 getOverallPoints,
                                 getOverallPointsPossible,
                                 getPointsBigCategoryOfCountry,
                                 getPointsPossibleInCategory
                             }) => {
    const width = 400;

    const russiaCountry = mapData["RU"];

    const heightSVG = 375;
    const widthSVG = 500;

    const [zoom, setZoom] = useState(null);
    const [SVGPosition, setSVGPosition] = useState([]);

    useEffect(() => {
        const countryCode = Object.keys(mapData)[Object.values(mapData).findIndex(c => c.name === country.name)]
        const countryBox = document.getElementById(countryCode)?.getBBox()
        if (countryBox) {
            const centerPosition = countryCode !== "RU" ? [countryBox.x + countryBox.width / 2, countryBox.y + countryBox.height / 2] : [500, 300]
            setZoom(countryCode !== "RU" ? heightSVG / countryBox.height / 3 : 0.8)
            setSVGPosition([centerPosition[0] - widthSVG / zoom / 2, centerPosition[1] - heightSVG / zoom / 2])
        }
    }, [country, zoom])

    return <svg id="svgExportFile1" width="1080" height="1080" viewBox="0 0 1080 1080">
        <rect width="1080" height="1080" fill="white"/>
        <text text-transform="uppercase" fill={definitions.colors["Main color"]} font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="34" line-height="34">
            {
                definitions[language].landing_page["Main title"].split("\n").map((t, i) => (
                    <tspan x="99" y={40 + 34 + 34 * i} key={i}>{t.toUpperCase()}</tspan>
                ))
            }
        </text>
        <text x="99" y={80 + 34 * (definitions[language].landing_page["Main title"].split("\n").length - 1) + 23}
              fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="500" font-size="18"
              line-height="23">{definitions[language].landing_page["Subtitle"]}</text>
        <text x="97" y={313 + 72} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="600"
              font-size="63" line-height="72"><Text id={`navigation.${country.name}`}/></text>
        <text x="97" y={372 + 36 + 10} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal"
              font-weight="normal" font-size="28"
              line-height="36">{`${getOverallPoints(null, country).toString().replace('.', ',')}/${getOverallPointsPossible()}`}
            <Text id="country_page.Ranking"/></text>
        {tgeu_logo_image}
        <text x="579" y={440 + 53} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="38" line-height="72"><Text id="country_page.Overview"/></text>
        {
            categories && Object.keys(categories).map((_, i) => {
                const points = getPointsBigCategoryOfCountry(i, country).toString().replace('.', ',')
                const total = getPointsPossibleInCategory(i)
                return <TextAndBar key={i} points={points} total={total} position={[579, 509 + (12 + 45) * i]}
                                   text={<Text id={`country_page.Category ${i + 1}`}/>} width={width}/>

            })
        }
        {
            SVGPosition[0] > -10000 && zoom > 0 &&
            <svg className={styles.mapExport} fill="#E5E7EA" x="0" y="459" xmlns="http://www.w3.org/2000/svg"
                 height={heightSVG} width={widthSVG}
                 viewBox={`${SVGPosition[0]} ${SVGPosition[1]} ${widthSVG / zoom} ${heightSVG / zoom}`}>
                <rect x={SVGPosition[0]} y={SVGPosition[1]} width={widthSVG / zoom} height={heightSVG / zoom}/>
                {
                    Object.keys(mapData).filter(c => mapData[c].included).map((k) => {
                        return <CountrySVG key={k} id={k} type={type} interactive={false} country={mapData[k]}
                                           selectedCountry={country} mainColor={definitions.colors["Main color"]} zoom={zoom}/>
                    })
                }
                {
                    <g id="RU">
                        {
                            russiaCountry.g.map((d, i) => {
                                return <CountrySVG key={i} id={`RU${i}`} type={type} interactive={false} d={d}
                                                   country={russiaCountry} selectedCountry={country} mainColor={definitions.colors["Main color"]}
                                                   zoom={zoom}/>
                            })
                        }
                    </g>
                }
            </svg>
        }
        {ue_flag}
        <text x="830" dominant-baseline="middle" text-anchor="middle" y={1003 + 20} fill="#383F45"
              font-family="Roboto, sans-serif" font-style="normal" font-weight="400" font-size="16" line-height="24">
            <Text id={`maps.Export`}/> {new Date(Date.now()).toLocaleDateString()}</text>
    </svg>
}

export const ImageExport2 = ({definitions, categories, type, subtype, language, country}) => {

    return <svg id="svgExportFile2" width="1080" height="1080" viewBox="0 0 1080 1080" fill="none">
        <rect width="1080" height="1080" fill="white"/>
        <text text-transform="uppercase" fill={definitions.colors["Main color"]} font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="34" line-height="34">
            {
                definitions[language].landing_page["Main title"].split("\n").map((t, i) => (
                    <tspan x="99" y={40 + 34 + 34 * i} key={i}>{t.toUpperCase()}</tspan>
                ))
            }
        </text>
        <text x="99" y={80 + 34 * (definitions[language].landing_page["Main title"].split("\n").length - 1) + 23}
              fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="500" font-size="18"
              line-height="23">{definitions[language].landing_page["Subtitle"]}</text>
        <text x="97" y={228 + 72} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="600"
              font-size={definitions[language].navigation[country.name].length < 12 ? 64 : 40} line-height="72"><Text
            id={`navigation.${country.name}`}/></text>
        {tgeu_logo_image}
        <text x="100" y={340 + 53} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="38" line-height="72"><Text id="country_page.Detail"/></text>
        {
            categories && Object.keys(categories).map((cat, i) => {

                const initialPos = i === 0 ? [100, 421 + 28]
                    : i === 1 ? [100, 882 + 28]
                        : i === 2 ? [614, 219 + 28]
                            : i === 3 ? [614, 400 + 28]
                                : i === 4 ? [614, 740 + 28]
                                    : i === 5 ? [614, 882 + 28] : [0, 0]

                return <g key={i}>
                    <text x={initialPos[0]} y={initialPos[1]} fill="#383F45" font-family="Manrope, sans-serif"
                          font-style="normal" font-weight="400" font-size="20" line-height="36"><Text
                        id={`country_page.Category ${i + 1}`}/></text>
                    {
                        categories[cat]
                            .map((smallCat, j) => {
                                const {value} = country.data[smallCat];
                                return <IconAndText key={j} position={[initialPos[0], initialPos[1] + 15 + j * (24 + 3)]}
                                                    value={value} catIndex={i} subcatIndex={j}/>
                            })
                    }
                </g>
            })
        }
        <text x="830" text-anchor="middle" y={1003 + 20} fill="#383F45" font-family="Roboto, sans-serif"
              font-style="normal" font-weight="400" font-size="16" line-height="24"><Text
            id={`maps.Export`}/> {new Date(Date.now()).toLocaleDateString()}</text>
        {ue_flag}
        <text x="300" y={1030 + 19} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
              font-weight="400" font-size="14" line-height="24"><Text id={`maps.Export 2`}/></text>
    </svg>
}

export const ImageExport3 = ({
                                 definitions,
                                 countriesData,
                                 selectedIndicator,
                                 language,
                                 type,
                                 subtype,
                                 getOverallPoints,
                                 getOverallPointsPossible,
                                 getPointsBigCategoryOfCountry,
                                 getPointsPossibleInCategory
                             }) => {

    const russiaCountry = mapData["RU"];

    const heightSVG = 779;
    const widthSVG = 1080;

    const zoom = 2.3;
    const SVGPosition = [-120, heightSVG / 4]

    const {getColor} = getColorsAndGettersMap(definitions,countriesData, type, subtype, getPointsBigCategoryOfCountry, getPointsPossibleInCategory, getOverallPoints, getOverallPointsPossible)


    const description = subtype ? definitions[language]?.maps[`Subnav ${subtype.id} Definition`] : definitions[language]?.maps[`Map subtitle ${type.id}`];
    let descriptionRows = []
    description.trim().split(" ").forEach((w, i) => {
        if (i % 5 === 0) {
            descriptionRows.push([]);
        }
        descriptionRows[Math.floor(i / 5)].push(w)
    })

    const paddingBackgroundTitle = 30;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sizeBackgroundTitle, setSizeBackgroundTitle] = useState([0, descriptionRows.length * 36 + paddingBackgroundTitle * 2 + 50])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        let maxWidth = document.getElementById("titleExport3").getBBox().width;
        for (let i = 0; i < descriptionRows.length; i++) {
            const width = document.getElementById(`description-${i}`)?.getBBox().width;
            if (width > maxWidth) maxWidth = width;
        }
        setSizeBackgroundTitle([maxWidth, descriptionRows.length * 24])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, subtype, language])

    return <svg id="svgExportFile3" width="400" height="400" viewBox="0 0 1080 1080" fill="none">
        <rect width="1080" height="1080" fill="white"/>
        <text text-transform="uppercase" fill={definitions.colors["Main color"]} font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="34" line-height="34">
            {
                definitions[language].landing_page["Main title"].split("\n").map((t, i) => (
                    <tspan x="99" y={40 + 34 + 34 * i} key={i}>{t.toUpperCase()}</tspan>
                ))
            }
        </text>
        <text x="99" y={80 + 34 * (definitions[language].landing_page["Main title"].split("\n").length - 1) + 23}
              fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="500" font-size="18"
              line-height="23">{definitions[language].landing_page["Subtitle"]}</text>
        {tgeu_logo_image}
        {
            <svg className={styles.mapExport} fill="#E5E7EA" x="0" y="200" xmlns="http://www.w3.org/2000/svg"
                 height={heightSVG} width={widthSVG}
                 viewBox={`${SVGPosition[0]} ${SVGPosition[1]} ${widthSVG / zoom} ${heightSVG / zoom}`}>
                <rect x={SVGPosition[0]} y={SVGPosition[1]} width="1080" height="779"/>
                {
                    Object.keys(mapData).filter(c => mapData[c].included).map((k) => {
                        return <CountrySVG key={k} id={k} type={type} interactive={false} definitions={definitions}
                                           country={mapData[k]} selectedCountry={null}
                                           selectedIndicator={selectedIndicator} mainColor={definitions.colors["Main color"]} zoom={zoom}
                                           countries={countriesData} getColor={getColor}/>
                    })
                }
                {
                    <g id="RU">
                        {
                            russiaCountry.g.map((d, i) => {
                                return <CountrySVG key={i} id={`RU${i}`} type={type} interactive={false}
                                                   definitions={definitions} d={d} country={russiaCountry}
                                                   selectedIndicator={selectedIndicator} selectedCountry={null}
                                                   mainColor={definitions.colors["Main color"]} zoom={zoom} countries={countriesData}
                                                   getColor={getColor}/>
                            })
                        }
                    </g>
                }
            </svg>
        }
        <text id="titleExport3" x="60" y={270} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="38" line-height="72"><Text id={subtype?.name ?? type.name}/></text>
        {selectedIndicator && <text id="subtitleExport3" x="60" y={300} fill="#383F45" font-family="Manrope, sans-serif"
                                    font-style="normal" font-weight="600" font-size="28"
                                    line-height="72">{definitions[language].maps[selectedIndicator]}</text>}
        <rect rx="10" x={30} y={580} width={sizeBackgroundTitle[0] + 20} height={sizeBackgroundTitle[1] + 20}
              fill="white" fillOpacity="0.7"/>
        {
            description && descriptionRows.map((array, i) => {
                const line = array.join(" ");
                return <text id={`description-${i}`} x="40" y={610 + i * 24} fill="#383F45"
                             font-family="Manrope, sans-serif" font-style="normal" font-weight="400" font-size="18"
                             line-height="36" key={i}>{line}</text>
            })
        }
        {
            <Legend
                type={type}
                subtype={subtype}
                getOverallPointsPossible={getOverallPointsPossible}
                getPointsPossibleInCategory={getPointsPossibleInCategory}
                definitions={definitions}
                language={language}
                svgModel
                position={[50, 896]}
                widthSVG={1080 - 50 * 2}
                heightSVG={69}
            />
        }
        <text x="830" text-anchor="middle" y={1003 + 20} fill="#383F45" font-family="Roboto, sans-serif"
              font-style="normal" font-weight="400" font-size="16" line-height="24"><Text
            id={`maps.Export`}/> {new Date(Date.now()).toLocaleDateString()}</text>
        {ue_flag}
        <text x="300" y={1030 + 19} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
              font-weight="400" font-size="14" line-height="24"><Text id={`maps.Export 2`}/></text>
    </svg>
}

//Country export
export const PDFExport1 = ({
                               definitions,
                               categories,
                               language,
                               type,
                               country,
                               getOverallPoints,
                               getOverallPointsPossible,
                               getPointsBigCategoryOfCountry,
                               getPointsPossibleInCategory
                           }) => {

    const width = 495;

    const russiaCountry = mapData["RU"];

    const heightSVG = 208;
    const widthSVG = 495;

    const [zoom, setZoom] = useState(null);
    const [SVGPosition, setSVGPosition] = useState([]);

    useEffect(() => {
        const countryCode = Object.keys(mapData)[Object.values(mapData).findIndex(c => c.name === country.name)]
        const countryBox = document.getElementById(countryCode)?.getBBox()
        if (countryBox) {
            const centerPosition = countryCode !== "RU" ? [countryBox.x + countryBox.width / 2, countryBox.y + countryBox.height / 2] : [500, 300]
            setZoom(countryCode !== "RU" ? heightSVG / countryBox.height / 1.5 : 0.4)
            setSVGPosition([centerPosition[0] - widthSVG / zoom / 2, centerPosition[1] - heightSVG / zoom / 2])
        }
    }, [country, zoom])

    return <svg id="pdfExportFile1" width="595" height="842" viewBox="0 0 595 842">
        <rect width="595" height="842" fill="white"/>
        <text text-transform="uppercase" fill={definitions.colors["Main color"]} font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="19" line-height="19">
            {
                definitions[language].landing_page["Main title"].split("\n").map((t, i) => (
                    <tspan x="40" y={50 + 19 + 19 * i} key={i}>{t.toUpperCase()}</tspan>
                ))
            }
        </text>
        <text x="40" y={50 + 19 + 19 * (definitions[language].landing_page["Main title"].split("\n").length - 1) + 12.5}
              fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="500" font-size="11"
              line-height="14">{definitions[language].landing_page["Subtitle"]}</text>
        <text x="40" y={153 + 30} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="700"
              font-size="24" line-height="38"><Text id={`navigation.${country.name}`}/></text>
        <text x="40" y={153 + 30 + 23} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal"
              font-weight="normal" font-size="18"
              line-height="28">{`${getOverallPoints(null, country).toString().replace('.', ',')}/${getOverallPointsPossible()}`}
            <Text id="country_page.Ranking"/></text>
        {tgeu_logo_pdf}
        <text x="40" y={465 + 23} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="500"
              font-size="18" line-height="28"><Text id="country_page.Overview"/></text>
        {
            categories && Object.keys(categories).map((_, i) => {
                const points = getPointsBigCategoryOfCountry(i, country).toString().replace('.', ',')
                const total = getPointsPossibleInCategory(i)

                return <TextAndBar key={i} points={points} total={total} fontSize={11} lineHeight={18}
                                   position={[50, 503 + 40 * i]} text={<Text id={`country_page.Category ${i + 1}`}/>}
                                   width={width}/>

            })
        }
        {
            SVGPosition[0] > -10000 && zoom > 0 &&
            <svg className={styles.mapExport} fill="#E5E7EA" x="40" y="230" xmlns="http://www.w3.org/2000/svg"
                 height={heightSVG} width={widthSVG}
                 viewBox={`${SVGPosition[0]} ${SVGPosition[1]} ${widthSVG / zoom} ${heightSVG / zoom}`}>
                <rect x={SVGPosition[0]} y={SVGPosition[1]} width={widthSVG / zoom} height={heightSVG / zoom}/>
                {
                    Object.keys(mapData).filter(c => mapData[c].included).map((k) => {
                        return <CountrySVG key={k} id={k} type={type} interactive={false} country={mapData[k]}
                                           selectedCountry={country} mainColor={definitions.colors["Main color"]} zoom={zoom}/>
                    })
                }
                {
                    <g id="RU">
                        {
                            russiaCountry.g.map((d, i) => {
                                return <CountrySVG key={i} id={`RU${i}`} type={type} interactive={false} d={d}
                                                   country={russiaCountry} selectedCountry={country} mainColor={definitions.colors["Main color"]}
                                                   zoom={zoom}/>
                            })
                        }
                    </g>
                }
            </svg>
        }
        {/* <text x="51" y="576" fill="#383F45" font-family="Roboto, sans-serif" font-style= "normal" font-weight="400" font-size="12" line-height="18">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</text> */}
        <text x="300" dominant-baseline="middle" text-anchor="middle" y="789" fill="#383F45"
              font-family="Roboto, sans-serif" font-style="normal" font-weight="400" font-size="7.5" line-height="11">
            <Text id={`maps.Export`}/> {new Date(Date.now()).toLocaleDateString()}</text>
        {<text x="297.5" dominant-baseline="middle" text-anchor="middle" y={789 + 11} fill="#383F45"
               font-family="Roboto, sans-serif" font-style="normal" font-weight="400" font-size="7.5" line-height="11">
            <MarkupText id={`navigation.Copyright`}/></text>}
    </svg>
}

// Map export markers
export const PDFExport2 = ({definitions, categories, language, country}) => {
    return <svg id="pdfExportFile2" width="595" height="842" viewBox="0 0 595 842">
        <rect width="595" height="842" fill="white"/>
        <text text-transform="uppercase" fill={definitions.colors["Main color"]} font-family="Manrope, sans-serif" font-style="normal"
              font-weight="600" font-size="19" line-height="19">
            {
                definitions[language].landing_page["Main title"].split("\n").map((t, i) => (
                    <tspan x="40" y={50 + 19 + 19 * i} key={i}>{t.toUpperCase()}</tspan>
                ))
            }
        </text>
        <text x="40" y={50 + 19 + 19 * (definitions[language].landing_page["Main title"].split("\n").length - 1) + 12.5}
              fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="500" font-size="11"
              line-height="14">{definitions[language].landing_page["Subtitle"]}</text>
        <text x="40" y={153 + 30} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal" font-weight="700"
              font-size="24" line-height="38"><Text id={`navigation.${country.name}`}/></text>
        <text x="40" y={153 + 30 + 23} fill="#383F45" font-family="Manrope, sans-serif" font-style="normal"
              font-weight="normal" font-size="18" line-height="28"><Text id="maps.Export List Indicators"/></text>
        {tgeu_logo_pdf}
        {
            categories && Object.keys(categories).map((cat, i) => {

                const initialPos = i === 0 ? [50, 222 + 19]
                    : i === 1 ? [50, 556 + 19]
                        : i === 2 ? [326, 222 + 19]
                            : i === 3 ? [326, 330 + 19]
                                : i === 4 ? [326, 540 + 19]
                                    : i === 5 ? [326, 630 + 19] : [0, 0]

                return <g key={i}>
                    <text x={initialPos[0]} y={initialPos[1]} fill="#383F45" font-family="Manrope, sans-serif"
                          font-style="normal" font-weight="400" font-size="14" line-height="24"><Text
                        id={`country_page.Category ${i + 1}`}/></text>
                    {
                        categories[cat]
                            .map((smallCat, j) => {
                                const {value} = country.data[smallCat];
                                return <IconAndText key={j} maxLength definitions={definitions} language={language}
                                                    fontSize={11}
                                                    position={[initialPos[0], initialPos[1] + 15 + j * (16 + 5)]}
                                                    value={value} catIndex={i} subcatIndex={j}/>
                            })
                    }
                </g>
            })
        }
        {ue_flag_bottom_right}
        {tgeu_logo_pdf_small}
        <text x="106" y={791 + 9} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal" font-weight="400"
              font-size="7.5" line-height="11"><Text id={`maps.Export TGEU 1`}/></text>
        <text x="106" y={791 + 9 + 11} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
              font-weight="400" font-size="7.5" line-height="11"><Text id={`maps.Export TGEU 2`}/></text>

        <text x="386" y={791 + 9} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal" font-weight="400"
              font-size="7.5" line-height="11"><Text id={`maps.Export PDF EU 1`}/></text>
        <text x="386" y={791 + 9 + 11} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
              font-weight="400" font-size="7.5" line-height="11"><Text id={`maps.Export PDF EU 2`}/></text>
    </svg>
}

// Map export gradient
export const PDFExport3 = ({
                               definitions,
                               countriesData,
                               language,
                               type,
                               subtype,
                               getOverallPoints,
                               getOverallPointsPossible,
                               getPointsBigCategoryOfCountry,
                               getPointsPossibleInCategory,
                               selectedIndicator
                           }) => {
    const russiaCountry = mapData["RU"];

    const heightSVG = 405;
    const widthSVG = 612;

    const zoom = 1.3;
    const SVGPosition = [0, heightSVG / 1.9]

    const {getColor} = getColorsAndGettersMap(definitions, countriesData, type, subtype, getPointsBigCategoryOfCountry, getPointsPossibleInCategory, getOverallPoints, getOverallPointsPossible)

    let description = subtype ? definitions[language]?.maps[`Subnav ${subtype.id} Definition`].trim().split('') : definitions[language]?.maps[`Map subtitle ${type.id}`].trim().split('');

    return <svg id="pdfExportFile3" width="842" height="595" viewBox="0 0 842 595" fill="none">
        <rect width="842" height="595" fill="white"/>
        <text style={{textTransform: 'uppercase'}} width="60" x="30" y={40 + 37} tex="uppercase" fill={definitions.colors["Main color"]}
              font-family="Manrope, sans-serif" font-style="normal" font-weight="600" font-size="37" line-height="25">
            <Text id="landing_page.Main title"/></text>
        <text width="60" x="30" y={40 + 37 + 10 + 23.5} fill="#383F45" font-family="Manrope, sans-serif"
              font-style="normal" font-weight="600" font-size="21.7" line-height="25"><Text id="landing_page.Subtitle"/>
        </text>
        {tgeu_logo_pdf_landscape}
        {
            <svg className={styles.mapExport} fill="#E5E7EA" x="190" y="130" xmlns="http://www.w3.org/2000/svg"
                 height={heightSVG} width={widthSVG}
                 viewBox={`${SVGPosition[0]} ${SVGPosition[1]} ${widthSVG / zoom} ${heightSVG / zoom}`}>
                <rect x={SVGPosition[0]} y={SVGPosition[1]} width={widthSVG / zoom} height={heightSVG / zoom} rx={8}/>
                {
                    Object.keys(mapData).filter(c => mapData[c].included).map((k) => {
                        return <CountrySVG key={k} id={k} type={type} interactive={false} country={mapData[k]}
                                           definitions={definitions} selectedIndicator={selectedIndicator}
                                           selectedCountry={null} mainColor={definitions.colors["Main color"]} zoom={zoom}
                                           countries={countriesData} getColor={getColor}/>
                    })
                }
                {
                    <g id="RU">
                        {
                            russiaCountry.g.map((d, i) => {
                                return <CountrySVG key={i} id={`RU${i}`} type={type} interactive={false} d={d}
                                                   country={russiaCountry} definitions={definitions}
                                                   selectedIndicator={selectedIndicator} selectedCountry={null}
                                                   mainColor={definitions.colors["Main color"]} zoom={zoom} countries={countriesData}
                                                   getColor={getColor}/>
                            })
                        }
                    </g>
                }
                <g>
                    <rect
                        x={461}
                        y={SVGPosition[1]}
                        width={10}
                        height={10}
                        fill={"white"}
                    />
                    <rect
                        x={451}
                        y={SVGPosition[1]}
                        width={20}
                        height={20}
                        rx={10}
                        fill={getColorsAndGettersMap(definitions, countriesData, type, subtype, getPointsBigCategoryOfCountry, getPointsPossibleInCategory, getOverallPoints, getOverallPointsPossible).getColor({name: "Russia"})}
                    />
                </g>
            </svg>
        }
        <text id="titleExportPDF3" x="30" y={150 + 16} fill="#383F45" font-family="Manrope, sans-serif"
              font-style="normal" font-weight="700" font-size="12" line-height="18"><Text
            id={subtype?.name ?? type.name}/></text>
        {description && <foreignObject x="30" y={150 + 24} width="140" height={200}>
            <div style={
                {
                    width: '100%',
                    height: '100%',
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#383F45",
                }
            }>
                {description}
            </div>
        </foreignObject>}
        {
            <Legend
                type={type}
                subtype={subtype}
                getOverallPointsPossible={getOverallPointsPossible}
                getPointsPossibleInCategory={getPointsPossibleInCategory}
                definitions={definitions}
                language={language}
                svgModel
                smallHeight
                position={[190 + widthSVG * 0.05, 480]}
                widthSVG={widthSVG * 0.9}
                heightSVG={40}
            />
        }
        <text x="540" text-anchor="middle" y={1003 + 20} fill="#383F45" font-family="Roboto, sans-serif"
              font-style="normal" font-weight="400" font-size="16" line-height="24"><Text
            id={`maps.Export`}/> {new Date(Date.now()).toLocaleDateString()}</text>
        {ue_flag_svg}
        <text x="30" y={550 + 9} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal" font-weight="400"
              font-size="7.5" line-height="11"><Text id={`maps.Export PDF EU 1`}/></text>
        <text x="30" y={550 + 9 + 11} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
              font-weight="400" font-size="7.5" line-height="11"><Text id={`maps.Export PDF EU 2`}/></text>
        <text x="791" text-anchor="end" y={550 + 9} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
              font-weight="400" font-size="7.5" line-height="11"><Text
            id={`maps.Export`}/> {new Date(Date.now()).toLocaleDateString()}</text>
    </svg>
}

export const PDFIndexExport = ({countriesData, countriesColumn}) => {
    return (
        <svg>
            <text x="106" y={791 + 9} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
                  font-weight="400" font-size="7.5" line-height="11"><Text id={`maps.Export TGEU 1`}/></text>
            <text x="106" y={791 + 9 + 11} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
                  font-weight="400" font-size="7.5" line-height="11"><Text id={`maps.Export TGEU 2`}/></text>
            {ue_flag_pdf}
            <text x="386" y={791 + 9} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
                  font-weight="400" font-size="7.5" line-height="11"><Text id={`maps.Export PDF EU 1`}/></text>
            <text x="386" y={791 + 9 + 11} fill="#383F45" font-family="Roboto, sans-serif" font-style="normal"
                  font-weight="400" font-size="7.5" line-height="11"><Text id={`maps.Export PDF EU 2`}/></text>
        </svg>
    )
}