import { Router } from "preact-router"
import './index.css'
import Intro from "./screens/Intro/Intro";
import Home from "./screens/Home/Home";
import { readString } from 'react-papaparse';
import about_csv from './assets/languages/about.csv'
import acknowledgements_csv from './assets/languages/acknowledgements.csv'
import country_page_csv from './assets/languages/country_page.csv'
import disclaimer_csv from './assets/languages/disclaimer.csv'
import index_csv from './assets/languages/index.csv'
import indicators_csv from './assets/languages/indicators.csv'
import landing_page_csv from './assets/languages/landing_page.csv'
import ff_csv from './assets/languages/FF.csv'
import maps_csv from './assets/languages/maps.csv'
import navigation_csv from './assets/languages/navigation.csv'
import privacy_policy_csv from './assets/languages/privacy_policy.csv'
import colors_csv from './assets/colors.csv'
import { useEffect, useState } from "preact/hooks";
import Acknowledgements from "./screens/Acknowledgements/Acknowledgements";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import Disclaimer from "./screens/Disclaimer/Disclaimer";
import Indicators from "./screens/Indicators/Indicators";
import FF from "./screens/FF/FF";
import About from "./screens/About/About";
import { IntlProvider } from "preact-i18n";
import { debounce } from "./utils";
import Index from "./screens/Index/Index";

const App = () => {

    const [loading, setLoading] = useState(true)

	const infos = {
        maps: [
            {
                id: 0,
                name: "maps.Map main title 0",
                tabName: "navigation.Map 1",
                link: "",
                description: "maps.Map subtitle 0"
            },
            {
                id: 1,
                name: "maps.Map main title 1",
                tabName: "navigation.Map 2",
                link: "legal-gender-recognition",
                description: "maps.Map subtitle 1",
                subtabs: [
                    {
                        id: 1,
                        name: "maps.Subnav 1",
                        link: "cluster-map",
                        description: "maps.Subnav 1 Definition",
                        clusters: [
                            {
                                name: "maps.Cluster 0",
                                description: "maps.Cluster 0 Description"
                            },
                            {
                                name: "maps.Cluster 1",
                                description: "maps.Cluster 1 Description"
                            },
                            {
                                name: "maps.Cluster 2",
                                description: "maps.Cluster 2 Description"
                            },
                            {
                                name: "maps.Cluster 3",
                                description: "maps.Cluster 3 Description"
                            },
                            {
                                name: "maps.Cluster 4",
                                description: "maps.Cluster 4 Description"
                            },
                            {
                                name: "maps.Cluster 5",
                                description: "maps.Cluster 5 Description"
                            },
                        ]
                    },
                    {
                        id: 2,
                        name: "maps.Subnav 2",
                        link: "availability",
                        description: "maps.Subnav 2 Definition",
                        markers: ["maps.Marker 1-2-1", "maps.Marker 1-2-2", "maps.Marker 1-2-3"]
                    },
                    {
                        id: 3,
                        name: "maps.Subnav 3",
                        link: "minors",
                        description: "maps.Subnav 3 Definition",
                        markers: ["maps.Marker 1-3-1", "maps.Marker 1-3-2", "maps.Marker 1-3-3"]
                    },
                    {
                        id: 4,
                        name: "maps.Subnav 4",
                        link: "no-divorce-requirement",
                        description: "maps.Subnav 4 Definition",
                        markers: ["maps.Marker 1-4-1", "maps.Marker 1-4-2", "maps.Marker 1-4-3"]
                    },
                    {
                        id: 5,
                        name: "maps.Subnav 5",
                        link: "non-binary-recognition",
                        description: "maps.Subnav 5 Definition",
                        markers: ["maps.Marker 1-5-1", "maps.Marker 1-5-2", "maps.Marker 1-5-3"]
                    },
                    {
                        id: 6,
                        name: "maps.Subnav 6",
                        link: "sterilisation",
                        description: "maps.Subnav 6 Definition",
                        markers: ["maps.Marker 1-6-1", "maps.Marker 1-6-2", "maps.Marker 1-6-3"]
                    },
                    {
                        id: 7,
                        name: "maps.Subnav 7",
                        link: "mental-health-diagnosis",
                        description: "maps.Subnav 7 Definition",
                        markers: ["maps.Marker 1-7-1", "maps.Marker 1-7-2", "maps.Marker 1-7-3"]
                    }
                ]
            },
            {
                id: 2,
                name: "maps.Map main title 2",
                tabName: "navigation.Map 3",
                link: "asylum",
                description: "maps.Map subtitle 2",
                markers: ["maps.Marker 2-1", "maps.Marker 2-2", "maps.Marker 2-3"]
            },
            {
                id: 3,
                name: "maps.Map main title 3",
                tabName: "navigation.Map 4",
                link: "hate-speech-and-crime",
                description: "maps.Map subtitle 3",
                markers: ["maps.Marker 3-1", "maps.Marker 3-2", "maps.Marker 3-3"]
            },
            {
                id: 4,
                name: "maps.Map main title 4",
                tabName: "navigation.Map 5",
                link: "non-discrimination",
                description: "maps.Map subtitle 4",
                markers: ["maps.Marker 4-1", "maps.Marker 4-2", "maps.Marker 4-3", "maps.Marker 4-4'", "maps.Marker 4-5", "maps.Marker 4-6", "maps.Marker 4-7", "maps.Marker 4-8"]
            },
            {
                id: 5,
                name: "maps.Map main title 5",
                tabName: "navigation.Map 6",
                link: "health",
                description: "maps.Map subtitle 5",
                markers: ["maps.Marker 5-1", "maps.Marker 5-2"]
            },
            {
                id: 6,
                name: "maps.Map main title 6",
                tabName: "navigation.Map 7",
                link: "family",
                description: "maps.Map subtitle 6",
                markers: ["maps.Marker 6-1", "maps.Marker 6-2"]
            },
            {
                id: 7,
                name: "maps.Map main title 7",
                tabName: "navigation.Map 8",
                link: "education",
                description: "maps.Map subtitle 7",
                markers: ["maps.Marker 7-1", "maps.Marker 7-2"]
            }
        ],
        bottomTabs: [
            {
                name: "navigation.Index",
                link: "/index",
            },
            {
                name: "navigation.Indicators",
                link: "/indicators",
            },
            {
                name: "navigation.Fast facts",
                link: "/fast-facts",
            },
        ]
    }

	const [definitions, setDefinitions] = useState(null);

    let definitionsTemp = null;

	const insertResultsInDefs = (title, results) => {
        if(title === "colors") {
            results = results.slice(1)
            const newDefinitions = {
                colors: {}
            }
            results.forEach(row => {
                newDefinitions.colors[row[0]] = row[1]
            })
            definitionsTemp = {...newDefinitions}
        }
        else {
            let newDefinitions = {...definitionsTemp};
            const languages = results[0].slice(1);
            languages.forEach(l => {
                if (!newDefinitions[l]) newDefinitions[l] = {}
                newDefinitions[l][title] = {}
            })
            results.forEach(row => {
                const key = row[0].trim();
                if (key !== "") {
                    languages.forEach((l, i) => newDefinitions[l][title][key] = row[i + 1].trim())
                }
            })
            if (Object.keys(newDefinitions.EN).length === 11) setDefinitions(newDefinitions)
            else definitionsTemp = {...newDefinitions}
        }
	}

	const getPapaConfig = (title) => {
		return {
			complete: (results) => {
				insertResultsInDefs(title, results.data)
			},
			download: true,
			error: (error, file) => {
				console.log('Error while parsing:', error, file);
			},
		}
	}

    useEffect(async () => {
        await readString(colors_csv, getPapaConfig("colors"));
        await readString(landing_page_csv, getPapaConfig("landing_page"));
        await readString(acknowledgements_csv, getPapaConfig("acknowledgements"));
        await readString(disclaimer_csv, getPapaConfig("disclaimer"));
        await readString(index_csv, getPapaConfig("index"));
        await readString(indicators_csv, getPapaConfig("indicators"));
        await readString(maps_csv, getPapaConfig("maps"));
        await readString(navigation_csv, getPapaConfig("navigation"));
        await readString(privacy_policy_csv, getPapaConfig("privacy_policy"));
        await readString(about_csv, getPapaConfig("about"));
        await readString(country_page_csv, getPapaConfig("country_page"));
        await readString(ff_csv, getPapaConfig("ff"));
        setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // We listen to the resize event
    useEffect(() => {
        const handleResize = debounce(() => {
            setScreenHeight(window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight)
        }, 400)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    })

    const languages = definitions ? Object.keys(definitions).filter(l => definitions[l]?.landing_page && definitions[l].landing_page[Object.keys(definitions[l].landing_page)[0]]?.trim() !== "") : []

    const [language, setLanguage] = useState(languages.find(l => l.toLowerCase() === (navigator.language || navigator.userLanguage).substring(0, 2).toLowerCase()) ?? "EN")

    const [screenHeight, setScreenHeight] = useState(window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight);

    useEffect(() => {
        if (definitions) {
            const appElement = document.getElementById('app');
            appElement.style.setProperty('--main-color', definitions.colors['Main color']);
            appElement.style.setProperty('--secondary-color', definitions.colors['Secondary color']);
        }
    }, [definitions]);


    return (
		!loading && definitions && <div id="app" style={{height: `${screenHeight}px`}}>
            <IntlProvider definition={definitions[language]}>
                <Router>
                    <Home path="/home/:type?/:subtype?" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <Acknowledgements path="/acknowledgements" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <PrivacyPolicy path="/privacy-policy" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <Disclaimer path="/disclaimer" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <Index path="/index" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <Indicators path="/indicators" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <About path="/about" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <FF path="/fast-facts" definitions={definitions} mainTabs={infos.maps} bottomTabs={infos.bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                    <Intro exact path="/" language={language} setLanguage={setLanguage} languages={languages}/>
                </Router>
            </IntlProvider>
		</div>
	);
}

export default App;