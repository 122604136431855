import FFCard from '../../components/Cards/FF/FF';
import TemplateScreen from '../../components/Template/TemplateScreen';
import './styles.css';
import { useState } from 'preact/hooks'
import { MarkupText, Text } from 'preact-i18n';
import * as Icon from "react-feather";

const FF = ({definitions, mainTabs, bottomTabs, languages, language, setLanguage}) => {

    const ff = [
        {
            name: <Text id="ff.Category 1" />,
            //description: <Text id="ff.Definition 1" />,
            categories: [
                {
                    description: <MarkupText id="ff.Text 1-1" />
                },
                {
                    description: <MarkupText id="ff.Text 1-2" />
                },
                {
                    description: <MarkupText id="ff.Text 1-3" />
                },
                {
                    description: <MarkupText id="ff.Text 1-4" />
                },
            ],
        },
        {
            name: <Text id="ff.Category 2" />,
            //description: <Text id="ff.Definition 2" />,
            categories: [
                {
                    description: <MarkupText id="ff.Text 2-1" />
                },
                /*{
                    description: <MarkupText id="ff.Text 2-2" />
                },
                {
                    description: <MarkupText id="ff.Text 2-3" />
                },*/
            ],
        },
        {
            name: <Text id="ff.Category 3" />,
            //description: <Text id="ff.Definition 3" />,
            categories: [
                {
                    description: <MarkupText id="ff.Text 3-1" />
                },
                {
                    description: <MarkupText id="ff.Text 3-2" />
                },
                {
                    description: <MarkupText id="ff.Text 3-3" />
                },
            ],
        },
        {
            name: <Text id="ff.Category 4" />,
            //description: <Text id="ff.Definition 4" />,
            categories: [
                {
                    description: <MarkupText id="ff.Text 4-1" />
                },
                {
                    description: <MarkupText id="ff.Text 4-2" />
                },
                {
                    description: <MarkupText id="ff.Text 4-3" />
                },
                {
                    description: <MarkupText id="ff.Text 4-4" />
                },
                {
                    description: <MarkupText id="ff.Text 4-5" />
                },
                {
                    description: <MarkupText id="ff.Text 4-6" />
                },
                {
                    description: <MarkupText id="ff.Text 4-7" />
                },
                {
                    description: <MarkupText id="ff.Text 4-8" />
                },
            ],
        },
        {
            name: <Text id="ff.Category 5" />,
            //description: <Text id="ff.Definition 5" />,
            categories: [
                {
                    description: <MarkupText id="ff.Text 5-1" />
                },
                {
                    description: <MarkupText id="ff.Text 5-2" />
                },
            ],
        },
        {
            name: <Text id="ff.Category 6" />,
            //description: <Text id="ff.Definition 6" />,
            categories: [
                {
                    description: <MarkupText id="ff.Text 6-1" />
                },
                /*{
                    description: <MarkupText id="ff.Text 6-2" />
                },*/
            ],
        },
        {
            name: <Text id="ff.Category 7" />,
            description: <Text id="ff.Definition 7-1" />,
            categories: [
                {
                    description: <MarkupText id="ff.Text 7-1" />
                },
                {
                    description: <MarkupText id="ff.Text 7-2" />
                },
                {
                    description: <MarkupText id="ff.Text 7-3" />
                },
                {
                    description: <MarkupText id="ff.Text 7-4" />
                },
                {
                    description: <MarkupText id="ff.Text 7-5" />
                },
                {
                    description: <MarkupText id="ff.Text 7-6" />
                },
            ],
        },
    ]

    const link = definitions[language].ff.Links;
    console.log(link)


    const [selectedIndicatorId, setSelectedIndicatorId] = useState(null);

    return <TemplateScreen definitions={definitions} mainTabs={mainTabs} bottomTabs={bottomTabs} title={<Text id="ff.Main title (H3)" />} language={language} setLanguage={setLanguage} languages={languages} >
        <div className="FFContainer">
            <div className={"downloadButton"}>
                <a href={definitions[language].ff["Link"]}>
                    <button className={'secondaryButton'}>
                        {definitions[language].ff["Link Label"]} <Icon.Download />
                    </button>
                </a>
            </div>
           <p><Text id="ff.Text" /></p>
            <div className="categoriesContainer">
                {
                    ff && ff.map((ff, i) => (
                        <FFCard selected={i === selectedIndicatorId} id={i} setSelectedIndicatorId={setSelectedIndicatorId} key={i} name={ff.name} description={ff.description} categories={ff.categories} />
                    ))
                }
            </div>
        </div>
    </TemplateScreen>
}

export default FF;