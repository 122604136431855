//import * as Icon from 'react-feather';
import { Router } from "preact-router"
import SideBar from '../../components/SideBar/SideBar';
import './styles.css'
import Footer from "../../components/Footer/Footer";
import MapView from "../../components/MapView/MapView";

const Home = ({type, subtype, mainTabs, bottomTabs, languages, language, setLanguage, definitions}) => {

    return (
        <div className="homePage">
            <div className="mainContainer">
                <SideBar definitions={definitions} currentTab={type} currentSubtab={subtype} mainTabs={mainTabs} bottomTabs={bottomTabs} language={language} setLanguage={setLanguage} languages={languages} />
                <Router>
                    <MapView path="/home/:type?" mainTabs={mainTabs} definitions={definitions} language={language} setLanguage={setLanguage} languages={languages} />
                    <MapView path="/home/:type/:subtype" mainTabs={mainTabs} definitions={definitions} language={language} setLanguage={setLanguage} languages={languages} />
                </Router>
            </div>
            <Footer />
        </div>
    )
}

export default Home;