import styles from "./styles.css"
import * as Icon from 'react-feather';
import mapJSON from '../../assets/map.json'
import {useEffect, useRef, useState} from "preact/hooks"
import { readString } from 'react-papaparse';
import { Icons } from "../../assets/img";
import MapOfCategory from "../Map/MapOfCategory";
import Legend from "../Legend/Legend";
import data from "../../assets/data.csv";
import {Text} from "preact-i18n";
import { Markup } from 'react-render-markup';
import {debounce, downloadCsvFile, formatDetails, getPapaConfig, getPointFromString} from "../../utils";
import React, {lazy} from "preact/compat";
import DownloadOverlay from "../Download/DownloadOverlay";

const MapView = ({type, subtype, mainTabs, definitions, language, languages, setLanguage}) => {

    const mapType = mainTabs.find(t => t.link === type);
    const mapSubtype = mapType.subtabs?.find(s => s.link === subtype)

    const infos = {
        // Used to flag each subcategory for each lgrMap
        lgrColumns: {
            1: [2, 3, 8, 9, 10, 7,6],
            2: [2, 3, 4],
            3: [12, 13, 5],
            4: [11],
            5: [14],
            6: [10],
            7: [7]
        }
        , zones: [
            {
                name: "Europe & Central Asia",
            },
            {
                name: "EU (European Union)"
            },
            {
                name: "Council of Europe"
            },
            {
                name: "Central Asia"
            },
            ...Object.keys(mapJSON).filter(k => mapJSON[k].included).map(k => ({name: mapJSON[k].name})).sort((a, b) => a.name.localeCompare(b.name))
        ]
    }
    const [selectedZone, setSelectedZone] = useState(infos.zones[0])
    const [selectedIndicator, setSelectedIndicator] = useState(null);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedClusterIndex, setSelectedClusterIndex] = useState(null);

    const [csvFile, setCsvFile] = useState({data: {}, errors: [], meta: {}});
    const [csvDetails, setCsvDetails] = useState({data: {}, errors: [], meta: {}});


    useEffect(() => {
        readString(data, getPapaConfig(setCsvFile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(async () => {
        //readString(`../../assets/data-details-${language}.csv`, {
        setCsvDetails(null)
        try {
            readString(`./assets/data-details-${language}.csv`, {
                complete: (results) => {
                    setCsvDetails({...results, data: formatDetails(results.data)});
                },
                download: true,
                error: (error, file) => {
                    setCsvDetails(null)
                },
            });
        } catch (e) {
            setCsvDetails(null)
        }
    }, [language])

    const countries = csvFile.data.countryData;
    const categories = csvFile.data.categoryList;

    const getAllCategories = () => {
        let allCategories = []
        for(let i = 0; i<Object.values(categories).length; i++) allCategories = allCategories.concat(Object.values(categories)[i])
        return allCategories;
    }

    const getPointsPossibleInCategory = (index) => {
        return Object.values(categories)[index]?.length;
    }

    const getPointsBigCategoryOfCountry = (index, country,indicator) => {
        if(indicator) {
            const str = country.data[definitions.EN.country_page[`Subcategory ${indicator.split(' ').at(-1)}`]].value
            const value =getPointFromString(str)
            return value%1 === 0 ? value : value.toFixed(2);
        }
        let points = 0;
        Object.keys(country.data).filter(cat => Object.values(categories)[index]?.includes(cat)).forEach(k => points += getPointFromString(country.data[k].value))
        return points%1 === 0 ? points : points.toFixed(2);
    }

    const getOverallPointsPossible = () => {
        return getAllCategories().length;
    }

    const getOverallPoints = (_, country) => {
        let points = 0;
        getAllCategories().forEach(k => points += getPointFromString(country.data[k].value));
        return points%1 === 0 ? points : points.toFixed(2);
    }

    const handleSelectCountry = (country) => {
        setExpandCountryDetailsView(false)
        setSelectedZone(country ? infos.zones.find(z => z.name === country.name) : infos.zones[0])
        setSelectedCountry(country !== null ? countries.find(c => c.name === country.name) : null)
        setSmallCategoryCommentDisplayed(null)
    }

    const handleSelectZoneDropdown = (zone) => {
        setSelectedZone(zone)
        switch (selectedZone) {
            case "Europe & Central Asia":
                break;
            case "EU (European Union)":
                break;
            case "Council of Europe":
                break;
            case "Central Asia":
                break;
            default: 
                handleSelectCountry(zone)
                break;
        }
    }

    const getDetailIcon = (value, category) => {
        switch (value) {
            case "0":
                return <Icon.X className={styles.xIcon} />
            case "N.A.":
                return <Icon.X className={styles.xIcon} />
            case "1":
                return <Icon.Check className={styles.vIcon} />
            default:
                return <div>
                        <Icon.Info className={styles.infoIcon} />
                        <div className={styles.tooltip}>{value} {category === Object.values(categories)[0][12] ? <Text id="country_page.Partially met - Non binary" /> : <Text id="country_page.Partially met" />}</div>
                    </div>
        }
    }

    const handleLeaveCountryView = () => {
        setSelectedCountry(null);
        if(infos.zones.findIndex(z => z.name === selectedZone.name) > 4) setSelectedZone(infos.zones[0])
    }

    const [smallCategoryCommentDisplayed, setSmallCategoryCommentDisplayed] = useState(null)
    const [expandDropdownLanguage, setExpandDropDownLanguage] = useState(false);
    const [expandDropdownZone, setExpandDropDownZone] = useState(false);
    const [expandCountryDetailsView, setExpandCountryDetailsView] = useState(false);

    const [smallScreen, setSmallScreen] = useState(!(window.innerWidth > 800
            || document.documentElement.clientWidth > 800
            || document.body.clientWidth > 800));

    useEffect(() => {
        const handleResize = debounce(() => {
            setSmallScreen(!(window.innerWidth > 800
            || document.documentElement.clientWidth > 800
            || document.body.clientWidth > 800));
        }, 500)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    })

    useEffect(() => {
        setSelectedClusterIndex(null)
    }, [type, subtype])

    const [downloadMapOverlayOpened, setDownloadMapOverlayOpened] = useState(false);
    const [downloadCountryOverlayOpened, setDownloadCountryOverlayOpened] = useState(false);
    const dataDetailsCounter = useRef(-1);

    return (
        <div className={styles.mapViewContainer}>
            {categories && countries && <div className={styles.mapView} style={{marginRight: selectedCountry ? '8px' : '20px'}}>
                    <div className={styles.mainContainer}>
                        <div className={styles.titleContainer}>
                            <h2><Text id={mapSubtype?.name ?? mapType.name} /><p>{mapSubtype && mapSubtype.clusters && selectedClusterIndex !== null ? (` |  ${definitions[language]["maps"][`Cluster ${selectedClusterIndex}`]}` ) : null}</p></h2>
                            <p><Text id={(!(mapSubtype && mapSubtype.clusters) ||  selectedClusterIndex === null) ? (mapSubtype?.description && mapSubtype.description !== "" ? mapSubtype.description : mapType.description) : mapSubtype.clusters[selectedClusterIndex].description} /></p>
                        </div>
                        <div id={"homeMapContainer"} className={styles.mapContainer}>
                            {
                                definitions && Object.keys(definitions.EN).length >= 11 && <MapOfCategory
                                    definitions={definitions}
                                    language={language}
                                    type={mapType}
                                    subtype={mapSubtype}
                                    countries={countries}
                                    selectedCountry={selectedCountry}
                                    handleSelectCountry={handleSelectCountry}
                                    getOverallPoints={getOverallPoints}
                                    getOverallPointsPossible={getOverallPointsPossible}
                                    getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry}
                                    getPointsPossibleInCategory={getPointsPossibleInCategory}
                                    selectedClusterIndex={selectedClusterIndex}
                                    selectedZone={selectedZone}
                                    lgrColumns={infos.lgrColumns}
                                    mainTabs={mainTabs}
                                    selectedIndicatorCallback={setSelectedIndicator}
                                    resetCountry={handleLeaveCountryView}
                                />
                            }
                            <div className={`dropdown ${styles.dropdown}`} onClick={() => setExpandDropDownZone(!expandDropdownZone)}>
                                <div className={`dropdown-main ${styles.dropdownMain}`} style={{borderRadius: expandDropdownZone ? "20px 20px 0 0" : "8px"}}>
                                    <Icon.MapPin className={styles.pinIcon} style={{color: expandDropdownZone ? "#383F45" : "var(--main-color)"}} />
                                    <p style={{opacity: expandDropdownZone ? 0.5 : 1}}><Text id={`navigation.${selectedZone?.name}`} /></p>
                                    {expandDropdownZone ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
                                </div>
                                <div className={`dropdown-content ${styles.dropdownContent}`} style={{display: expandDropdownZone ? "inherit": "none"}}>
                                    {
                                        infos.zones.map((z, i) => (
                                            <div key={z.name}>
                                                <div className={styles.dropdownItem}>
                                                    <button onClick={() => handleSelectZoneDropdown(z)}>
                                                        <p style={z.name === selectedZone.name ? {color: "var(--main-color)", fontWeight: "bold"} : {}}><Text id={`navigation.${z.name}`} /></p>
                                                    </button>
                                                    {
                                                        z.name === selectedZone.name && (
                                                            <Icon.Check className={styles.checkIcon} />
                                                        )
                                                    }
                                                </div>
                                                {
                                                    i === 3 && <div className={styles.line} />
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <button className={`secondaryButton ${styles.download}`} onClick={() => setDownloadMapOverlayOpened(true)} ><Text id="maps.Main button" /><Icon.Download /></button>
                            <div className={`dropdown ${styles.dropdownLanguage}`} onClick={() => setExpandDropDownLanguage(!expandDropdownLanguage)}>
                                <div className="dropdown-main">
                                    <p style={{opacity: expandDropdownLanguage ? 0.5 : 1}}>{language}</p>
                                    {expandDropdownLanguage ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
                                </div>
                                <div className={`dropdown-content ${styles.dropdownLanguagesContent}`} style={{display: expandDropdownLanguage ? "inherit": "none"}}>
                                    {languages && languages.filter(l => l!== language).map((lang, i) =>(
                                        <button key={i} onClick={() => setLanguage(lang)} >{lang}</button>
                                    ))}
                                </div>
                            </div>
                            {
                                downloadMapOverlayOpened && 
                                <DownloadOverlay 
                                    idsExport={[3]}
                                    callback={() => setDownloadMapOverlayOpened(false)}
                                    definitions={definitions} 
                                    language={language} 
                                    type={mapType} 
                                    categories={categories}
                                    handleCancelDownload={() => setDownloadMapOverlayOpened(false)}
                                    mainTabs={mainTabs}
                                    subtype={mapSubtype}
                                    countries={countries}
                                    selectedCountry={null}
                                    selectedZone={selectedCountry ? null : selectedZone}
                                    getOverallPoints={getOverallPoints} 
                                    getOverallPointsPossible={getOverallPointsPossible} 
                                    getPointsPossibleInCategory={getPointsPossibleInCategory} 
                                    getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry}
                                    lgrColumns={infos.lgrColumns}
                                    selectedIndicator={selectedIndicator}
                                     />
                            }
                        </div>
                        <Legend 
                            type={mapType} 
                            subtype={mapSubtype} 
                            getOverallPointsPossible={getOverallPointsPossible} 
                            getPointsPossibleInCategory={getPointsPossibleInCategory}
                            definitions={definitions}
                            language={language}
                            selectedClusterIndex={selectedClusterIndex}
                            setSelectedClusterIndex={setSelectedClusterIndex}
                            handleSelectCountry={handleSelectCountry}
                            smallScreen={smallScreen}
                            selectedIndicator={selectedIndicator}
                         />
                    </div>
                </div>
            }
            {   <div className={styles.detailsContainer} style={!smallScreen && !selectedCountry ? {width: "0", padding: "27px 0"} : {padding: smallScreen && !selectedCountry ? "0" : "27px 27px 0 27px", height: !smallScreen ? "calc(100% - 27px)" :  !selectedCountry ? "0px" : expandCountryDetailsView ? "100%" : "170px"}}>
                    {selectedCountry && (
                    <React.Fragment>
                        <div className={styles.dataHeader} onClick={() => setExpandCountryDetailsView(!expandCountryDetailsView)}>
                            { smallScreen && (<div className={styles.barCountryToExpand}/> )}
                            <div className={styles.leaveDetailsButtonContainer}><button onClick={() => handleLeaveCountryView()} ><Icon.XCircle /></button></div>
                            <h2><Text id={`navigation.${selectedCountry.name}`} /></h2>
                            <div className={styles.subtitleContainer}>
                                <h3>{`${getOverallPoints(null, selectedCountry).toString().replace('.', ',')}/${getOverallPointsPossible()}`} <Text id="country_page.Ranking" /></h3>
                            </div>
                            {(!smallScreen || expandCountryDetailsView) && <button
                                className={`primaryButton ${styles.downloadAllButton}`}
                                onClick={(e) => {e.stopPropagation();setDownloadCountryOverlayOpened(true)}}
                            >
                                <Text id="maps.Main button" />
                                <Icon.Download style={{marginLeft: "10px"}} />
                            </button>}
                        </div>
                        <div className={styles.dataContainer} >
                            <div>
                                <div className={styles.title}>
                                    <h2><Text id="country_page.Overview" /></h2>
                                    {/* <button className="secondaryButton" onClick={() => downloadCsvFile()}><Icon.Image /><Icon.Download /></button> */}
                                </div>
                                <div className={styles.barsContainer}>
                                    {
                                        csvFile.data.categoryList && Object.keys(categories).map((cat, i) => {

                                            const points = getPointsBigCategoryOfCountry(i, selectedCountry).toString().replace('.', ',')
                                            const total = getPointsPossibleInCategory(i)

                                            return <div key={i} className={styles.barContainer}>
                                                <p>{`${points}/${total} `}<Text id={`country_page.Category ${i+1}`} /></p>
                                                <div className={styles.bar}>
                                                    <div style={{width: `${parseFloat(points.replace(",","."))/total*100}%`}} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div>
                                <div className={styles.title}>
                                    <h2><Text id="country_page.Detail" /></h2>
                                    <button className="secondaryButton" onClick={() => downloadCsvFile(definitions, language, mainTabs, infos.lgrColumns, subtype, countries, categories, selectedCountry, selectedZone, null)}><img src={Icons.csv_icon} alt="csv_icon" /><Icon.Download /></button>
                                </div>
                                {
                                    categories && Object.keys(categories).map((cat, i) => {
                                        return <div key={i}>
                                            <h3><Text id={`country_page.Category ${i+1}`} /></h3>
                                            <div className={styles.detailItemsContainer}>
                                                {
                                                    categories[cat]
                                                        .map((smallCat, j) => {
                                                            let {value, comment} = selectedCountry.data[smallCat];
                                                            comment = csvDetails ? csvDetails.data[selectedCountry.name][smallCat] : null
                                                            const commentRows = comment ? [...comment.split("\n")] : []
                                                            return <div key={smallCat} className={styles.detailItem}>
                                                                <div className={styles.mainRow}>
                                                                    {getDetailIcon(value)}
                                                                    <div className={styles.titleAndExpand} style={{cursor: commentRows.length === 1 && commentRows[0].trim() === "" ? 'inherit' : 'pointer'}} onClick={() => setSmallCategoryCommentDisplayed((smallCat === smallCategoryCommentDisplayed || commentRows.length === 1 && commentRows[0].trim() === "") ? null : smallCat)}>
                                                                        <p><Text id={`country_page.Subcategory ${i+1}-${j+1}`} /></p>
                                                                        {
                                                                            !!comment?.length && (smallCat === smallCategoryCommentDisplayed ? <Icon.MinusCircle className={styles.plusIcon} /> : <Icon.PlusCircle className={styles.plusIcon} />)
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {smallCat === smallCategoryCommentDisplayed && <div style={{ paddingLeft:'30px'}}><Markup markup={comment}></Markup></div>}
                                                            </div>
                                                        })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        {
                            downloadCountryOverlayOpened && 
                            <DownloadOverlay 
                                idsExport={[1, 2]}
                                callback={() => setDownloadCountryOverlayOpened(false)}
                                definitions={definitions} 
                                language={language} 
                                type={mapType} 
                                categories={categories}
                                forceAllCategoriesInCSV
                                handleCancelDownload={() => setDownloadCountryOverlayOpened(false)}
                                mainTabs={mainTabs}
                                subtype={mapSubtype}
                                countries={countries}
                                selectedCountry={selectedCountry}
                                selectedZone={selectedZone}
                                getOverallPoints={getOverallPoints} 
                                getOverallPointsPossible={getOverallPointsPossible} 
                                getPointsPossibleInCategory={getPointsPossibleInCategory} 
                                getPointsBigCategoryOfCountry={getPointsBigCategoryOfCountry}
                                lgrColumns={infos.lgrColumns}
                            />
                        }
                        </React.Fragment>
                    )}
                </div>
                
            }
        </div>
    )
}

export default MapView;